import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';

// Login Redux States
import {
  loginSuccess,
  loginFail,
  activeSuccess,
  activeFail,
  verifySuccess,
  verifyFail,
  changePasswordSuccess,
  changePasswordFail,
  getByUserIdSuccess,
  getByUserIdFail,
  getAllUserSuccess,
  getAllUserFail,
  addUserSuccess,
  addUserFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserFail,
  deleteUserSuccess,
  getAuthSuccess,
  getAuthFail,
} from './slice';

import {
  login,
  active,
  verify,
  changePassword,
  getByUserId,
  getUser,
  addUser,
  updateUser,
  deleteUser,
  getAuth,
} from '../../api/backend_helper';

function* onLogin({ payload: data }) {
  try {
    const response = yield call(login, data);
    const accessToken = response.data;
    yield put(loginSuccess(accessToken));
    localStorage.setItem('accessToken', accessToken.token);
    localStorage.setItem('userId', accessToken.userId);
    localStorage.setItem(
      'userPermissions',
      JSON.stringify(accessToken.listPermissions)
    );
  } catch (error) {
    yield put(loginFail(error.response?.data));
  }
}

function* onActive({ payload: data }) {
  try {
    const response = yield call(active, data);
    yield put(activeSuccess(response));
  } catch (error) {
    yield put(activeFail(error.response.data));
  }
}

function* onVerify({ payload: data }) {
  try {
    const response = yield call(verify, data);
    yield put(verifySuccess(response));
  } catch (error) {
    yield put(verifyFail(error));
  }
}

function* onGetByUserId({ payload: query }) {
  try {
    const response = yield call(getByUserId, query);
    yield put(getByUserIdSuccess(response));
  } catch (error) {
    yield put(getByUserIdFail(error));
  }
}

function* onGetAllUser({ payload: query }) {
  try {
    const response = yield call(getUser, query);
    yield put(getAllUserSuccess(response));
  } catch (error) {
    yield put(getAllUserFail(error));
  }
}

function* onAddUser({ payload: data }) {
  try {
    const response = yield call(addUser, data);
    yield put(addUserSuccess(response));
  } catch (error) {
    yield put(addUserFail(error));
  }
}

function* onUpdateUser({ payload }) {
  try {
    const response = yield call(updateUser, payload.id, payload.data);
    yield put(updateUserSuccess(response));
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* onDeleteUser({ payload }) {
  try {
    const response = yield call(deleteUser, payload.id, { flag: true });
    yield put(deleteUserSuccess(response));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* onChangePassword({ payload: data }) {
  try {
    const response = yield call(changePassword, data);
    yield put(changePasswordSuccess(response));
  } catch (error) {
    yield put(changePasswordFail(error));
  }
}

function* onGetAuth() {
  try {
    const response = yield call(getAuth);
    yield put(getAuthSuccess(response));
  } catch (error) {
    yield put(getAuthFail(error));
  }
}

function* AuthSaga() {
  yield takeLatest('auth/login', onLogin);
  yield takeLatest('auth/active', onActive);
  yield takeLatest('auth/verify', onVerify);
  yield takeEvery('auth/getByUserId', onGetByUserId);
  yield takeEvery('auth/getAllUser', onGetAllUser);
  yield takeEvery('auth/updateUser', onUpdateUser);
  yield takeEvery('auth/addUser', onAddUser);
  yield takeEvery('auth/deleteUser', onDeleteUser);
  yield takeEvery('auth/changePassword', onChangePassword);
  yield takeEvery('auth/getAuth', onGetAuth);
}

export default AuthSaga;
