import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { ButtonGroup } from 'primereact/buttongroup';

const ActionMenu = ({
  onViewDetail,
  onEdit,
  onDelete,
  onUpdate,
  onAddEmployee,
  type,
}) => {
  const menuAction = useRef(null);

  let actions = [
    {
      label: 'Chỉnh sửa',
      icon: 'pi pi-pen-to-square',
      command: onEdit,
    },
    {
      label: 'Xóa',
      icon: (
        <i
          className='pi pi-trash'
          style={{ color: 'red', marginRight: '8px' }}
        />
      ),
      command: onDelete,
    },
  ];

  if (type === 'permission') {
    actions = actions.filter((action) => action.label === 'Xóa');
  }

  return (
    <div className='flex justify-content-end align-items-center'>
      <div className='action-box'>
        <ButtonGroup>
          {actions.map((action, index) => (
            <Button
              key={index}
              icon={action.icon}
              onClick={action.command}
              className='surface-100'
              text
            />
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ActionMenu;
