import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateTeams, setDataNull } from '../../store/teams/slice';
import '../../assets/scss/theme-base/Team.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';

const ModelEditTeam = ({ visible, onHide, rowData, toast }) => {
  const dispatch = useDispatch();

  const { errorUpdate, updateData } = useSelector(
    (state) => ({
      errorUpdate: state.Teams.errorUpdate,
      updateData: state.Teams.updateData,
    }),
    shallowEqual
  );

  const validationSchema = Yup.object({
    teamCode: Yup.string().required('Mã tổ đội là bắt buộc'),
    name: Yup.string().required('Tên tổ đội là bắt buộc'),
    password: Yup.string()
      .required('Mật khẩutổ đội là bắt buộc')
      .min(6, 'Mật khẩu phải có ít nhất 6 ký tự'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      teamCode: rowData?.teamCode || '',
      name: rowData?.name || '',
      description: rowData?.description || '',
      password: rowData?.password || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(updateTeams({ id: rowData.id, data: values }));
    },
  });

  useEffect(() => {
    if (errorUpdate) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorUpdate?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (updateData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Cập nhật thành công',
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdate, updateData]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      className='responsive-dialog'
      header='Sửa tổ đội'
      modal
      style={{ width: '450px', minHeight: '55%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            severity='secondary'
            className='p-button-text'
          />
          <Button
            type='submit'
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <form onSubmit={formik.handleSubmit}>
        <div className='bg-white p-3 border-round-md'>
          <div className='flex flex-column mb-4 gap-3'>
            <div className='flex flex-column w-12'>
              <label className='mb-2'>Tên tổ đội</label>
              <InputText
                id='name'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                disabled={rowData?.id==='9b2d2e9c-c6fb-4cb1-8f15-3e350730be9c' || rowData?.id==='c048fe80-59fd-4ffe-b6d8-0307832dca0c'}
                invalid={
                  formik.touched.name && formik.errors.name ? true : false
                }
              />
              {formik.touched.name && formik.errors.name ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.name.toString()}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-12'>
              <label className='mb-2'>Mã tổ đội</label>
              <InputText
                id='teamCode'
                name='teamCode'
                disabled={rowData?.id==='9b2d2e9c-c6fb-4cb1-8f15-3e350730be9c'|| rowData?.id==='c048fe80-59fd-4ffe-b6d8-0307832dca0c'}
                value={formik.values.teamCode}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                invalid={
                  formik.touched.teamCode && formik.errors.teamCode
                    ? true
                    : false
                }
              />
              {formik.touched.teamCode && formik.errors.teamCode ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.teamCode.toString()}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-12'>
              <label className='mb-2'>Mật khẩu</label>
              <Password
                value={formik.values.password}
                onChange={formik.handleChange}
                id='password'
                name='password'
                type='password'
                autoComplete='on'
                inputClassName='w-full'
                inputStyle={{ height: '40px' }}
                pt={{
                  iconField: {
                    root: { className: 'w-full' },
                  },
                }}
                toggleMask
                feedback={false}
                invalid={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.password.toString()}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-12 py-4'>
              <label className='mb-2'>Ghi chú</label>
              <InputTextarea
                id='description'
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                autoResize
              />
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ModelEditTeam;
