import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  addGroupPermission,
  getPermission,
  setPermissionNull,
} from '../../store/permission/slice';
import { InputTextarea } from 'primereact/inputtextarea';

const ModelAddPermission = ({ visible, onHide, toast }) => {
  const dispatch = useDispatch();

  const { groupPermissionInsert, groupPermissionError } = useSelector(
    (state) => ({
      groupPermissionInsert: state.Permission.groupPermissionInsert,
      groupPermissionError: state.Permission.groupPermissionError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      groupName: Yup.string().required('Tên nhóm quyền không được để trống'),
    }),
    onSubmit: (value) => {
      dispatch(addGroupPermission(value));
    },
  });

  useEffect(() => {
    if (groupPermissionInsert) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thêm nhóm quyền thành công',
        life: 3000,
      });
      onHide();
      formik.resetForm();
      dispatch(setPermissionNull());
      dispatch(getPermission({ page: 1, limit: 20 }));
    }
  }, [groupPermissionInsert]);

  useEffect(() => {
    if (groupPermissionError) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: 'Thêm nhóm quyền thất bại',
        life: 3000,
      });
      dispatch(setPermissionNull());
    }
  }, [groupPermissionError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      header='Thêm nhóm quyền'
      modal
      style={{ width: '400px', height: '55%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            severity='secondary'
            className='p-button-text'
          />
          <Button
            type='submit'
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <div className='bg-white  border-round-md'>
        <div className='flex flex-column gap-3'>
          <div className='flex flex-column w-12'>
            <label className='mb-2 '>Tên nhóm quyền</label>
            <InputText
              id='groupName'
              name='groupName'
              value={formik.values.groupName}
              onChange={formik.handleChange}
              invalid={
                formik.touched.groupName && formik.errors.groupName
                  ? true
                  : false
              }
            />
            {formik.touched.groupName && formik.errors.groupName ? (
              <div style={{ color: 'red' }}>
                {formik.errors.groupName.toString()}
              </div>
            ) : null}
          </div>
          <div className='flex flex-column w-12 py-2'>
            <label className='mb-2'>Ghi chú</label>
            <InputTextarea
              id='description'
              name='description'
              autoResize
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModelAddPermission;
