import { createSlice } from "@reduxjs/toolkit";

const initialState = { dashboardPlanData: null, error: null, loading: false };

const dashboardPlanSlice = createSlice({
    name: "dashboardPlan",
    initialState,
    reducers: {
        getDashboardPlan: (state) => {
            state.loading = true;
        },
        getDashboardPlanSuccess: (state, action) => {
            state.loading = false;
            state.dashboardPlanData = action.payload;
            state.error = null;
        },
        getDashboardPlanFail: (state, action) => {
            state.loading = false;
            state.dashboardPlanData = null;
            state.error = action.payload;
        },
    },
});

export const {
    getDashboardPlan,
    getDashboardPlanFail,
    getDashboardPlanSuccess,
} = dashboardPlanSlice.actions;

export default dashboardPlanSlice.reducer;
