import React, { useState, useEffect, useRef } from "react";
import { TreeSelect } from "primereact/treeselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getBuilding, setDataNull2, setProduct } from "../../store/order/slice";
import { Dropdown } from "primereact/dropdown";
import "../../assets/scss/theme-base/ModalAddDashbord.css";

const ModalAddDashbord = ({ visible, onHide, toast }) => {
    const [listAddress, setListAddress] = useState([]);
    const dispatch = useDispatch();
    const avata = "";
    const [selectedFile, setSelectedFile] = useState(null);
    const [showAvata, setShowAvata] = useState();
    const [selectedBuildingCode, setSelectedBuildingCode] = useState("");
    const fileInputRef = useRef(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);

    const { dataBuilding, productData, errorProductData } = useSelector(
        (state) => ({
            productData: state.Production.productData,
            dataBuilding: state.Production.dataBuilding,
            errorProductData: state.Production.errorProductData,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (visible) {
            dispatch(
                getBuilding({
                    page: 1,
                    limit: 100,
                })
            );
        }
    }, [dispatch, visible]);

    useEffect(() => {
        if (dataBuilding && dataBuilding.data && dataBuilding.data.items) {
            const transformedData = dataBuilding.data.items.map((item) => ({
                key: item.id,
                label: item.name,
                value: item.id,
                buildingCode: item.buildingCode,
            }));
            setListAddress(transformedData);
        }
    }, [dataBuilding]);

    // Handle file change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setShowAvata(e.target.result);
                setIsImageUploaded(true);
            };
            reader.readAsDataURL(file);
            // Set the image field in formik values
            formik.setFieldValue("image", file);
        }
    };

    // Validation schema
    const validationSchema = Yup.object().shape({
        buildingId: Yup.string().required("Công trình là bắt buộc"),
        categoryName: Yup.string().required("Hạng mục là bắt buộc"),
        categoryCode: Yup.string().required("Mã sản phẩm là bắt buộc"),
        unit: Yup.string().required("Đơn vị tính là bắt buộc"),
        quantity: Yup.string().required("Số lượng là bắt buộc"),
        factoryCode: Yup.string().required("Mã nhà máy là bắt buộc"),
        orderCode: Yup.string().required("Mã đơn hàng là bắt buộc"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            image: "avatar-null.png",
            buildingId: "",
            categoryName: "",
            categoryCode: "",
            unit: "",
            quantity: "",
            factoryCode: "",
            productNote: "",
            orderCode: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (value) => {
            const data = {
                ...value,
                buildingId: value.buildingId,
                categoryName: value.categoryName,
                categoryCode: value.categoryCode,
                unit: value.unit,
                quantity: value.quantity,
                factoryCode: value.factoryCode,
                productNote: value.productNote,
                orderCode: value.orderCode,
            };
            dispatch(setProduct({ values: data, file: selectedFile }));
        },
    });

    useEffect(() => {
        if (errorProductData) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorProductData?.response?.data?.message,
                life: 3000,
            });
            dispatch(setDataNull2());
        }
        if (productData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Thêm đơn hàng thành công",
                life: 3000,
            });
            formik.resetForm();
            dispatch(setDataNull2());
            setShowAvata(avata);
            setIsImageUploaded(false);
            onHide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorProductData, productData]);

    const handleBuildingSelect = (e) => {
        const selectedBuilding = listAddress.find(
            (item) => item.value === e.value
        );
        formik.setFieldValue("buildingId", e.value);
        setSelectedBuildingCode(selectedBuilding.buildingCode);
    };

    return (
        <Dialog
            visible={visible}
            onHide={() => {
                formik.resetForm();
                setShowAvata(avata);
                setSelectedBuildingCode("");
                setIsImageUploaded(false);
                onHide();
            }}
            header="Thêm hạng mục"
            modal
            style={{ width: "35%", height: "84%" }}
            className="responsive-dialog"
            footer={
                <div>
                    <Button
                        label="Hủy"
                        onClick={() => {
                            formik.resetForm();
                            setShowAvata(avata);
                            setSelectedBuildingCode("");
                            setIsImageUploaded(false);
                            onHide();
                        }}
                        severity="secondary"
                        className="p-button-text"
                    />
                    <Button
                        type="submit"
                        label="Lưu"
                        onClick={formik.handleSubmit}
                    />
                </div>
            }
        >
            <div className="bg-white p-3 border-round-md">
                <div className="flex flex-column mb-4 gap-3">
                    <div className="flex pb-2 col-12 responsive-gap ">
                        <div className="col-3">
                            <Button
                                label={isImageUploaded ? "" : "Tải ảnh lên"}
                                icon={isImageUploaded ? "" : "pi pi-upload"}
                                className="p-button-outlined p-button-primary responsive-button"
                                onClick={() => fileInputRef.current.click()}
                                style={{
                                    height: "140px",
                                    width: "120px",
                                    backgroundImage: `url(${showAvata})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="flex flex-column justify-content-between col-9 p-0">
                            <div className="pl-5 mb-2">
                                <label className="">Tên công trình</label>
                                <Dropdown
                                    id="buildingId"
                                    name="buildingId"
                                    value={formik.values.buildingId}
                                    options={listAddress}
                                    filter
                                    onChange={handleBuildingSelect}
                                    style={{
                                        height: "40px",
                                        width: "100%",
                                        marginTop: "5px",
                                    }}
                                    className={`responsive-input ${
                                        !formik.touched.buildingId ||
                                        !formik.errors.buildingId
                                            ? "border-300"
                                            : "border-red-500"
                                    }`}
                                />
                                {formik.touched.buildingId &&
                                formik.errors.buildingId ? (
                                    <div style={{ color: "red" }}>
                                        {formik.errors.buildingId.toString()}
                                    </div>
                                ) : null}
                            </div>
                            <div className="pl-5">
                                <label className="">Tên hạng mục</label>
                                <InputText
                                    id="categoryName"
                                    name="categoryName"
                                    value={formik.values.categoryName}
                                    onChange={formik.handleChange}
                                    style={{
                                        height: "40px",
                                        width: "100%",
                                        marginTop: "5px",
                                    }}
                                    className={
                                        formik.touched.categoryName &&
                                        formik.errors.categoryName
                                            ? "p-invalid"
                                            : ""
                                    }
                                />
                                {formik.touched.categoryName &&
                                formik.errors.categoryName ? (
                                    <div style={{ color: "red" }}>
                                        {formik.errors.categoryName.toString()}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-content-between align-items-center col-12 gap-5">
                        <div className="flex flex-column w-6">
                            <label className="mb-2">Mã hạng mục</label>
                            <InputText
                                id="categoryCode"
                                name="categoryCode"
                                value={formik.values.categoryCode}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.categoryCode &&
                                    formik.errors.categoryCode
                                        ? "p-invalid"
                                        : ""
                                }
                            />
                            {formik.touched.categoryCode &&
                            formik.errors.categoryCode ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.categoryCode.toString()}
                                </div>
                            ) : null}
                        </div>

                        <div className="flex flex-column w-6">
                            <label className="mb-2">Mã nhà máy</label>
                            <InputText
                                id="factoryCode"
                                name="factoryCode"
                                value={formik.values.factoryCode}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.factoryCode &&
                                    formik.errors.factoryCode
                                        ? "p-invalid"
                                        : ""
                                }
                            />
                            {formik.touched.factoryCode &&
                            formik.errors.factoryCode ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.factoryCode.toString()}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="flex justify-content-between align-items-center col-12 gap-5">
                        <div className="flex flex-column w-6">
                            <label className="mb-2">Mã đơn hàng</label>
                            <InputText
                                id="orderCode"
                                name="orderCode"
                                value={formik.values.orderCode}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.orderCode &&
                                    formik.errors.orderCode
                                        ? "p-invalid"
                                        : ""
                                }
                            />
                            {formik.touched.orderCode &&
                            formik.errors.orderCode ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.orderCode.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-column w-6">
                            <label className="mb-2">Số lượng</label>
                            <InputText
                                id="quantity"
                                name="quantity"
                                value={formik.values.quantity}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.quantity &&
                                    formik.errors.quantity
                                        ? "p-invalid"
                                        : ""
                                }
                            />
                            {formik.touched.quantity &&
                            formik.errors.quantity ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.quantity.toString()}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="flex justify-content-between align-items-center col-12 gap-5">
                        
                        <div className="flex flex-column w-6">
                            <label className="mb-2">Đơn vị tính</label>
                            <InputText
                                id="unit"
                                name="unit"
                                value={formik.values.unit}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.unit && formik.errors.unit
                                        ? "p-invalid"
                                        : ""
                                }
                            />
                            {formik.touched.unit && formik.errors.unit ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.unit.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-column w-6">
                        <label className="mb-2">Ghi chú</label>
                        <InputText
                            id="productNote"
                            name="productNote"
                            value={formik.values.productNote}
                            onChange={formik.handleChange}
                            className={`responsive-input ${
                                formik.touched.productNote &&
                                formik.errors.productNote
                                    ? "p-invalid"
                                    : ""
                            }`}
                            style={{ height: "40px" }}
                        />
                        {formik.touched.productNote &&
                        formik.errors.productNote ? (
                            <div style={{ color: "red" }}>
                                {formik.errors.productNote.toString()}
                            </div>
                        ) : null}
                    </div>
                    </div>

                    
                </div>
            </div>
        </Dialog>
    );
};

export default ModalAddDashbord;
