import FormatDate from "../Report/FormatDate";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "html2canvas";
import logo from "../../assets/images/logo.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const defaultImageUrl =
    "https://th.bing.com/th/id/OIP.CF-nc_bSeMzAbjG7KsjXlQHaHa?rs=1&pid=ImgDetMain";

const getBase64Image = (img) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(img);
    });
};
const fetchImageAsBase64 = async (imagePath) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const base64Image = await getBase64Image(blob);
    return base64Image;
};

const compressImage = async (imageFile, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
                (blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                },
                "image/jpeg",
                quality
            );
        };

        img.onerror = (error) => reject(error);
    });
};

const getBase64FromUrl = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        //check neus khoong cos thi bor qua
        if (!blob.type.startsWith("image/")) {
            console.warn("Invalid image format. Expected an image.");
            return null;
        }

        const compressedBase64 = await compressImage(blob, 300, 300, 0.7);
        return compressedBase64;
    } catch (error) {
        console.error("Error converting image to base64:", error);
        try {
            const defaultResponse = await fetch(defaultImageUrl);
            const defaultBlob = await defaultResponse.blob();
            const defaultCompressedBase64 = await compressImage(
                defaultBlob,
                300,
                300,
                0.7
            );
            return defaultCompressedBase64;
        } catch (defaultError) {
            console.error("Error loading default image:", defaultError);
            return null;
        }
    }
};

const prepareDataTableWithImages = async (datatable) => {
    const updatedDataTable = await Promise.all(
        datatable.map(async (product) => {
            const base64Image = await getBase64FromUrl(product.categoryImage);
            return {
                ...product,
                categoryImage: base64Image,
            };
        })
    );
    return updatedDataTable;
};

export const exportImagePDF = async (data) => {
    const imagePath = logo;

    const logoBase64 = await fetchImageAsBase64(imagePath);

    const images = {};

    for (const event of data.data.Products) {
        for (const production of event.productions) {
            if (production.Productions.length > 0) {
                if (production.Productions[0].images) {
                    for (const image of production.Productions[0].images) {
                        try {
                            images[image] = await getBase64FromUrl(image);
                        } catch (error) {
                            console.error(
                                `Failed to load image: ${image}`,
                                error
                            );
                        }
                    }
                }
            } else {
                console.log(
                    "production.Productions[0] is not iterable or does not exist."
                );
            }
        }
    }

    const groupImagesIntoRows = (images, maxPerRow) => {
        const rows = [];
        for (let i = 0; i < images.length; i += maxPerRow) {
            rows.push(images.slice(i, i + maxPerRow));
        }
        return rows;
    };

    const docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 60, 40, 60],
        content: [
            {
                image: logoBase64,
                width: 40,
                absolutePosition: { x: 40, y: 30 },
            },
            {
                text: "Báo cáo hình ảnh sản xuất",
                style: "header",
                fontSize: 20,
                margin: [0, 0, 0, 10],
                alignment: "center",
            },
            { text: data?.data.name, alignment: "center", fontSize: 16 },
            ...data.data.Products.flatMap((product) => [
                {
                    text: `Tên hạng mục: ${product.categoryName}`,
                    style: "subheader",
                    margin: [0, 20, 0, 0],
                    fontSize: 14,
                },
                {
                    text: `Mã nhà máy: ${product.factoryCode}`,
                    style: "subheader",
                    margin: [0, 5, 0, 0],
                    fontSize: 10,
                },
                ...product.productions.map((item, index) => [
                    {
                        text: `${index + 1}: ${item.name}`,
                        margin: [0, 5, 0, 5],
                        fontSize: 10,
                    },
                    item.Productions[0].images &&
                    item.Productions[0].images.length > 0
                        ? {
                              stack: groupImagesIntoRows(
                                  item.Productions[0].images.map((image) =>
                                      images[image]
                                          ? {
                                                image: images[image],
                                                width: 120, // Đảm bảo tất cả ảnh có cùng chiều rộng
                                                height: 120, // Đảm bảo tất cả ảnh có cùng chiều cao
                                                margin: [0, 0, 10, 10],
                                            }
                                          : { text: "", margin: [0, 5, 0, 5] }
                                  ),
                                  4
                              ).map((row) => ({ columns: row, columnGap: 10 })),
                          }
                        : { text: "", margin: [0, 5, 0, 5] },
                ]),
            ]),
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
            },
        },
        defaultStyle: {
            font: "Roboto",
        },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.download("production-image.pdf");
};
