import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingSpinner = () => {
  return (
    <div className='flex align-items-center justify-content-center'>
      <ProgressSpinner style={{ height: '40px', width: '40px' }} />
    </div>
  );
};

export default LoadingSpinner;
