import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css'; // Import theme
import 'primereact/resources/primereact.min.css'; // Import PrimeReact CSS
import 'primeicons/primeicons.css'; // Import PrimeIcons
import { Checkbox } from 'primereact/checkbox';
import ModelAddPermission from './ModelAddPermission';
import ModelEditPermission from './ModalEditPermission';
import ModelDelete from '../../components/ModalDelete';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  deleteGroupPermission,
  getPermission,
  getPermissionByGroupId,
  setPermissionNull,
  setPermissionToGroup,
} from '../../store/permission/slice';
import './index.css';

export default function Permission() {
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [rowData, setRowData] = useState();
  const [modalDelete, setModalDelete] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedGroup, setSelectedGroup] = useState();

  const DEFAULT_PERMISSIONS = '26782e9b-1ecd-41e6-a555-33d130021109';

  const {
    // allPermissions,
    permissionData,
    deleteGroupPermissionData,
    deleteGroupPermissionError,
    permissionDataByGroupId,
    dataInsertPermissionToGroup,
    errorInsertPermissionToGroup,
  } = useSelector(
    (state) => ({
      permissionDataByGroupId: state.Permission.dataPermissionByGroupId,
      permissionData: state.Permission.permissionData,
      deleteGroupPermissionData: state.Permission.deleteGroupPermissionData,
      deleteGroupPermissionError: state.Permission.deleteGroupPermissionError,
      dataInsertPermissionToGroup: state.Permission.dataInsertPermissionToGroup,
      errorInsertPermissionToGroup:
        state.Permission.errorInsertPermissionToGroup,
    }),
    shallowEqual
  );

  // get all group permission
  useEffect(() => {
    dispatch(getPermission({ page: 1, limit: 20 }));
  }, [dispatch]);

  // init the first group permission to selected group
  useEffect(() => {
    if (
      permissionData &&
      permissionData.items &&
      permissionData.items.length > 0
    ) {
      setSelectedGroup(permissionData.items[0]);
    }
  }, [permissionData]);

  // get all permission by group id
  useEffect(() => {
    if (selectedGroup) {
      dispatch(getPermissionByGroupId({ groupPermissionId: selectedGroup.id }));
    }
  }, [selectedGroup, dispatch]);

  useEffect(() => {
    if (permissionDataByGroupId) {
      const checkedItems = {};
      const parentCheckedItems = {};

      Object.keys(permissionDataByGroupId).forEach((parentKey) => {
        let allChildrenChecked = true;

        permissionDataByGroupId[parentKey].forEach((item) => {
          checkedItems[item.id] = item.checked;
          if (!item.checked) {
            allChildrenChecked = false;
          }
        });

        parentCheckedItems[parentKey] = allChildrenChecked;
      });

      setCheckedItems({ ...checkedItems, ...parentCheckedItems });
    }
  }, [permissionDataByGroupId]);

  //Delete group permission
  const handleDelete = (rowData) => {
    setModalDelete(true);
    setRowData(rowData);
    setSelectedGroup(rowData);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deleteGroupPermission(id));
      }
      setModalDelete(false);
    }
  };

  useEffect(() => {
    if (deleteGroupPermissionData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xóa nhóm quyền thành công',
        life: 3000,
      });
      dispatch(getPermission({ page: 1, limit: 50 }));
      dispatch(setPermissionNull());
    }
  }, [deleteGroupPermissionData]);

  useEffect(() => {
    if (deleteGroupPermissionError) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: deleteGroupPermissionError.response.data.message,
        life: 3000,
      });
      dispatch(setPermissionNull());
    }
  }, [deleteGroupPermissionError]);

  //Sửa dữ liệu
  const handleEdit = (rowData) => {
    setRowData(rowData);
    setDialogEditVisible(true);
    setSelectedGroup(rowData);
  };

  //Menu hành động
  const actionBodyTemplate = (rowData) => {
    return rowData && rowData.id !== DEFAULT_PERMISSIONS ? (
      <div className='flex'>
        <Button
          icon='pi pi-pen-to-square'
          text
          pt={{ root: { className: 'custom-button' } }}
          onClick={() => handleEdit(rowData)}
        />
        <Button
          icon='pi pi-trash'
          text
          pt={{ root: { className: 'custom-button' } }}
          onClick={() => handleDelete(rowData)}
        />
      </div>
    ) : (
      <></>
    );
  };

  // Hàm để xử lý check/uncheck một mục cha
  const handleParentCheck = (parentKey) => {
    const updatedCheckedItems = { ...checkedItems };

    // Đảo trạng thái checked của tất cả các mục con
    permissionDataByGroupId[parentKey].forEach((item) => {
      updatedCheckedItems[item.id] = !updatedCheckedItems[parentKey]; // Đảo trạng thái mục con dựa trên trạng thái mục cha
    });

    // Đảo trạng thái của mục cha
    updatedCheckedItems[parentKey] = !updatedCheckedItems[parentKey];

    // Cập nhật trạng thái với các mục đã checked mới
    setCheckedItems(updatedCheckedItems);
  };

  // Hàm để xử lý check/uncheck một mục con cụ thể
  const handleChildCheck = (childId, parentKey) => {
    const updatedCheckedItems = {
      ...checkedItems,
      [childId]: !checkedItems[childId],
    };

    // Kiểm tra nếu tất cả các mục con đều được checked
    const allChildrenChecked = permissionDataByGroupId[parentKey].every(
      (item) => updatedCheckedItems[item.id]
    );

    // Cập nhật trạng thái của mục cha dựa trên các mục con
    updatedCheckedItems[parentKey] = allChildrenChecked;

    // Cập nhật trạng thái với các mục đã checked mới
    setCheckedItems(updatedCheckedItems);
  };

  // Hàm để lấy ra một mảng tất cả các id của các mục con được tích
  const getCheckedChildIds = () => {
    const checkedChildIds = [];
    // Duyệt qua tất cả các nhóm quyền
    permissionDataByGroupId &&
      Object.keys(permissionDataByGroupId).forEach((parentKey) => {
        // Duyệt qua tất cả các mục con của mỗi nhóm
        permissionDataByGroupId[parentKey].forEach((item) => {
          if (checkedItems[item.id]) {
            checkedChildIds.push(item.id);
          }
        });
      });

    return checkedChildIds;
  };

  const setPermissionsGroup = () => {
    const listCheckedPermissions = getCheckedChildIds();
    dispatch(
      setPermissionToGroup({
        groupPermissionId: selectedGroup?.id,
        listPermissions: listCheckedPermissions,
      })
    );
  };

  useEffect(() => {
    if (dataInsertPermissionToGroup) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Cập nhật quyền thành công',
        life: 3000,
      });
      dispatch(
        getPermissionByGroupId({ groupPermissionId: selectedGroup?.id })
      );
    }
  }, [dataInsertPermissionToGroup]);

  useEffect(() => {
    if (errorInsertPermissionToGroup) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorInsertPermissionToGroup.response.data.message,
        life: 3000,
      });
    }
  }, [errorInsertPermissionToGroup]);

  return (
    <>
      <Toast ref={toast} />
      <div className='flex flex-column lg:flex-row gap-3 '>
        <div className='bg-white border-round-md shadow-1 p-3 flex flex-column gap-2 w-full lg:w-4 xl:w-3'>
          <div className='flex justify-content-between align-items-center flex-wrap'>
            <h3>Nhóm quyền</h3>
            <Button
              label='Thêm'
              onClick={() => setDialogVisible(true)}
              style={{ height: '30px', width: '100px' }}
            />
          </div>
          <div className='border-1 border-round-xl border-300 '>
            <DataTable
              value={permissionData?.items}
              onRowClick={(e) => setSelectedGroup(e.data)}
              selection={selectedGroup}
              selectionMode='single'
              showHeaders={false}
              emptyMessage='Chưa có nhóm quyền'
              scrollable
              scrollHeight='80vh'
              pt={{
                root: { className: 'custom-table' },
              }}
              style={{ borderRadius: '10px' }}
              className='overflow-hidden'>
              <Column
                field='groupName'
                style={{ minWidth: '100px' }}
                body={(rowData) => (
                  <div className='ml-3'>{rowData.groupName}</div>
                )}
              />
              <Column
                field='description'
                style={{ display: 'none' }}
              />
              <Column
                body={actionBodyTemplate}
                alignHeader={'center'}
                align={'right'}
                style={{ width: '10%', height: '40px' }}
              />
            </DataTable>
          </div>
        </div>
        <div className='bg-white border-round-md shadow-1 p-3 flex flex-column gap-2 w-full lg:w-8 xl:w-9'>
          <div className='flex justify-content-between'>
            <h3
              className='flex align-items-center'
              style={{ height: '30px' }}>
              {selectedGroup?.groupName}
            </h3>
            {selectedGroup && selectedGroup.id !== DEFAULT_PERMISSIONS && (
              <Button
                label='Lưu'
                style={{ height: '30px', width: '100px' }}
                onClick={setPermissionsGroup}
              />
            )}
          </div>
          <div className='flex flex-column gap-1'>
            <div className='flex justify-content-around flex-wrap px-5 border-1 border-round-xl border-300 '>
              {permissionDataByGroupId &&
                Object.keys(permissionDataByGroupId).map((key) => (
                  <div
                    key={key}
                    className='w-6 xl:w-3  my-3'>
                    <div className='font-bold mb-3 text-primary flex align-items-center'>
                      <Checkbox
                        checked={checkedItems[key] === true}
                        onChange={() => handleParentCheck(key)}
                        inputId={key}
                        pt={{
                          root: { className: 'custom-checkbox' },
                        }}
                        disabled={selectedGroup?.id === DEFAULT_PERMISSIONS}
                      />
                      <label
                        htmlFor={key}
                        className='ml-2 cursor-pointer'>
                        {key}
                      </label>
                    </div>
                    {permissionDataByGroupId[key].map((item) => (
                      <div
                        key={item.id}
                        className='my-2 flex align-items-center'>
                        <Checkbox
                          inputId={item.id}
                          checked={checkedItems[item.id]}
                          onChange={() => handleChildCheck(item.id, key)}
                          disabled={selectedGroup?.id === DEFAULT_PERMISSIONS}
                        />
                        <label
                          className='ml-2 cursor-pointer'
                          htmlFor={item.id}>
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <ModelAddPermission
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        toast={toast}
      />
      <ModelEditPermission
        visible={dialogEditVisible}
        onHide={() => setDialogEditVisible(false)}
        toast={toast}
        rowData={rowData}
        setSelectedGroup={setSelectedGroup}
      />
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg='Bạn chắc chắn muốn xóa nhóm quyền này?'
      />
    </>
  );
}
