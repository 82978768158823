import { call, put, takeEvery } from "redux-saga/effects";

import {
    getProductionAllSuccess,
    getProductionAllFail,
    getOrderFail,
    getOrderSuccess,
    getPlanFail,
    getPlanSuccess,
    setPlanSuccess,
    setPlanFail,
    getBuildingFail,
    getBuildingSuccess,
    setProductFail,
    setProductSuccess,
    getTeamProgressFail,
    getTeamProgressSuccess,
    addPriceSuccess,
    addPriceFail,
    addQrFail,
    addQrSuccess,
    updateProductionSuccess,
    updateProductionFail,
    updatePlanSuccess,
    updatePlanFail,
    deletePlanSuccess,
    deletetePlanFail,
    getPlanLeadSuccess,
    getPlanLeadFail,
    setPlanLeadSuccess,
    setPlanLeadFail,
    updatePlanLeadSuccess,
    updatePlanLeadFail,
    deletePlanLeadSuccess,
    deletetePlanLeadFail,
    getTeamChildrenFail,
    getTeamChildrenSuccess,
    downloadExcelSuccess,
    downloadExcelFail,
    importExcelDataFail,
    importExcelDataSuccess,
    downloadExcel2Success,
    downloadExcel2Fail,
    importExcelData2Success,
    importExcelData2Fail,
    importExcelData4Fail,
    importExcelData4Success,
    downloadExcel4Success,
    downloadExcel4Fail,
    deleteOrderFail,
    deleteOrderSuccess,
    editOrderSuccess,
    editOrderFail,
    exportExcelSuccess,
    exportExcelFail,
    exportExcel2Success,
    exportExcel2Fail,
    exportExcel3Success,
    exportExcel3Fail,
    exportExcel4Success,
    exportExcel4Fail,
    getStepFail,
    getStepSuccess,
    setStepFail,
    setStepSuccess,
    updateStepSuccess,
    updateStepFail,
    deleteStepSuccess,
    deleteteStepFail,
    downloadExcel3Success,
    downloadExcel3Fail,
    importExcelData3Fail,
    importExcelData3Success,
    exportExcel5Fail,
    exportExcel5Success,
    setHistorySuccess,
    setHistoryFail,
    updateHistorySuccess,
    updateHistoryFail,
    deleteHistorySuccess,
    deleteHistoryFail,
    getDViFullFail,
    getDViFullSuccess,
    getPlanCategoryMaterialSuccess,
    getPlanCategoryMaterialFail,
    setPlanCategoryMaterialSuccess,
    setPlanCategoryMaterialFail,
    updatePlanCategoryMaterialSuccess,
    updatePlanCategoryMaterialFail,
    deletePlanCategoryMaterialSuccess,
    deletePlanCategoryMaterialFail,
} from "./slice";
import {
    getProductionAll,
    getOrder as getOrderAPI,
    getPlan,
    setPlan,
    getBuilding,
    setProduct,
    getTeamProgress,
    addPrice,
    updateProduction,
    addQr,
    updatePlan,
    deletePlan,
    getTeamChildren,
    downloadExcel,
    importDataExcel,
    editOrder,
    deleteOrder,
    exportExcel,
    getStep,
    setStep,
    updateStep,
    deleteStep,
    getDViFull,
} from "../../api/backend_helper";
import axios from "axios";
import {
    IMPORT_DATA_EXCEL,
    IMPORT_DATA_EXCEL2,
    IMPORT_DATA_EXCEL3,
    IMPORT_DATA_EXCEL4,
} from "../../api/url_helper";
const token = localStorage.getItem("accessToken");

function* getProduction() {
    try {
        const data = yield call(getProductionAll);
        yield put(getProductionAllSuccess(data));
    } catch (error) {
        yield put(getProductionAllFail(error.message));
    }
}

function* getOrderSaga(action) {
    try {
        const {
            page,
            limit,
            category,
            factoryCode,
            building,
            status,
            orderCode,
            current,
            notMachining,
            notMaterial,
        } = action.payload;
        const data = yield call(getOrderAPI, {
            page,
            limit,
            category,
            factoryCode,
            building,
            status,
            orderCode,
            current,
            notMachining,
            notMaterial,
        });
        yield put(getOrderSuccess(data));
    } catch (error) {
        yield put(getOrderFail(error.message));
    }
}

function* getTeamChildrenSaga(action) {
    try {
        const { team, factoryCode } = action.payload;
        const data = yield call(getTeamChildren, { team, factoryCode });
        yield put(getTeamChildrenSuccess(data));
    } catch (error) {
        yield put(getTeamChildrenFail(error.message));
    }
}

function* getBuildingSaga(action) {
    try {
        const { page, limit, keyword } = action.payload;
        const data = yield call(getBuilding, { page, limit, keyword });
        yield put(getBuildingSuccess(data));
    } catch (error) {
        yield put(getBuildingFail(error.message));
    }
}

function* getPlanSaga(action) {
    try {
        const { page, limit, productId, type } = action.payload;
        const data = yield call(getPlan, { page, limit, productId, type });
        yield put(getPlanSuccess(data));
    } catch (error) {
        yield put(getPlanFail(error.message));
    }
}

function* getStepSaga(action) {
    try {
        const { page, limit, factoryCode, type } = action.payload;
        const data = yield call(getStep, { page, limit, factoryCode });
        yield put(getStepSuccess(data));
    } catch (error) {
        yield put(getStepFail(error.message));
    }
}

function* getPlanLeadSaga(action) {
    try {
        const { page, limit, productId, type } = action.payload;
        const data = yield call(getPlan, { page, limit, productId, type });
        yield put(getPlanLeadSuccess(data));
    } catch (error) {
        yield put(getPlanLeadFail(error.message));
    }
}

function* getPlanCategoryMaterialSaga(action) {
    try {
        const { page, limit, productId, type } = action.payload;
        const data = yield call(getPlan, { page, limit, productId, type });
        yield put(getPlanCategoryMaterialSuccess(data));
    } catch (error) {
        yield put(getPlanCategoryMaterialFail(error.message));
    }
}

function* setPlanSaga(action) {
    try {
        const data = yield call(setPlan, action.payload);
        yield put(setPlanSuccess(data));
    } catch (error) {
        yield put(setPlanFail(error));
    }
}
function* setHistorySaga(action) {
    try {
        const data = yield call(setPlan, action.payload);
        yield put(setHistorySuccess(data));
    } catch (error) {
        yield put(setHistoryFail(error));
    }
}

function* setStepSaga(action) {
    try {
        const data = yield call(setStep, action.payload);
        yield put(setStepSuccess(data));
    } catch (error) {
        yield put(setStepFail(error));
    }
}

function* setPlanLeadSaga(action) {
    try {
        const data = yield call(setPlan, action.payload);
        yield put(setPlanLeadSuccess(data));
    } catch (error) {
        yield put(setPlanLeadFail(error));
    }
}

function* setPlanCategoryMaterialSaga(action) {
    try {
        const data = yield call(setPlan, action.payload);
        yield put(setPlanCategoryMaterialSuccess(data));
    } catch (error) {
        yield put(setPlanCategoryMaterialFail(error));
    }
}


function* updatePlanSaga(action) {
    try {
        const { id, data } = action.payload;
        const response = yield call(updatePlan, id, data);
        yield put(updatePlanSuccess(response));
    } catch (error) {
        yield put(updatePlanFail(error));
    }
}

function* updateHistorySaga(action) {
    try {
        const { id, data } = action.payload;
        const response = yield call(updatePlan, id, data);
        yield put(updateHistorySuccess(response));
    } catch (error) {
        yield put(updateHistoryFail(error));
    }
}

function* updateStepSaga(action) {
    try {
        const { id, data } = action.payload;
        const response = yield call(updateStep, id, data);
        yield put(updateStepSuccess(response));
    } catch (error) {
        yield put(updateStepFail(error));
    }
}

function* updatePlanLeadSaga(action) {
    try {
        const { id, data } = action.payload;
        const response = yield call(updatePlan, id, data);
        yield put(updatePlanLeadSuccess(response));
    } catch (error) {
        yield put(updatePlanLeadFail(error));
    }
}

function* updatePlanCategoryMaterialSaga(action) {
    try {
        const { id, data } = action.payload;
        const response = yield call(updatePlan, id, data);
        yield put(updatePlanCategoryMaterialSuccess(response));
    } catch (error) {
        yield put(updatePlanCategoryMaterialFail(error));
    }
}

function* removePlanSaga({ payload }) {
    try {
        const response = yield call(deletePlan, payload.id);
        yield put(deletePlanSuccess(response));
    } catch (error) {
        yield put(deletetePlanFail(error));
    }
}

function* removeHistorySaga({ payload }) {
    try {
        const response = yield call(deletePlan, payload.id);
        yield put(deleteHistorySuccess(response));
    } catch (error) {
        yield put(deleteHistoryFail(error));
    }
}

function* removeStepSaga({ payload }) {
    try {
        const response = yield call(deleteStep, payload.id);
        yield put(deleteStepSuccess(response));
    } catch (error) {
        yield put(deleteteStepFail(error));
    }
}

function* removePlanLeadSaga({ payload }) {
    try {
        const response = yield call(deletePlan, payload.id);
        yield put(deletePlanLeadSuccess(response));
    } catch (error) {
        yield put(deletetePlanLeadFail(error));
    }
}

function* removePlanCategoryMaterialSaga({ payload }) {
    try {
        const response = yield call(deletePlan, payload.id);
        yield put(deletePlanCategoryMaterialSuccess(response));
    } catch (error) {
        yield put(deletePlanCategoryMaterialFail(error));
    }
}

function* setProductSaga(action) {
    try {
        const { values, file } = action.payload;

        const data = new FormData();
        data.append("factoryCode", values.factoryCode);
        data.append("unit", values.unit);
        data.append("categoryName", values.categoryName);
        data.append("quantity", values.quantity);
        data.append("buildingId", values.buildingId);
        data.append("productNote", values.productNote);
        data.append("categoryCode", values.categoryCode);
        data.append("orderCode", values.orderCode);
        data.append("productCode", values.productCode);

        if (file) {
            data.append("file", file);
        }
        const response = yield call(setProduct, action.payload.values);
        yield put(setProductSuccess(response.data));
    } catch (error) {
        yield put(setProductFail(error));
    }
}

function* editOrderSaga(action) {
    try {
        const { values, file } = action.payload;

        const data = new FormData();
        data.append("factoryCode", values.factoryCode);
        data.append("unit", values.unit);
        data.append("categoryName", values.categoryName);
        data.append("quantity", values.quantity);
        data.append("buildingId", values.buildingId);
        data.append("productNote", values.productNote);
        data.append("categoryCode", values.categoryCode);
        data.append("orderCode", values.orderCode);
        data.append("productCode", values.productCode);

        if (file) {
            data.append("file", file);
        }
        const response = yield call(editOrder, action.payload.values);
        yield put(editOrderSuccess(response.data));
    } catch (error) {
        yield put(editOrderFail(error));
    }
}

function* getTeamProgressSaga(action) {
    try {
        const { id } = action.payload;
        const data = yield call(getTeamProgress, id);
        yield put(getTeamProgressSuccess(data));
    } catch (error) {
        yield put(getTeamProgressFail(error.message));
    }
}

function* addPriceSaga(action) {
    try {
        const values = action.payload;
        const data = yield call(addPrice, values);
        yield put(addPriceSuccess(data));
    } catch (error) {
        yield put(addPriceFail(error));
    }
}

function* addQrSaga(action) {
    try {
        const values = action.payload;
        const data = yield call(addQr, values);
        yield put(addQrSuccess(data));
    } catch (error) {
        yield put(addQrFail(error));
    }
}

function* updateProductionSaga(action) {
    try {
        const values = action.payload;
        const data = yield call(updateProduction, values);
        yield put(updateProductionSuccess(data));
    } catch (error) {
        yield put(updateProductionFail(error.message));
    }
}

function* downloadExcelSaga(action) {
    try {
        const data = yield call(downloadExcel, action.payload);
        yield put(downloadExcelSuccess(data));
    } catch (error) {
        yield put(downloadExcelFail(error));
    }
}

function* downloadExcel2Saga(action) {
    try {
        const data = yield call(downloadExcel, action.payload);
        yield put(downloadExcel2Success(data));
    } catch (error) {
        yield put(downloadExcel2Fail(error));
    }
}

function* downloadExcel3Saga(action) {
    try {
        const data = yield call(downloadExcel, action.payload);
        yield put(downloadExcel3Success(data));
    } catch (error) {
        yield put(downloadExcel3Fail(error));
    }
}

function* downloadExcel4Saga(action) {
    try {
        const data = yield call(downloadExcel, action.payload);
        yield put(downloadExcel4Success(data));
    } catch (error) {
        yield put(downloadExcel4Fail(error));
    }
}

function* importExcelSaga(action) {
    try {
        const { files } = action.payload;
        const data = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }
        }
        const response = yield call(axios.post, IMPORT_DATA_EXCEL, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });
        yield put(importExcelDataSuccess(response.data));
    } catch (error) {
        yield put(importExcelDataFail(error));
    }
}

function* importExcel2Saga(action) {
    try {
        const { files } = action.payload;
        const data = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }
        }
        const response = yield call(axios.post, IMPORT_DATA_EXCEL2, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });
        yield put(importExcelData2Success(response.data));
    } catch (error) {
        yield put(importExcelData2Fail(error));
    }
}

function* importExcel3Saga(action) {
    try {
        const { files } = action.payload;
        const data = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }
        }
        const response = yield call(axios.post, IMPORT_DATA_EXCEL3, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });
        yield put(importExcelData3Success(response.data));
    } catch (error) {
        yield put(importExcelData3Fail(error));
    }
}

function* importExcel4Saga(action) {
    try {
        const { files } = action.payload;
        const data = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }
        }
        const response = yield call(axios.post, IMPORT_DATA_EXCEL4, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });
        yield put(importExcelData4Success(response.data));
    } catch (error) {
        yield put(importExcelData4Fail(error));
    }
}

function* deleteOrderSaga(action) {
    try {
        const values = action.payload;
        const data = yield call(deleteOrder, values);
        yield put(deleteOrderSuccess(data));
    } catch (error) {
        yield put(deleteOrderFail(error));
    }
}

function* exportExcelSaga(action) {
    try {
        const data = yield call(exportExcel, action.payload);
        yield put(exportExcelSuccess(data));
    } catch (error) {
        yield put(exportExcelFail(error));
    }
}

function* exportExcel2Saga(action) {
    try {
        const data = yield call(exportExcel, action.payload);
        yield put(exportExcel2Success(data));
    } catch (error) {
        yield put(exportExcel2Fail(error));
    }
}

function* exportExcel3Saga(action) {
    try {
        const data = yield call(exportExcel, action.payload);
        yield put(exportExcel3Success(data));
    } catch (error) {
        yield put(exportExcel3Fail(error));
    }
}

function* exportExcel4Saga(action) {
    try {
        const data = yield call(exportExcel, action.payload);
        yield put(exportExcel4Success(data));
    } catch (error) {
        yield put(exportExcel4Fail(error));
    }
}
function* exportExcel5Saga(action) {
    try {
        const data = yield call(exportExcel, action.payload);
        yield put(exportExcel5Success(data));
    } catch (error) {
        yield put(exportExcel5Fail(error));
    }
}

function* getDViFullSaga(action) {
    try {
      const { id, data } = action.payload;
      const response = yield call(getDViFull, id, data);
      yield put(getDViFullSuccess(response));
    } catch (error) {
      yield put(getDViFullFail(error));
    }
  }

function* ProductionSaga() {
    yield takeEvery("productions/deletePlanCategoryMaterial", removePlanCategoryMaterialSaga);
    yield takeEvery("productions/setPlanCategoryMaterial", setPlanCategoryMaterialSaga);
    yield takeEvery("productions/getPlanCategoryMaterial", getPlanCategoryMaterialSaga);
    yield takeEvery("productions/getDViFull", getDViFullSaga);
    yield takeEvery("productions/getProductionAll", getProduction);
    yield takeEvery("productions/getOrder", getOrderSaga);
    yield takeEvery("productions/getPlan", getPlanSaga);
    yield takeEvery("productions/setPlan", setPlanSaga);
    yield takeEvery("productions/getBuilding", getBuildingSaga);
    yield takeEvery("productions/getTeamProgress", getTeamProgressSaga);
    yield takeEvery("productions/addPrice", addPriceSaga);
    yield takeEvery("productions/addQr", addQrSaga);
    yield takeEvery("productions/updateProduction", updateProductionSaga);
    yield takeEvery("productions/setProduct", setProductSaga);
    yield takeEvery("productions/updatePlan", updatePlanSaga);
    yield takeEvery("productions/deletePlan", removePlanSaga);
    yield takeEvery("productions/getPlanLead", getPlanLeadSaga);
    yield takeEvery("productions/setPlanLead", setPlanLeadSaga);
    yield takeEvery("productions/updatePlanLead", updatePlanLeadSaga);
    yield takeEvery("productions/updatePlanCategoryMaterial", updatePlanCategoryMaterialSaga);
    yield takeEvery("productions/deletePlanLead", removePlanLeadSaga);
    yield takeEvery("productions/getTeamChildren", getTeamChildrenSaga);
    yield takeEvery("productions/downloadExcel", downloadExcelSaga);
    yield takeEvery("productions/downloadExcel2", downloadExcel2Saga);
    yield takeEvery("productions/downloadExcel3", downloadExcel3Saga);
    yield takeEvery("productions/downloadExcel4", downloadExcel4Saga);
    yield takeEvery("productions/importExcelData", importExcelSaga);
    yield takeEvery("productions/importExcelData2", importExcel2Saga);
    yield takeEvery("productions/importExcelData3", importExcel3Saga);
    yield takeEvery("productions/importExcelData4", importExcel4Saga);
    yield takeEvery("productions/deleteOrder", deleteOrderSaga);
    yield takeEvery("productions/editOrder", editOrderSaga);
    yield takeEvery("productions/exportExcel", exportExcelSaga);
    yield takeEvery("productions/exportExcel2", exportExcel2Saga);
    yield takeEvery("productions/exportExcel3", exportExcel3Saga);
    yield takeEvery("productions/exportExcel4", exportExcel4Saga);
    yield takeEvery("productions/exportExcel5", exportExcel5Saga);
    yield takeEvery("productions/getStep", getStepSaga);
    yield takeEvery("productions/setStep", setStepSaga);
    yield takeEvery("productions/updateStep", updateStepSaga);
    yield takeEvery("productions/deleteStep", removeStepSaga);
    yield takeEvery("productions/setHistory", setHistorySaga);
    yield takeEvery("productions/updateHistory", updateHistorySaga);
    yield takeEvery("productions/deleteHistory", removeHistorySaga);
}

export default ProductionSaga;
