import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { downloadExcel3, importExcelData3,setDataNull2 } from "../../store/order/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import UploadFile from "../../components/UploadFile";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { site } from "../../api/url_helper";
import { ProgressSpinner } from "primereact/progressspinner";


const ModalExcel = ({ visible, onHide }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [clearFiles, setClearFiles] = useState(false);
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);

    const { excelData3, importData3, errorImportData3,errorExcelData3 } = useSelector(
        (state) => ({
            excelData3: state.Production.excelData3,
            importData3: state.Production.importData3,
            errorImportData3: state.Production.errorImportData3,
            errorExcelData3: state.Production.errorExcelData3,
        }),
        shallowEqual
    );

    const handleDownloadClick = () => {
        setLoading(true);
        dispatch(downloadExcel3({ type: "production" }));
    };

    const showToast = (severity, summary, detail) => {
        toast.current?.show({ severity, summary, detail, life: 3000 });
    };

    useEffect(() => {
        if (excelData3) {
            setLoading(false);
            const { file } = excelData3;
            if (file) {
                const fileUrl = `${site}/${file}`;
                const link = document.createElement("a");
                link.href = fileUrl;
                link.setAttribute("download", "Sample data.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(setDataNull2());
                showToast('success', 'Thành công', 'Tải thành công');
            }
        } else if (errorExcelData3) {
            setLoading(false);
            showToast('error', 'Thất bại', errorExcelData3.response.data.message);
            dispatch(setDataNull2());
        }
    }, [excelData3, errorExcelData3, dispatch]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: () => {
            setLoading(true);
            dispatch(importExcelData3({ files: Array.from(selectedFiles) }));
        },
    });

    useEffect(() => {
        if (importData3) {
            setLoading(false);
            showToast("success", "Thành công", "Nhập thành công");
            formik.resetForm();
            onHide();
        } else if (errorImportData3) {
            setLoading(false);
            showToast("error", "Thất bại", errorImportData3?.response?.data?.message);
            dispatch(setDataNull2());
        }
    }, [importData3, errorImportData3, dispatch, formik, onHide]);

    const header = <div>Thêm từ Excel</div>;

    return (
        <>
        <Toast ref={toast} />
        <Dialog
            visible={visible}
            className="responsive-dialog"
            onHide={() => {
                onHide();
            }}
            header={header}
            style={{ width: "40%" }}
        >
            {loading && (
                    <div className="flex justify-content-center align-items-center">
                        <ProgressSpinner />
                    </div>
                )}
                {!loading && (
                    <>
            <p className="font-bold">Các bước</p>
            <div className="bg-yellow-100 px-5 py-3 line-height-4 border-round-md my-4">
                <span>
                    1. Tải file mẫu và nhập dữ liệu (
                    <a
                        className="text-blue-500 font-bold underline cursor-pointer"
                        onClick={handleDownloadClick}
                    >
                        Tải file mẫu
                    </a>
                    )
                </span>
                <span className="text-red-500 font-bold"></span>
                <p>2. Nhập dữ liệu chính xác theo các trường.</p>
                <p className="text-red-500">
                    Lưu ý: Không thay đổi tên và thứ tự các cột.
                </p>
            </div>
            <UploadFile
                onUpload={() => {}}
                getFiles={setSelectedFiles}
                clearFiles={clearFiles}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "40px",
                    gap: "1rem",
                }}
            >
                <div className="card">
                    <Button
                        label="Hủy"
                        className="px-5 py-2 bg-white border-none text-400"
                        severity="secondary"
                        onClick={() => {
                            onHide();
                        }}
                    />
                </div>
                <div className="card">
                    <Button
                        label="Nhập"
                        className="px-5 py-2 bg-green-700"
                        // severity="info"
                        onClick={formik.handleSubmit}
                        type="submit"
                    />
                </div>
            </div>
            </>
            )}
        </Dialog>
        </>
    );
};

export default ModalExcel;
