import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listStatus: null,
    error: null,
    loading: false,
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        getListStatus: (state) => {
            state.loading = true;
        },
        getListStatusSuccess: (state, action) => {
            state.loading = false;
            state.listStatus = action.payload;
            state.error = null;
        },
        getListStatusFail: (state, action) => {
            state.loading = false;
            state.listStatus = null;
            state.error = action.payload;
        },
    },
});

export const { getListStatus, getListStatusFail, getListStatusSuccess } =
    productSlice.actions;

export default productSlice.reducer;
