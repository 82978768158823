import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reportQuality: null,
    error: null,
    loading: false,
};

const reportQualitySlice = createSlice({
    name: "reportQuality",
    initialState,
    reducers: {
        getReportQuality: (state, action) => {
            state.loading = true;
        },
        getReportQualitySuccess: (state, action) => {
            state.loading = false;
            state.reportQuality = action.payload;
            state.error = null;
        },
        getReportQualityFail: (state, action) => {
            state.loading = false;
            state.reportQuality = false;
            state.error = action.payload;
        },
    },
});

export const {
    getReportQuality,
    getReportQualityFail,
    getReportQualitySuccess,
} = reportQualitySlice.actions;

export default reportQualitySlice.reducer;
