import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import ModelAddUser from './ModelAddUser';
import ActionMenu from '../../components/ActionMenu';
import ModelDelete from '../../components/ModalDelete';
import ModelEditUser from './ModelEditUser';
import { Paginator } from 'primereact/paginator';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  deleteUser,
  getAllUser,
  setDataInsertNull,
} from '../../store/auth/slice.js';
import { getPermission } from '../../store/permission/slice';

function Users() {
  const listPermissions =
    JSON.parse(localStorage.getItem('userPermissions')) || {};

  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState();
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const toast = useRef(null);

  const [valueSearch, setValueSearch] = useState('');

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const { dataAllUser, permissionData, dataDeleteUser, errorDeleteUser } =
    useSelector(
      (state) => ({
        dataAllUser: state.Auth.dataAllUser,
        errorAllUser: state.Auth.errorAllUser,
        permissionData: state.Permission.permissionData,
        dataDeleteUser: state.Auth.dataDeleteUser,
        errorDeleteUser: state.Auth.errorDeleteUser,
      }),
      shallowEqual
    );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllUser({
        page: (first + rows) / rows,
        limit: rows,
        keyword: valueSearch,
      })
    );
  }, [valueSearch, first, rows, dialogVisible, dialogEditVisible]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    if (dialogVisible || dialogEditVisible) {
      dispatch(getPermission());
    }
  }, [dialogVisible, dialogEditVisible]);

  //Sửa dữ liệu
  const handleEdit = (rowData) => {
    setRowData(rowData);
    setDialogEditVisible(true);
  };

  //Xóa dữ liệu
  const handleDelete = (rowData) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined && id === '4cf97ce5-f42f-4c1e-92bb-00f894081a43') {
        toast.current.show({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Không thể xóa người dùng này',
          life: 3000,
        });
      }
      if (id !== undefined && id !== '4cf97ce5-f42f-4c1e-92bb-00f894081a43') {
        dispatch(deleteUser({ id, flag: true }));
      }
      setModalDelete(false);
    }
  };

  useEffect(() => {
    if (dataDeleteUser) {
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xóa người dùng thành công',
        life: 3000,
      });
      dispatch(setDataInsertNull());
      dispatch(
        getAllUser({
          page: (first + rows) / rows,
          limit: rows,
          keyword: valueSearch,
        })
      );
    }
  }, [dataDeleteUser]);

  useEffect(() => {
    if (errorDeleteUser) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorDeleteUser.response.data.message,
        life: 3000,
      });
      dispatch(setDataInsertNull());
    }
  }, [errorDeleteUser]);

  //Menu hành động
  const actionBodyTemplate = (rowData) => {
    return listPermissions['Người dùng'] &&
      listPermissions['Người dùng'].includes('Sửa người dùng') &&
      listPermissions['Người dùng'].includes('Xóa người dùng') ? (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
      />
    ) : (
      <></>
    );
  };

  // const handlePasswordToggle = (id) => {
  //   setVisiblePasswordId(visiblePasswordId === id ? null : id);
  // };

  // const passwordBodyTemplate = (rowData) => {
  //   const isVisible = visiblePasswordId === rowData.id;
  //   return (
  //     <div className='flex align-items-center'>
  //       <InputText
  //         style={{ border: '1px solid #ffffff', width: '100px' }}
  //         type={isVisible ? 'text' : 'password'}
  //         value={rowData.password || ''}
  //         readOnly
  //       />
  //       <Button
  //         icon={`pi ${isVisible ? 'pi-eye-slash' : 'pi-eye'}`}
  //         onClick={() => handlePasswordToggle(rowData.id)}
  //         text
  //       />
  //     </div>
  //   );
  // };

  return (
    <>
      <Toast ref={toast} />
      <div className='flex flex-row justify-content-between mb-3'>
        {listPermissions['Người dùng'] &&
          listPermissions['Người dùng'].includes('Thêm người dùng') && (
            <Button
              label='Thêm mới'
              size='small'
              onClick={() => setDialogVisible(true)}
            />
          )}
        <IconField
          iconPosition='left'
          className='w-6'>
          <InputIcon className='pi pi-search'></InputIcon>
          <InputText
            placeholder='Search'
            className='w-full border-0'
            value={valueSearch}
            onChange={(e) => setValueSearch(e.target.value)}
          />
        </IconField>
      </div>
      <div className='bg-white border-round-md shadow-1 p-3'>
        <div className='card w-full'>
          <DataTable
            value={dataAllUser?.data?.items}
            size='small'
            emptyMessage='Không có người dùng'
            scrollable
            style={{ minWidth: '300px' }}
            scrollHeight='600px'>
            <Column
              header='STT'
              field='stt'
              style={{ width: '5%' }}
              alignHeader={'center'}
              align={'center'}
              body={(rowData, { rowIndex }) => (
                <span>{first + rowIndex + 1}</span>
              )}
            />
            <Column
              field='name'
              header='Tên hiển thị'
              style={{ minWidth: '120px' }}
            />
            <Column
              header='Nhóm quyền'
              body={(data) => data.GroupPermissions[0]?.groupName}
              style={{ minWidth: '120px' }}
            />
            <Column
              field='email'
              header='Tên đăng nhập'
              style={{ minWidth: '100px' }}
            />
            <Column
              field='password'
              header='Mật khẩu'
              style={{ minWidth: '100px' }}
              body={() => '********'}
            />
            <Column
              field='note'
              header='Ghi chú'
              style={{ minWidth: '150px' }}
            />
            <Column
              field='action'
              body={actionBodyTemplate}
              style={{ minWidth: '100px', height: '50px' }}
            />
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={dataAllUser?.data?.totalItems}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <ModelAddUser
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        toast={toast}
        permission={permissionData?.items}
      />
      <ModelEditUser
        visible={dialogEditVisible}
        onHide={() => setDialogEditVisible(false)}
        toast={toast}
        rowData={rowData}
        permission={permissionData?.items}
      />
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: 'center' }}>
            Bạn chắc chắn muốn xóa người dùng này?
          </div>
        }
      />
    </>
  );
}

export default Users;
