import React, { useState, useEffect } from "react";
import {  formatNumber } from '../../components/utils';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { addPrice,setDataNull2 } from "../../store/order/slice";
import "../../assets/scss/theme-base/ModalAddPlan.css";

        


const ModalAddPrice = ({ isShow, hiden, toast,rowData }) => {
  const dispatch = useDispatch();

  
  const { addPriceData,errorPrice } = useSelector(
    (state) => ({
      addPriceData: state.Production.addPriceData,
      errorPrice: state.Production.errorPrice,
    }),
    shallowEqual
  );

  

  // Validation schema
  const validationSchema = Yup.object().shape({
    price: Yup.string().required("Đơn giá là bắt buộc"),
  });



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: formatNumber(rowData?.price) || "",
      priceNote: rowData?.priceNote ||"",
      factoryCode: rowData?.factoryCode || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      const formattedPrice = value.price.replace(/\./g, '');
      const data = { ...value, price: formattedPrice };
      dispatch(addPrice(data));
    },
  });

  const handlePriceChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^\d]/g, '');
    const formattedValue = formatNumber(numericValue);
    formik.setFieldValue('price', formattedValue);
  };
  

  useEffect(() => {
    if (errorPrice) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: errorPrice?.response?.data?.message,
        life: 5000,
      });
      // dispatch(setDataNull2());
    }
    if (addPriceData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Thêm Đơn giá thành công",
        life: 5000,
      });
      formik.resetForm();
      dispatch(setDataNull2());
      hiden();
    }
  }, [errorPrice, addPriceData]);

  return (
    <Dialog
      visible={isShow}
      onHide={() => {
        formik.resetForm();
        hiden();
      }}
      className="responsive-dialog"
      header="Nhập đơn giá"
      modal
      style={{ width: "450px", height: "45%" }}
      footer={
        <div>
          <Button
            label="Hủy"
            onClick={() => {
              formik.resetForm();
              hiden();
            }}
            severity="secondary"
            className="p-button-text"
          />
          <Button type="submit" label="Lưu" onClick={formik.handleSubmit} />
        </div>
      }
    >
      <div className="bg-white p-3 border-round-md">
        <div className="flex flex-column mb-4 gap-3">
          

          <div className="flex flex-column w-12 mb-3">
            <label className="mb-2">Đơn giá</label>
            <InputText
              id="price"
              name="price"
              value={formik.values.price}
              onChange={handlePriceChange}
              style={{ height: "40px" }}
              className={
                formik.touched.price && formik.errors.price
                  ? "p-invalid"
                  : ""
              }
            />
            {formik.touched.price && formik.errors.price ? (
              <div style={{ color: "red" }}>
                {formik.errors.price.toString()}
              </div>
            ) : null}
          </div>
          <div className="flex flex-column w-12 mb-3">
            <label className="mb-2">Ghi chú</label>
            <InputText
              id="priceNote"
              name="priceNote"
              value={formik.values.priceNote}
              onChange={formik.handleChange}
              style={{ height: "40px" }}
              className={
                formik.touched.priceNote && formik.errors.priceNote
                  ? "p-invalid"
                  : ""
              }
            />
            {formik.touched.priceNote && formik.errors.priceNote ? (
              <div style={{ color: "red" }}>
                {formik.errors.priceNote.toString()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalAddPrice;
