import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { getOrder } from '../../store/order/slice';
import { addQrTeam, setDataNull,checkPassword } from '../../store/teams/slice';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import UploadFile from '../../components/UploadFile';
import { Toast } from 'primereact/toast';
import { QrReader } from 'react-qr-reader';
import '../../assets/scss/theme-base/ModalProjectDeliveryTeam.css';
import { formatDate } from '../../components/utils';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ModalProjectDeliveryTeam2 = ({ visible, onHide }) => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clearFiles, setClearFiles] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [submitType, setSubmitType] = useState('');
  const [qrModalIsOpen, setQrModalIsOpen] = useState(false);
  const [isRearCamera, setIsRearCamera] = useState(true);
  const [isFactoryCodeSelected, setIsFactoryCodeSelected] = useState(false); 
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);
  const query = useQuery();
  const name = query.get('name') || '';
  const initialFactoryCode = query.get('factoryCode') || '';
  const initialQuantity = query.get('quantity') || '';
  const toast = useRef(null);
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [teamName, setTeamName] = useState('');

  const { addQrTeamData, errorQrTeam, orderData,checkPasswordData, errorCheckPassword} = useSelector(
    (state) => ({
      addQrTeamData: state.Teams.addQrTeamData,
      errorQrTeam: state.Teams.errorQrTeam,
      orderData: state.Production.orderData,
      checkPasswordData: state.Teams.checkPasswordData,
      errorCheckPassword: state.Teams.errorCheckPassword
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isPasswordEntered) {
    dispatch(
      getOrder({
        page: 1,
        limit: orderData?.data?.products?.totalItems,
        current: 'plan',
      })
    );
    }
  }, [dispatch, isPasswordEntered, orderData?.data?.products?.totalItems]);

  const getFactoryCodeOptions = () => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return [];
    return orderData.data.products.items.map((item) => ({
      label: item.factoryCode,
      value: item.factoryCode,
    }));
  };

  const getQuantityByFactoryCode = (factoryCode) => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return '';
    const item = orderData.data.products.items.find(
      (item) => item.factoryCode === factoryCode
    );
    return item ? item.quantity : '';
  };

  const getIntendFinishDate = (factoryCode) => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return '';
    const item = orderData.data.products.items.find(
      (item) => item.factoryCode === factoryCode
    );
    return item ? item.intendFinishDate : '';
  };

  const validationSchema = Yup.object().shape({
    quantityMade: Yup.string().required('Số lượng thực hiện là bắt buộc'),
  });

  const onUpload = () => {};

  const getFiles = (files) => {
    setSelectedFiles(files);
  };

  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name,
      factoryCode: initialFactoryCode,
      quantity: initialQuantity,
      quantityMade: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        teamName: values.name,
        quantityMade: values.quantityMade,
        description: values.description,
        factoryCode: values.factoryCode,
        type: submitType,
      };
      dispatch(addQrTeam({ values: data, files: Array.from(selectedFiles) }));
    },
  });

  const handleFactoryCodeChange = (e) => {
    const selectedFactoryCode = e.value;
    formik.setFieldValue('factoryCode', selectedFactoryCode);
    const selectedQuantity = getQuantityByFactoryCode(selectedFactoryCode);
    formik.setFieldValue('quantity', selectedQuantity);
    const selectedIntendFinishDate = getIntendFinishDate(selectedFactoryCode);
    formik.setFieldValue('intendFinishDate', selectedIntendFinishDate);
    setIsFactoryCodeSelected(true); 
  };

  const openQrModal = () => setQrModalIsOpen(true);
  const closeQrModal = () => setQrModalIsOpen(false);
  const toggleCamera = () => setIsRearCamera((prev) => !prev);

  const handleScan = (data) => {
    if (data) {
      const url = new URL(data);
      const factoryCode = url.searchParams.get('factoryCode');
      if (factoryCode) {
        formik.setFieldValue('factoryCode', factoryCode);
        const selectedQuantity = getQuantityByFactoryCode(factoryCode);
        formik.setFieldValue('quantity', selectedQuantity);
        const selectedIntendFinishDate = getIntendFinishDate(factoryCode);
        formik.setFieldValue('intendFinishDate', selectedIntendFinishDate);
        setIsFactoryCodeSelected(true); 
      }
      closeQrModal();
    }
  };

  const handleError = (err) => {
    console.error(err);
    toast.current?.show({
      severity: 'error',
      summary: 'Thất bại',
      detail: 'Lỗi khi quét mã QR',
      life: 3000,
    });
  };

  const handleStartSubmit = () => {
    setSubmitType('start');
    formik.handleSubmit();
  };

  const handleEndSubmit = () => {
    setSubmitType('end');
    formik.handleSubmit();
  };

  useEffect(() => {
    if (addQrTeamData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thao tác thành công',
        life: 3000,
      });
      if (submitType === 'start') {
        formik.setFieldValue('factoryCode', ''); // Reset mã nhà máy
        setIsFactoryCodeSelected(false); // Reset trạng thái đã chọn nhà máy
      }
  
      if (submitType === 'end') {
        formik.resetForm();
        setTimeout(() => {
          onHide();
          setTeamName('');
          setPassword('');
          setIsPasswordEntered(false);
          navigate('/import');
        }, 1000);
      }
  
      dispatch(setDataNull());
    }
  }, [dispatch, addQrTeamData, submitType]);
  
  const handleClose = () => {
    setIsShow(false);
    navigate('/import');
    setTeamName('');
    setPassword('');
    setIsPasswordEntered(false);
    onHide();
  };

  useEffect(() => {
    if (errorQrTeam) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorQrTeam?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [dispatch, errorQrTeam]);


  const handlePasswordSubmit = () => {
    const data = {
      teamName: formik.values.name, 
      password: password,
    };
  
    dispatch(checkPassword(data));
  };

  useEffect(() => {
    if (checkPasswordData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Nhập mật khẩu thành công',
        life: 3000,
      });
      setIsPasswordEntered(true);
      setTeamName(checkPasswordData.data.data); 
      formik.setFieldValue('name', checkPasswordData.data.data);
      dispatch(setDataNull());
      
    }
  }, [dispatch, checkPasswordData]);

  useEffect(() => {
    if (errorCheckPassword) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorCheckPassword?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [dispatch, errorCheckPassword]);
  
  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header='Nhập sản xuất'
        visible={visible}
        className='responsive-dialog'
        style={{ width: '50vw' }}
        onHide={handleClose}>
        <div className='bg-white p-3 border-round-md'>
          <div className='flex flex-column mb-4 gap-3'>
            <div className='flex flex-column w-12 mb-3'>
              <label className='mb-2'>Mã tổ đội</label>
              <InputText
                id='name'
                name='name'
                value={formik.values.name || teamName}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                className={
                  formik.touched.name && formik.errors.name ? 'p-invalid' : ''
                }
              />
              {formik.touched.name && formik.errors.name ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.name.toString()}
                </div>
              ) : null}
            </div>
            {!isPasswordEntered && (
              <>
                <div className='flex flex-column w-12 mb-3'>
                  <label className='mb-2'>Mật khẩu</label>
                  <InputText
                    id='password'
                    name='password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ height: '40px' }}
                    className={
                      formik.touched.password && formik.errors.password ? 'p-invalid' : ''
                    }
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors.password.toString()}
                    </div>
                  ) : null}
                </div>
                <div className='flex mb-3 justify-content-center'>
                  <Button
                    label='Xác nhận'
                    onClick={handlePasswordSubmit}
                  />
                </div>
              </>
            )}
            {isPasswordEntered && (
              <>
                <div className='flex flex-column w-12 mb-3'>
                  <label className='mb-2'>Mã nhà máy</label>
                  <div className='flex gap-1'>
                    <Dropdown
                      id='factoryCode'
                      name='factoryCode'
                      value={formik.values.factoryCode}
                      options={getFactoryCodeOptions()}
                      onChange={handleFactoryCodeChange}
                      onClick={handleDropdownOpen}
                      filter
                      filterBy='label'
                      style={{
                        height: '40px',
                        width: '93%',
                        border: '1px solid #ddd',
                      }}
                      className={
                        formik.touched.factoryCode && formik.errors.factoryCode
                          ? 'p-invalid'
                          : ''
                      }
                    />
                    <Button
                      icon='pi pi-qrcode'
                      className='p-button-outlined'
                      onClick={openQrModal}
                      style={{ width: '7%', minWidth: '40px' }}
                    />
                  </div>

                  {formik.touched.factoryCode && formik.errors.factoryCode ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors.factoryCode.toString()}
                    </div>
                  ) : null}
                </div>
                {isFactoryCodeSelected && (
                  <>
                  <div className='flex flex-column w-12 mb-3'>
                      <label className='mb-2'>Kế hoạch hoàn thành</label>
                      <InputText
                        id='intendFinishDate'
                        name='intendFinishDate'
                        value={formatDate(formik.values.intendFinishDate)}
                        onChange={formik.handleChange}
                        style={{ height: '40px' }}
                        className={
                          formik.touched.intendFinishDate && formik.errors.intendFinishDate
                            ? 'p-invalid'
                            : ''
                        }
                        readOnly
                      />
                      {formik.touched.intendFinishDate && formik.errors.intendFinishDate ? (
                        <div style={{ color: 'red' }}>
                          {formik.errors.intendFinishDate.toString()}
                        </div>
                      ) : null}
                    </div>
                    <div className='flex flex-column w-12 mb-3'>
                      <label className='mb-2'>Số lượng đơn hàng</label>
                      <InputText
                        id='quantity'
                        name='quantity'
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        style={{ height: '40px' }}
                        className={
                          formik.touched.quantity && formik.errors.quantity
                            ? 'p-invalid'
                            : ''
                        }
                        readOnly
                      />
                      {formik.touched.quantity && formik.errors.quantity ? (
                        <div style={{ color: 'red' }}>
                          {formik.errors.quantity.toString()}
                        </div>
                      ) : null}
                    </div>
                    <div className='flex flex-column w-12 mb-3'>
                      <label className='mb-2'>Số lượng thực hiện</label>
                      <InputText
                        id='quantityMade'
                        name='quantityMade'
                        value={formik.values.quantityMade}
                        onChange={formik.handleChange}
                        style={{ height: '40px' }}
                        className={
                          formik.touched.quantityMade && formik.errors.quantityMade
                            ? 'p-invalid'
                            : ''
                        }
                      />
                      {formik.touched.quantityMade && formik.errors.quantityMade ? (
                        <div style={{ color: 'red' }}>
                          {formik.errors.quantityMade.toString()}
                        </div>
                      ) : null}
                    </div>
                    <div className='flex flex-column w-12 mb-3'>
                      <label className='mb-2'>Ghi chú</label>
                      <InputText
                        id='description'
                        name='description'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        style={{ height: '40px' }}
                        className={
                          formik.touched.description && formik.errors.description
                            ? 'p-invalid'
                            : ''
                        }
                      />
                      {formik.touched.description && formik.errors.description ? (
                        <div style={{ color: 'red' }}>
                          {formik.errors.description.toString()}
                        </div>
                      ) : null}
                    </div>
                    <UploadFile
                      onUpload={onUpload}
                      getFiles={getFiles}
                      clearFiles={clearFiles}
                    />
                    <div className='flex mb-3 justify-content-center gap-5'>
                      <Button
                        label='Nhập'
                        onClick={handleStartSubmit}
                      />
                      <Button
                        label='Kết thúc'
                        onClick={handleEndSubmit}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Dialog>

      <Dialog
        header='Quét mã QR'
        visible={qrModalIsOpen}
        style={{ width: '80vw' }}
        onHide={closeQrModal}>
        <QrReader
          onResult={handleScan}
          onError={handleError}
          style={{ width: '100%' }}
          constraints={{
            facingMode: isRearCamera ? 'environment' : 'user',
          }}
        />
        <div className='flex justify-content-between'>
          <Button
            label='Chuyển camera'
            onClick={toggleCamera}
          />
          <Button
            label='Đóng'
            onClick={closeQrModal}
          />
        </div>
      </Dialog>
    </>
  );
};

export default ModalProjectDeliveryTeam2;
