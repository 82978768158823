import { takeEvery, put, call } from 'redux-saga/effects';

import {
  getPermissionSuccess,
  getPermissionFail,
  getAllPermissionsSuccess,
  getAllPermissionsFail,
  addGroupPermissionSuccess,
  addGroupPermissionFail,
  updateGroupPermissionSuccess,
  updateGroupPermissionFail,
  deleteGroupPermissionSuccess,
  deleteGroupPermissionFail,
  getPermissionByGroupIdSuccess,
  getPermissionByGroupIdFail,
  setPermissionToGroupSuccess,
  setPermissionToGroupFail,
} from './slice';
import {
  getPermission,
  getAllPermissions,
  addGroupPermission,
  deleteGroupPermission,
  getPermissionByGroupId,
  updateGroupPermission,
  setPermissionToGroup,
} from '../../api/backend_helper';

function* onGetPermission({ payload: query }) {
  try {
    const response = yield call(getPermission, query);
    yield put(getPermissionSuccess(response.data));
  } catch (error) {
    yield put(getPermissionFail(error));
  }
}

function* onGetAllPermissions({ payload: query }) {
  try {
    const response = yield call(getAllPermissions, query);
    yield put(getAllPermissionsSuccess(response.data));
  } catch (error) {
    yield put(getAllPermissionsFail(error));
  }
}

function* onAddGroupPermission({ payload: data }) {
  try {
    const response = yield call(addGroupPermission, data);
    yield put(addGroupPermissionSuccess(response.data));
  } catch (error) {
    yield put(addGroupPermissionFail(error));
  }
}

function* onUpdateGroupPermission({ payload }) {
  try {
    const response = yield call(
      updateGroupPermission,
      payload.id,
      payload.data
    );
    yield put(updateGroupPermissionSuccess(response.data));
  } catch (error) {
    yield put(updateGroupPermissionFail(error));
  }
}

function* onDeleteGroupPermission({ payload: id }) {
  try {
    const response = yield call(deleteGroupPermission, id);
    yield put(deleteGroupPermissionSuccess(response.data));
  } catch (error) {
    yield put(deleteGroupPermissionFail(error));
  }
}

function* onGetPermissionByGroupId({ payload: query }) {
  try {
    const response = yield call(getPermissionByGroupId, query);
    yield put(getPermissionByGroupIdSuccess(response.data));
  } catch (error) {
    yield put(getPermissionByGroupIdFail(error));
  }
}

function* onSetPermissionToGroup({ payload: data }) {
  try {
    const response = yield call(setPermissionToGroup, data);
    yield put(setPermissionToGroupSuccess(response.data));
  } catch (error) {
    yield put(setPermissionToGroupFail(error));
  }
}

function* PermissionSaga() {
  yield takeEvery('permission/getPermission', onGetPermission);
  yield takeEvery('permission/getAllPermissions', onGetAllPermissions);
  yield takeEvery('permission/addGroupPermission', onAddGroupPermission);
  yield takeEvery('permission/updateGroupPermission', onUpdateGroupPermission);
  yield takeEvery('permission/deleteGroupPermission', onDeleteGroupPermission);
  yield takeEvery(
    'permission/getPermissionByGroupId',
    onGetPermissionByGroupId
  );
  yield takeEvery('permission/setPermissionToGroup', onSetPermissionToGroup);
}

export default PermissionSaga;
