import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categoryData: null,
    categoryDataByID: null,
    error: null,
    loading: false,
    insertData: null,
    updateData: null,
    deleteData: null,
    errorInsert: null,
    errorUpdate: null,
    errorDelete: null,
};

const categorySlice = createSlice({
  name : 'category',
  initialState,
  reducers: {
    getDataAll: (state) => {
      state.loading = true;
    },
    getDataAllSuccess: (state, action) => {
      state.loading = false;
      state.categoryData = action.payload;
      state.error = null;
    },
    getDataAllFail: (state, action) => {
      state.loading = false;
      state.categoryData = null;
      state.error = action.payload;
    },
    addCategory: (state) => {
      state.loading = true;
      state.insertData= null;
      state.errorInsert = null;
    },
    addCategorySuccess: (state, action) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addCategoryFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.insertData = null;
    },
    updateCategory: (state) => {
      state.loading = true;
    },
    updateCategorySuccess: (state, action) => {
      state.loading = false;
      const index = state.categoryData.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.categoryData[index] = action.payload;
      }
      state.error = null;
    },
    updateCategoryFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCategory: (state) => {
      state.loading = true;
    },
    deleteCategorySuccess: (state, action) => {
      state.loading = false;
      state.categoryData = state.categoryData.filter(item => item.id !== action.payload);
      state.error = null;
    },
    deleteCategoryFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;

    },
  },
});

export const {
  getDataAll,
  getDataAllSuccess,
  getDataAllFail,
  addCategory,
  addCategorySuccess,
  addCategoryFail,
  updateCategory,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategory,
  deleteCategorySuccess,
  deleteCategoryFail,
  setDataNull,
} = categorySlice.actions;

export default categorySlice.reducer;