import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  updateConstruction,
  setDataNull,
} from '../../store/construction/slice';
import '../../assets/scss/theme-base/ModelAddConstruction.css';
import { InputTextarea } from 'primereact/inputtextarea';

const ModelEditConstruction = ({ visible, onHide, rowData, toast }) => {
  const dispatch = useDispatch();

  const { errorUpdate, updateData } = useSelector(
    (state) => ({
      errorUpdate: state.Construction.errorUpdate,
      updateData: state.Construction.updateData,
    }),
    shallowEqual
  );

  const constructionSchema = Yup.object().shape({
    buildingCode: Yup.string().required('This value is required'),
    name: Yup.string().required('This value is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      buildingCode: rowData?.buildingCode || '',
      name: rowData?.name || '',
      description: rowData?.description || '',
    },
    validationSchema: constructionSchema,
    onSubmit: async (values) => {
      dispatch(updateConstruction({ id: rowData.id, data: values }));
    },
  });

  useEffect(() => {
    if (errorUpdate) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorUpdate?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (updateData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Cập nhật thành công',
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdate, updateData]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      className='responsive-dialog'
      header='Sửa công trình'
      modal
      style={{ width: '450px', minHeigh: '55%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            className='p-button-text'
          />
          <Button
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <div className='bg-white p-3 border-round-md'>
        <div className='flex flex-column mb-4 gap-3'>
          <div className='flex flex-column w-12'>
            <label className='mb-2'>Mã công trình</label>
            <InputText
              id='buildingCode'
              {...formik.getFieldProps('buildingCode')}
              style={{ height: '40px' }}
              className={
                formik.touched.buildingCode && formik.errors.buildingCode
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.buildingCode && formik.errors.buildingCode && (
              <div style={{ color: 'red' }}>{formik.errors.buildingCode}</div>
            )}
          </div>
          <div className='flex flex-column w-12 pt-4'>
            <label className='mb-2'>Tên công trình</label>
            <InputText
              id='name'
              {...formik.getFieldProps('name')}
              style={{ height: '40px' }}
              className={
                formik.touched.name && formik.errors.name ? 'p-invalid' : ''
              }
            />
            {formik.touched.name && formik.errors.name && (
              <div style={{ color: 'red' }}>{formik.errors.name}</div>
            )}
          </div>
          <div className='flex flex-column w-12 py-4'>
            <label className='mb-2'>Ghi chú</label>
            <InputTextarea
              id='description'
              name='description'
              value={formik.values.description}
              onChange={formik.handleChange}
              autoResize
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModelEditConstruction;
