import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamsData: null,
  teamsDataByID: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
  addQrTeamData: null,
  errorQrTeam: null,
  addQrNoteData: null,
  errorQrNote: null,
  checkPasswordData: null,
  errorCheckPassword: null,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    getDataAll: (state) => {
      state.loading = true;
    },
    getDataAllSuccess: (state, action) => {
      state.loading = false;
      state.teamsData = action.payload;
      state.error = null;
    },
    getDataAllFail: (state, action) => {
      state.loading = false;
      state.teamsData = null;
      state.error = action.payload;
    },
    addTeams: (state) => {
      state.loadingInsert = true;
      state.insertData = null;
      state.errorInsert = null;
    },
    addTeamsSuccess: (state, action) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addTeamsFail: (state, action) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateTeams: (state) => {
      state.loading = true;
      state.updateData = null;
      state.errorUpdate = null;
    },
    updateTeamsSuccess: (state, action) => {
      state.loading = false;
      state.updateData = action.payload;
      state.errorUpdate = null;
    },
    updateTeamsFail: (state, action) => {
      state.loading = false;
      state.errorUpdate = action.payload;
      state.updateData = null;
    },
    deleteTeams: (state) => {
      state.loading = true;
    },
    deleteTeamsSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteTeamsFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    addQrTeam: (state) => {
      state.loading = true;
      state.addQrTeamData = null;
      state.errorQrTeam = null;
    },
    addQrTeamSuccess: (state, action) => {
      state.loading = false;
      state.addQrTeamData = action.payload;
      state.errorQrTeam = null;
    },
    addQrTeamFail: (state, action) => {
      state.loading = false;
      state.addQrTeamData = null;
      state.errorQrTeam = action.payload;
    },
    addQrNote: (state) => {
      state.loading = true;
      state.addQrNoteData = null;
      state.errorQrNote = null;
    },
    addQrNoteSuccess: (state, action) => {
      state.loading = false;
      state.addQrNoteData = action.payload;
      state.errorQrNote = null;
    },
    addQrNoteFail: (state, action) => {
      state.loading = false;
      state.addQrNoteData = null;
      state.errorQrNote = action.payload;
    },
    checkPassword: (state) => {
      state.loading = true;
    },
    checkPasswordSuccess: (state, action) => {
      state.loading = false;
      state.checkPasswordData = action.payload;
      state.errorCheckPassword = null;
    },
    checkPasswordFail: (state, action) => {
      state.loading = false;
      state.checkPasswordData = null;
      state.errorCheckPassword = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
      state.addQrTeamData = null;
      state.errorQrTeam = null;
      state.addQrNoteData = null;
      state.errorQrNote = null;
      state.checkPasswordData = null;
      state.errorCheckPassword = null;
    },
    setDataNull2: (state) => {
      state.addQrTeamData = null;
      state.errorQrTeam = null;
    },
  },
});

export const {
  setDataNull2,
  getDataAll,
  getDataAllSuccess,
  getDataAllFail,
  addTeams,
  addTeamsSuccess,
  addTeamsFail,
  updateTeams,
  updateTeamsSuccess,
  updateTeamsFail,
  deleteTeams,
  deleteTeamsSuccess,
  deleteTeamsFail,
  setDataNull,
  addQrTeam,
  addQrTeamSuccess,
  addQrTeamFail,
  addQrNote,
  addQrNoteSuccess,
  addQrNoteFail,
  checkPassword,
  checkPasswordSuccess,
  checkPasswordFail,
} = teamsSlice.actions;

export default teamsSlice.reducer;
