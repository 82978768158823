import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../assets/scss/theme-base/ModalAddPlan.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getPlan, setDataNull2 } from '../../store/order/slice';
import { formatNumber } from '../../components/utils';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function ActualComplete({ isShow, hiden, detail, productId, price }) {
  const dispatch = useDispatch();
  const planData = useSelector(
    (state) => state.Production.planData,
    shallowEqual
  );

  useEffect(() => {
    if (isShow && productId) {
      dispatch(
        getPlan({ page: 1, limit: 100, productId, type: 'actual-delivery' })
      );
    }
  }, [dispatch, isShow, productId]);

  const handleHide = () => {
    hiden();
    dispatch(setDataNull2());
  };

  return (
    <Dialog
      header='Sản lượng thực tế'
      visible={isShow}
      className='responsive-dialog mx-3'
      style={{ minWidth: '450px' }}
      onHide={handleHide}>
      <DataTable
        emptyMessage='Không có dữ liệu'
        value={planData?.data?.items}>
        <Column
          field='time'
          header='Ngày'
          style={{ height: '40px', minWidth: '100px' }}
          body={(rowData) => formatDate(rowData.time)}></Column>
        <Column
          field='actualDelivery'
          style={{ height: '40px', minWidth: '100px' }}
          header='SL thực tế'></Column>
        <Column
          field='numDelivery'
          header='Giá trị'
          style={{ height: '40px', minWidth: '100px' }}
          body={(rowData) => formatNumber(rowData?.numDelivery)}></Column>
      </DataTable>
    </Dialog>
  );
}

export default ActualComplete;
