import Header from "./Header.js";

function PublicLayout({ children }) {
    return (
        <div className="h-screen w-screen surface-100">
            <div>
                <Header />
            </div>
            <div className="flex flex-row justify-content-center mt-5">
                {children}
            </div>
        </div>
    );
}

export default PublicLayout;
