import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardQuantityData: null,
    error: null,
    loading: false,
};

const dashboardQuantitySlice = createSlice({
    name: "dashboardQuantity",
    initialState,
    reducers: {
        getDashboardQuantity: (state) => {
            state.loading = true;
        },
        getDashboardQuantitySuccess: (state, action) => {
            state.loading = false;
            state.dashboardQuantityData = action.payload;
            state.error = null;
        },
        getDashboardQuantityFail: (state, action) => {
            state.loading = false;
            state.dashboardQuantityData = null;
            state.error = action.payload;
        },
    },
});

export const {
    getDashboardQuantity,
    getDashboardQuantityFail,
    getDashboardQuantitySuccess,
} = dashboardQuantitySlice.actions;

export default dashboardQuantitySlice.reducer;
