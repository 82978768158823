import { Dialog } from "primereact/dialog";
import { site } from "../../api/url_helper";
import "primeicons/primeicons.css";
import { getTeamChildren } from "../../store/order/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { formatDate2 } from "../../components/utils";
import { Image } from "primereact/image";
import "../../assets/scss/theme-base/Intersection.css";

const ModalDv = ({ visible, onHide, name, factoryCode }) => {
    const dispatch = useDispatch();
    const { teamChildrenData, data } = useSelector(
        (state) => ({
            teamChildrenData: state.Production.teamChildrenData,
            data: state.Production.teamChildrenData?.data?.items,
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(getTeamChildren({ team: name, factoryCode: factoryCode }));
    }, [dispatch, name, factoryCode]);

    const pairs = data || [];

    return (
        <Dialog
            visible={visible}
            onHide={() => {
                onHide();
            }}
            className="p-fluid"
            header="Chi tiết sản xuất"
            style={{ width: '30vw' }}
        >
            <div>
                {pairs.map((pair, index) => (
                    <div
                        key={index}
                        className="mb-5 pb-2 flex justify-content-between"
                        style={{ borderBottom: "1px solid #d8d8d8" }}
                    >
                        <div className="w-50 pr-2">
                            <div>
                                <div className="mb-1">{pair.start && formatDate2(pair.start.time)}</div>
                                <div className="mb-1">Số lượng: {pair.start && pair.start.quantityMade}</div>
                                <div className="mb-1">{pair.start && pair.start.note}</div>
                                <div className="flex gap-3 overflow-x-auto" style={{ maxWidth: '160px', minHeight: '40px' }}>
                                    {pair.start && pair.start.images.map((img, imgIndex) => (
                                        <Image
                                            key={imgIndex}
                                            src={`${img}`}
                                            alt={`start-img-${imgIndex}`}
                                            height="auto"
                                            width="40px"
                                            style={{ flex: '0 0 auto' }}
                                            preview
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="w-10 flex justify-content-center">
                            <i className="pi pi-arrow-right" style={{color:'green'}}></i>
                        </div>
                        <div className="w-50 pl-2">
                            {pair.end && (
                                <div>
                                    <div className="mb-1">{pair.end && formatDate2(pair.end.time)}</div>
                                    <div className="mb-1">Số lượng: {pair.end && pair.end.quantityMade}</div>
                                    <div className="mb-1">{pair.end && pair.end.note}</div>
                                    <div className="flex gap-3 overflow-x-auto" style={{ maxWidth: '160px', minHeight: '40px' }}>
                                        {pair.end && pair.end.images.map((img, imgIndex) => (
                                            <Image
                                                key={imgIndex}
                                                src={`${img}`}
                                                alt={`end-img-${imgIndex}`}
                                                height="auto"
                                                width="40px"
                                                style={{ flex: '0 0 auto' }}
                                                preview
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Dialog>
    );
};

export default ModalDv;
