import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LoadingSpinner from "../Report/LoadingSpinner";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import Intersection from "../Plan/intersection";
import { Paginator } from "primereact/paginator";

const Progress = ({ dashboardTeamsWorking, onPaginationChange }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };

    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
        if (onPaginationChange) {
            onPaginationChange(newFirst, newRows);
        }
    };

    return (
        <div className="p-3 bg-white border-round-lg h-full">
            <div>
                <p className="font-bold text-500 mb-3 mt-3">Đang thực hiện</p>
            </div>
            {dashboardTeamsWorking && dashboardTeamsWorking.data ? (
                <div>
                    <DataTable
                        value={dashboardTeamsWorking?.data?.items}
                        // paginator
                        // rows={10}
                        // rowsPerPageOptions={[10, 25, 50, 100, 500]}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowClassName="custom-row-class-factory"
                        emptyMessage="Không có dữ liệu"
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        scrollable
                        scrollHeight="50vh"
                    >
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Tổ đội"
                            field="name"
                            style={{ minWidth: "100px" }}
                            filter
                        />
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ minWidth: "150px" }}
                            filter
                        />
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={dashboardTeamsWorking?.data?.totalItems}
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    <Intersection
                        visible={dialogLocationVisible}
                        onHide={() => setDialogLocationVisible(false)}
                    />
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default Progress;
