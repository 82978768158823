// AddLocationPopup.js
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { QrReader } from 'react-qr-reader';
import UploadFile from '../../components/UploadFile';
import { Dropdown } from 'primereact/dropdown';
import { addQrTeam, getDataAll, setDataNull2 } from '../../store/teams/slice';

const AddLocationPopup = ({ visible, onHide, factoryCode }) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [submitType, setSubmitType] = useState('');
  const [qrModalIsOpen, setQrModalIsOpen] = useState(false);
  const [isRearCamera, setIsRearCamera] = useState(true);
  const [clearFiles, setClearFiles] = useState(false);

  const { addQrTeamData, errorQrTeam, orderData, teamsData } = useSelector(
    (state) => ({
      addQrTeamData: state.Teams.addQrTeamData,
      errorQrTeam: state.Teams.errorQrTeam,
      orderData: state.Production.orderData,
      teamsData: state.Teams.teamsData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isDropdownOpen2) {
      dispatch(
        getDataAll({
          page: 1,
          limit: 100,
        })
      );
    }
  }, [dispatch, isDropdownOpen2]);

  const getTeamOptions = () => {
    if (!teamsData || !teamsData.data || !teamsData.data.items) return [];
    return teamsData.data.items.map((team) => ({
      label: team.name,
      value: team.name,
    }));
  };

  const validationSchema = Yup.object().shape({
    quantityMade: Yup.string().required('Số lượng thực hiện là bắt buộc'),
  });

  const onUpload = () => {};

  const getFiles = (files) => {
    setSelectedFiles(files);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      factoryCode: factoryCode || '',
      quantityMade: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        teamName: values.name,
        quantityMade: values.quantityMade,
        description: values.description,
        factoryCode: values.factoryCode,
        type: submitType,
      };
      dispatch(addQrTeam({ values: data, files: Array.from(selectedFiles) }));
    },
  });

  const getFactoryCodeOptions = () => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return [];
    return orderData.data.products.items.map((item) => ({
      label: item.factoryCode,
      value: item.factoryCode,
    }));
  };

  const handleScan = (data) => {
    if (data) {
      const url = new URL(data);
      const factoryCode = url.searchParams.get('factoryCode');
      if (factoryCode) {
        formik.setFieldValue('factoryCode', factoryCode);
        const selectedQuantity = getQuantityByFactoryCode(factoryCode);
        formik.setFieldValue('quantity', selectedQuantity);
      }
      closeQrModal();
    }
  };

  const handleError = (err) => {
    console.error(err);
    toast.current?.show({
      severity: 'error',
      summary: 'Thất bại',
      detail: 'Lỗi khi quét mã QR',
      life: 3000,
    });
  };

  const getQuantityByFactoryCode = (factoryCode) => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return '';
    const item = orderData.data.products.items.find(
      (item) => item.factoryCode === factoryCode
    );
    return item ? item.quantity : '';
  };

  const handleFactoryCodeChange = (e) => {
    const selectedFactoryCode = e.value;
    formik.setFieldValue('factoryCode', selectedFactoryCode);
    const selectedQuantity = getQuantityByFactoryCode(selectedFactoryCode);
    formik.setFieldValue('quantity', selectedQuantity);
  };

  const handleDropdownOpen2 = () => {
    setIsDropdownOpen2(true);
  };

  const handleStartSubmit = () => {
    setSubmitType('start');
    formik.handleSubmit();
  };

  const handleEndSubmit = () => {
    setSubmitType('end');
    formik.handleSubmit();
  };

  // const openQrModal = () => setQrModalIsOpen(true);
  const closeQrModal = () => setQrModalIsOpen(false);
  const toggleCamera = () => setIsRearCamera((prev) => !prev);

  useEffect(() => {
    if (addQrTeamData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thao tác thành công',
        life: 3000,
      });
      formik.resetForm();
      setTimeout(() => {
        onHide();
        dispatch(setDataNull2());
      }, 2000);
    }
  }, [dispatch, addQrTeamData]);

  useEffect(() => {
    if (errorQrTeam) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorQrTeam?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull2());
    }
  }, [dispatch, errorQrTeam]);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header='Nhập sản xuất'
        visible={visible}
        className='responsive-dialog'
        style={{ width: '50vw' }}
        onHide={() => {
          formik.resetForm();
          onHide();
          dispatch(setDataNull2());
        }}>
        <div className='bg-white p-3 border-round-md'>
          <div className='flex flex-column mb-4 gap-3'>
            <div className='flex flex-column w-12 mb-3'>
              <label className='mb-2'>Tên tổ đội</label>
              <Dropdown
                id='name'
                name='name'
                filter
                value={formik.values.name}
                options={getTeamOptions()}
                onClick={handleDropdownOpen2}
                onChange={(e) => formik.setFieldValue('name', e.value)}
                style={{ height: '40px', border: '1px solid #ddd' }}
                className={
                  formik.touched.name && formik.errors.name ? 'p-invalid' : ''
                }
              />
              {formik.touched.name && formik.errors.name ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.name.toString()}
                </div>
              ) : null}
            </div>
            <>
              <div className='flex flex-column w-12 mb-3'>
                <label className='mb-2'>Mã nhà máy</label>
                <div className='flex gap-1'>
                  <InputText
                    id='factoryCode'
                    name='factoryCode'
                    value={formik.values.factoryCode}
                    options={getFactoryCodeOptions()}
                    onChange={handleFactoryCodeChange}
                    style={{
                      height: '40px',
                      width: '100%',
                      border: '1px solid #ddd',
                    }}
                    readOnly
                    className={
                      formik.touched.factoryCode && formik.errors.factoryCode
                        ? 'p-invalid'
                        : ''
                    }
                  />
                </div>

                {formik.touched.factoryCode && formik.errors.factoryCode ? (
                  <div style={{ color: 'red' }}>
                    {formik.errors.factoryCode.toString()}
                  </div>
                ) : null}
              </div>
              <>
                <div className='flex flex-column w-12 mb-3'>
                  <label className='mb-2'>Số lượng đơn hàng</label>
                  <InputText
                    id='quantity'
                    name='quantity'
                    value={getQuantityByFactoryCode(formik.values.factoryCode)}
                    onChange={formik.handleChange}
                    style={{ height: '40px' }}
                    className={
                      formik.touched.quantity && formik.errors.quantity
                        ? 'p-invalid'
                        : ''
                    }
                    readOnly
                  />
                  {formik.touched.quantity && formik.errors.quantity ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors.quantity.toString()}
                    </div>
                  ) : null}
                </div>
                <div className='flex flex-column w-12 mb-3'>
                  <label className='mb-2'>Số lượng thực hiện</label>
                  <InputText
                    id='quantityMade'
                    name='quantityMade'
                    value={formik.values.quantityMade}
                    onChange={formik.handleChange}
                    style={{ height: '40px' }}
                    className={
                      formik.touched.quantityMade && formik.errors.quantityMade
                        ? 'p-invalid'
                        : ''
                    }
                  />
                  {formik.touched.quantityMade && formik.errors.quantityMade ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors.quantityMade.toString()}
                    </div>
                  ) : null}
                </div>
                <div className='flex flex-column w-12 mb-3'>
                  <label className='mb-2'>Ghi chú</label>
                  <InputText
                    id='description'
                    name='description'
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    style={{ height: '40px' }}
                    className={
                      formik.touched.description && formik.errors.description
                        ? 'p-invalid'
                        : ''
                    }
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors.description.toString()}
                    </div>
                  ) : null}
                </div>
                <UploadFile
                  onUpload={onUpload}
                  getFiles={getFiles}
                  clearFiles={clearFiles}
                />
                <div className='flex mb-3 justify-content-center gap-5'>
                  <Button
                    label='Nhập'
                    onClick={handleStartSubmit}
                    type='button'
                  />
                  <Button
                    label='Kết thúc'
                    onClick={handleEndSubmit}
                    type='button'
                  />
                </div>
              </>
            </>
          </div>
        </div>
      </Dialog>

      <Dialog
        header='Quét mã QR'
        visible={qrModalIsOpen}
        style={{ width: '80vw' }}
        onHide={closeQrModal}>
        <QrReader
          onResult={handleScan}
          onError={handleError}
          style={{ width: '100%' }}
          constraints={{
            facingMode: isRearCamera ? 'environment' : 'user',
          }}
        />
        <div className='flex justify-content-between'>
          <Button
            label='Chuyển camera'
            onClick={toggleCamera}
          />
          <Button
            label='Đóng'
            onClick={closeQrModal}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddLocationPopup;
