import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { getBuilding } from "../../store/order/slice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "../../assets/scss/theme-base/dashboard.css";
import { SplitButton } from "primereact/splitbutton";
import { DataContext } from "../../contexts/data/DataProvider";

const HeaderReport = ({
    building,
    orderCode,
    category,
    factoryCode,
    setBuilding,
    setOrderCode,
    setCategory,
    setFactoryCode,
    setStartDateOutput,
    setEndDateOutput,
    exportClick,
    printReport,
    exportExcel,
    exportImage,
    exportQuality,
    type,
}) => {
    const { setBuildingName } = useContext(DataContext);
    const dispatch = useDispatch();
    //lấy tuần tháng năm hiện tại
    const today = new Date();

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Lấy năm hiện tại
    const currentYear = new Date().getFullYear();

    // Lấy tháng hiện tại (tháng trong JavaScript bắt đầu từ 0, nên cần cộng thêm 1)
    const currentMonth = today.getMonth() + 1;

    const daysSinceFirstDay = Math.floor(
        (today - firstDayOfMonth) / (1000 * 60 * 60 * 24)
    );

    //lấy tuần hiện tại
    const currentWeek = Math.floor(daysSinceFirstDay / 7) + 1;

    // const [selectedMonth, setSelectedMonth] = useState(String(currentMonth));
    // const [selectedYear, setSelectedYear] = useState(currentYear);
    // const [selectedWeek, setSelectedWeek] = useState(currentWeek);
    const [startDate, setStartDate] = useState(new Date("2024-08-01"));
    const [endDate, setEndDate] = useState(new Date());
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [buildingOptions, setBuildingOptions] = useState([]);

    const dataBuilding = useSelector(
        (state) => state.Production.dataBuilding,
        shallowEqual
    );

    function getNameByBuildingCode(buildingCode) {
        const result = dataBuilding?.data?.items.find(
            (item) => item.buildingCode === buildingCode
        );
        return result ? result.name : "";
    }

    useEffect(() => {
        setBuildingName(getNameByBuildingCode(building));
    }, [building]);

    const limit = dataBuilding?.data?.totalItems;

    useEffect(() => {
        dispatch(
            getBuilding({
                page: (first + rows) / rows,
                limit: limit > 10 ? limit : 10,
            })
        );
    }, [dispatch, first, rows]);

    useEffect(() => {
        const buildings = dataBuilding?.data?.items.map((item) => ({
            label: item.name,
            value: item.buildingCode,
        }));
        if (buildings && buildings.length > 0) {
            setBuildingOptions(buildings);
        }
    }, [dataBuilding]);

    // Tạo danh sách các năm từ currentYear - 10 đến currentYear + 10
    // const years = Array.from(
    //     { length: 21 },
    //     (_, i) => currentYear - 10 + i
    // ).map((year) => ({ name: year.toString(), value: year }));

    // const months = [
    //     { name: "Tháng 1", value: "1" },
    //     { name: "Tháng 2", value: "2" },
    //     { name: "Tháng 3", value: "3" },
    //     { name: "Tháng 4", value: "4" },
    //     { name: "Tháng 5", value: "5" },
    //     { name: "Tháng 6", value: "6" },
    //     { name: "Tháng 7", value: "7" },
    //     { name: "Tháng 8", value: "8" },
    //     { name: "Tháng 9", value: "9" },
    //     { name: "Tháng 10", value: "10" },
    //     { name: "Tháng 11", value: "11" },
    //     { name: "Tháng 12", value: "12" },
    // ];
    // const weeks = Array.from({ length: 5 }, (_, i) => ({
    //     name: `Tuần ${i + 1}`,
    //     value: i + 1,
    // }));

    // Định dạng ngày theo định dạng YYYY-MM-DD
    const formatDate = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    // Hàm tính ngày đầu tiên và ngày cuối cùng của tuần
    // const getDatesFromWeekMonthYear = (year, month, week) => {
    //     if (year && month && week) {
    //         // Tính ngày cuối cùng của tháng
    //         const lastDayOfMonth = new Date(year, month, 0);

    //         // Tính ngày đầu tiên và ngày cuối cùng của tuần trong tháng
    //         let startOfWeek, endOfWeek;

    //         if (week === 1) {
    //             // Tuần 1: từ ngày 1 đến ngày 7
    //             startOfWeek = new Date(year, month - 1, 1);
    //             endOfWeek = new Date(year, month - 1, 7);
    //         } else {
    //             // Các tuần tiếp theo
    //             startOfWeek = new Date(year, month - 1, (week - 1) * 7 + 1);
    //             endOfWeek = new Date(year, month - 1, week * 7);

    //             // Điều chỉnh ngày kết thúc tuần không vượt quá ngày cuối cùng của tháng
    //             if (endOfWeek > lastDayOfMonth) {
    //                 endOfWeek = new Date(
    //                     year,
    //                     month - 1,
    //                     lastDayOfMonth.getDate()
    //                 );
    //             }
    //         }

    //         // Nếu ngày bắt đầu tuần vượt quá ngày cuối cùng của tháng, trả về không có dữ liệu
    //         if (startOfWeek > lastDayOfMonth) {
    //             return { start: "", end: "" };
    //         }

    //         return {
    //             start: startOfWeek,
    //             end: endOfWeek,
    //         };
    //     }
    //     return { start: "", end: "" };
    // };
    // useEffect(() => {
    //     const { start, end } = getDatesFromWeekMonthYear(
    //         selectedYear,
    //         selectedMonth,
    //         selectedWeek
    //     );
    //     setStartDate(start);
    //     setEndDate(end);
    //     setStartDateOutput(formatDate(start));
    //     setEndDateOutput(formatDate(end));
    // }, [selectedYear, selectedMonth, selectedWeek]);

    // const { start, end } = getDatesFromWeekMonthYear(
    //     selectedYear,
    //     selectedMonth,
    //     selectedWeek
    // );

    let importItems = [
        {
            label: "Xuất PDF",
            icon: "pi pi-file-pdf",
            command: () => {
                exportClick();
                setBuildingName("");
            },
        },
        {
            label: "Xuất Excel",
            icon: "pi pi-file-excel",
            command: exportExcel,
        },
        {
            label: "Xuất hình ảnh sản xuất",
            icon: "pi pi-image",
            command: exportImage,
        },
        {
            label: "Xuất báo cáo chất lượng",
            icon: "pi pi-file-export",
            command: exportQuality,
        },
    ];
    if (type !== "construction") {
        importItems = importItems.filter(
            (item) =>
                item.label !== "Xuất hình ảnh sản xuất" &&
                item.label !== "Xuất báo cáo chất lượng"
        );
    }
    // if (type === "quantity") {
    //     importItems = importItems.filter(
    //         (item) => item.label === "Xuất PDF" || item.label === "Xuất Excel"
    //     );
    // } else if (type === "construction") {
    //     importItems = importItems.filter((item) => item.label !== "Xuất Excel");
    // } else {
    //     importItems = importItems.filter((item) => item.label === "Xuất PDF");
    // }
    function formatDateWithOffset(dateStr, offsetDays = 0) {
        // Chuyển đổi chuỗi thành đối tượng Date
        const date = new Date(dateStr);

        // Thêm offset ngày
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + offsetDays);

        // Định dạng ngày
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
        const day = String(newDate.getDate()).padStart(2, "0");

        return `${year}/${month}/${day}`;
    }
    // xóa bộ lọc
    const onClickDeleteFilter = () => {
        // setSelectedMonth(String(currentMonth));
        // setSelectedYear(currentYear);
        // setSelectedWeek(currentWeek);
        // setStartDateOutput(start);
        // setEndDateOutput(end);
        setStartDate(new Date("2024-08-01"));
        setEndDate(new Date());
        setBuilding(null);
        setCategory("");
        setFactoryCode("");
        setOrderCode("");
    };

    useEffect(() => {
        setStartDateOutput(formatDateWithOffset(startDate));
        setEndDateOutput(formatDateWithOffset(endDate));
    }, [startDate, endDate]);

    return (
        <div>
            <div className="flex sm:justify-content-between flex-wrap align-items-start justify-content-center">
                <div className="flex gap-3 max-w-max mb-3 flex-column md:flex-row sm:ml-0">
                    {/* <div className="flex gap-3 max-w-max md:mb-0 flex-1 w-full">
                        <Dropdown
                            value={selectedYear}
                            options={years}
                            onChange={(e) => setSelectedYear(e.value)}
                            optionLabel="name"
                            placeholder="Năm"
                            className="flex align-items-center justify-content-center border-0"
                            // style={{ width: "100px" }}
                        />
                        <Dropdown
                            value={selectedMonth}
                            options={months}
                            onChange={(e) => setSelectedMonth(e.value)}
                            optionLabel="name"
                            placeholder="Tháng"
                            className="flex align-items-center justify-content-center border-0"
                            // style={{ width: "120px" }}
                        />
                        <Dropdown
                            value={selectedWeek}
                            options={weeks}
                            onChange={(e) => setSelectedWeek(e.value)}
                            optionLabel="name"
                            placeholder="Tuần"
                            className="flex align-items-center justify-content-center border-0"
                            style={{ width: "117px" }}
                        />
                    </div> */}
                    <div className="flex gap-3 max-w-max h-2rem w-12 md:h-auto">
                        <div className="calendar-container">
                            <label htmlFor="startDate">Từ ngày:</label>
                            <Calendar
                                locale="vi"
                                id="startDate"
                                value={startDate}
                                onChange={(e) => {
                                    setStartDate(e.target.value);
                                    setStartDateOutput(
                                        formatDate(e.target.value)
                                    );
                                }}
                                dateFormat="dd/mm/yy"
                                className="calendar-custom"
                                style={{ height: "35px" }}
                            />
                        </div>
                        <div className="calendar-container">
                            <label htmlFor="endtDate">Đến ngày:</label>
                            <Calendar
                                locale="vi"
                                id="endDate"
                                value={endDate}
                                onChange={(e) => {
                                    setEndDate(e.target.value);
                                    setEndDateOutput(
                                        formatDate(e.target.value)
                                    );
                                }}
                                dateFormat="dd/mm/yy"
                                className="calendar-custom"
                                minDate={startDate}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0 btn-container">
                    <Button
                        label="Xóa bộ lọc"
                        icon="pi pi-filter-slash"
                        onClick={onClickDeleteFilter}
                        severity="secondary"
                        size="small"
                    ></Button>
                    <SplitButton
                        // className="bg-blue-500"
                        model={importItems}
                        icon="pi pi-print"
                        label="In báo cáo"
                        size="small"
                        severity="danger"
                        outlined
                        onClick={(e) => {
                            printReport();
                            setBuildingName("");
                        }}
                    ></SplitButton>
                </div>
            </div>
            <div className="flex gap-3 w-full md:flex-row flex-column">
                <div className="flex gap-3 w-full">
                    <Dropdown
                        placeholder="Lọc công trình"
                        options={buildingOptions}
                        onChange={(e) => setBuilding(e.target.value)}
                        value={building}
                        filter
                        showClear
                        style={{ height: "32px" }}
                        className="py-0 flex align-items-center border-0 w-6 md:w-6 md:max-w-sm"
                        emptyFilterMessage="Chưa có kết quả phù hợp"
                        emptyMessage="Chưa có công trình"
                    />
                    <InputText
                        type="text"
                        value={orderCode}
                        className="p-inputtext-sm text-base px-3 border-0 w-6 md:w-6 md:max-w-sm"
                        placeholder="Mã đơn hàng"
                        onChange={(e) => setOrderCode(e.target.value)}
                    />
                </div>
                <div className="flex gap-3 w-full">
                    <InputText
                        type="text"
                        value={category}
                        className="p-inputtext-sm text-base px-3 border-0 w-6 md:w-6 md:max-w-sm"
                        placeholder="Lọc hạng mục"
                        onChange={(e) => setCategory(e.target.value)}
                    />
                    <InputText
                        type="text"
                        value={factoryCode}
                        className="p-inputtext-sm text-base px-3 border-0 w-6 md:w-6 md:max-w-sm"
                        placeholder="Lọc mã nhà máy"
                        onChange={(e) => setFactoryCode(e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default HeaderReport;
