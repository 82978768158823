import React from "react";
import "./ReportTechnical.scss";
import ProgressBarCustom from "./ProgressBarCustom";
import "../../assets/scss/theme-base/dashboard.css";

export default function HeaderReportTechnical({ dashboardTechnicalData }) {
    const dataTechnical = dashboardTechnicalData?.data;

    return (
        <div className="flex md:flex-row flex-column mt-3 gap-3">
            <div className="bg-white border-round-md shadow-1 p-3 col pl-5">
                <p>Tổng số hạng mục</p>
                <p className="mt-3 font-bold text-2xl">
                    {dataTechnical?.countTotalCategories}
                </p>
            </div>
            <div className="bg-white border-round-md shadow-1 p-3 col pl-5">
                <p>Tổng số lượng đặt vật tư</p>
                <div className="flex mt-3 align-items-center">
                    <p className="font-bold text-2xl mr-5">
                        {dataTechnical?.countProductsWithMaterialOrdering}
                    </p>
                    <div className="w-full">
                        <ProgressBarCustom
                            value={
                                dataTechnical?.countProductsWithMaterialOrdering
                            }
                            total={dataTechnical?.countTotalCategories}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-white border-round-md shadow-1 p-3 col pl-5">
                <p>Tổng số lượng đặt gia công</p>
                <div className="flex mt-3 align-items-center">
                    <p className="font-bold text-2xl mr-5">
                        {dataTechnical?.countProductsWithMachiningOrdering}
                    </p>
                    <div className="w-full">
                        <ProgressBarCustom
                            value={
                                dataTechnical?.countProductsWithMachiningOrdering
                            }
                            total={dataTechnical?.countTotalCategories}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-white border-round-md shadow-1 p-3 col pl-5">
                <p>Tổng số lượng ra BOM SX</p>
                <div className="flex mt-3 align-items-center">
                    <p className="font-bold text-2xl mr-5">
                        {dataTechnical?.countProductsWithBomProduction}
                    </p>
                    <div className="w-full">
                        <ProgressBarCustom
                            value={
                                dataTechnical?.countProductsWithBomProduction
                            }
                            total={dataTechnical?.countTotalCategories}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
