import React, { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updateProduction, setDataNull } from '../../store/order/slice';
import { Checkbox } from 'primereact/checkbox';

const ModalImport = ({ isShow, hiden, rowData, toast }) => {
  const dispatch = useDispatch();
  const { dataUpdate, error } = useSelector(
    (state) => ({
      dataUpdate: state.Production.dataUpdate,
      error: state.Production.error,
    }),
    shallowEqual
  );

  const [notMachining, setNotMachining] = useState(
    rowData?.notMachining || false
  );

  useEffect(() => {
    if (rowData) {
      setNotMachining(rowData.notMachining || false);
    }
  }, [rowData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bomProductionDate: rowData?.bomProductionDate
        ? new Date(rowData.bomProductionDate)
        : '',
      materialOrderingDate: rowData?.materialOrderingDate
        ? new Date(rowData.materialOrderingDate)
        : '',
      machiningOrderingDate: rowData?.machiningOrderingDate
        ? new Date(rowData.machiningOrderingDate)
        : '',
      factoryCode: rowData?.factoryCode || '',
      materialName: rowData?.materialName || '',
      categoryMaterial: rowData?.categoryMaterial || '',
      technicalNote: rowData?.technicalNote || '',
    },
    onSubmit: async (values) => {
      const payload = {
        ...values,
        bomProductionDate: values.bomProductionDate
          ? values.bomProductionDate.toISOString()
          : null,
        materialOrderingDate: values.materialOrderingDate
          ? values.materialOrderingDate.toISOString()
          : null,
        materialName: values.materialName || null,
        categoryMaterial: values.categoryMaterial || null,
        technicalNote: values.technicalNote || null,
        notMachining,
      };

      if (!notMachining) {
        payload.machiningOrderingDate = values.machiningOrderingDate
          ? values.machiningOrderingDate.toISOString()
          : null;
      } else {
        delete payload.machiningOrderingDate;
      }

      // Loại bỏ các trường có giá trị undefined
      Object.keys(payload).forEach((key) => {
        if (payload[key] === undefined) {
          delete payload[key];
        }
      });

      dispatch(updateProduction(payload));
    },
  });

  useEffect(() => {
    if (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: error.mes,
        life: 5000,
      });
      dispatch(setDataNull());
    }
    if (dataUpdate) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Cập nhật thông tin thành công',
        life: 5000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      hiden();
    }
  }, [error, dataUpdate]);

  return (
    <Dialog
      className='responsive-dialog'
      visible={isShow}
      onHide={() => {
        formik.resetForm();
        hiden();
      }}
      header='Cập nhật thông tin'
      modal
      style={{ width: '35%', height: '77%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              hiden();
            }}
            severity='secondary'
            className='p-button-text'
          />
          <Button
            type='submit'
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <div className='bg-white p-3 border-round-md'>
        <div className='flex flex-column mb-4 gap-3'>
          <div className='flex flex-column w-12 mb-3'>
            <label className='mb-2'>Ngày đặt vật tư</label>
            <Calendar
              id='materialOrderingDate'
              dateFormat='dd/mm/yy'
              {...formik.getFieldProps('materialOrderingDate')}
              value={formik.values.materialOrderingDate}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.materialOrderingDate &&
                formik.errors.materialOrderingDate
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.materialOrderingDate &&
            formik.errors.materialOrderingDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors.materialOrderingDate.toString()}
              </div>
            ) : null}
          </div>
          <div className='flex flex-column w-12 mb-3'>
            <label className='mb-2'>Tên vật tư chính</label>
            <InputText
              id='materialName'
              name='materialName'
              value={formik.values.materialName}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.materialName && formik.errors.materialName
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.materialName && formik.errors.materialName ? (
              <div style={{ color: 'red' }}>
                {formik.errors.materialName.toString()}
              </div>
            ) : null}
          </div>
          <div className='flex flex-column w-12 mb-3'>
            <div className='mb-2 flex justify-content-between'>
              <label>Ngày đặt hàng gia công</label>
              <div className='card flex justify-content-center'>
                <Checkbox
                  checked={notMachining}
                  onChange={(e) => setNotMachining(e.checked)}
                />
                <label className='ml-2'>Không cần hàng gia công</label>
              </div>
            </div>
            {!notMachining && (
              <>
                <Calendar
                  id='machiningOrderingDate'
                  dateFormat='dd/mm/yy'
                  {...formik.getFieldProps('machiningOrderingDate')}
                  value={formik.values.machiningOrderingDate}
                  onChange={formik.handleChange}
                  style={{ height: '40px' }}
                  className={
                    formik.touched.machiningOrderingDate &&
                    formik.errors.machiningOrderingDate
                      ? 'p-invalid'
                      : ''
                  }
                />
                {formik.touched.machiningOrderingDate &&
                formik.errors.machiningOrderingDate ? (
                  <div style={{ color: 'red' }}>
                    {formik.errors.machiningOrderingDate.toString()}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className='flex flex-column w-12 mb-3'>
            <label className='mb-2'>Hạng mục gia công</label>
            <InputText
              id='categoryMaterial'
              name='categoryMaterial'
              value={formik.values.categoryMaterial}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.categoryMaterial &&
                formik.errors.categoryMaterial
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.categoryMaterial &&
            formik.errors.categoryMaterial ? (
              <div style={{ color: 'red' }}>
                {formik.errors.categoryMaterial.toString()}
              </div>
            ) : null}
          </div>
          <div className='flex flex-column w-12 mb-3'>
            <label className='mb-2'>Ngày ra bom sản xuất</label>
            <Calendar
              id='bomProductionDate'
              dateFormat='dd/mm/yy'
              {...formik.getFieldProps('bomProductionDate')}
              value={formik.values.bomProductionDate}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.bomProductionDate &&
                formik.errors.bomProductionDate
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.bomProductionDate &&
            formik.errors.bomProductionDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors.bomProductionDate.toString()}
              </div>
            ) : null}
          </div>
          <div className='flex flex-column w-12 mb-3'>
            <label className='mb-2'>Ghi chú</label>
            <InputText
              id='technicalNote'
              name='technicalNote'
              value={formik.values.technicalNote}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.technicalNote && formik.errors.technicalNote
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.technicalNote && formik.errors.technicalNote ? (
              <div style={{ color: 'red' }}>
                {formik.errors.technicalNote.toString()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalImport;
