import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardTeamsData: null,
    dashboardTeamsDetailData: null,
    dashboardTeamsWorkingData: null,
    error: null,
    loading: false,
    excelDataTeam: null,
    errorExcelDataTeam: null,
};

const dashboardTeamsSlice = createSlice({
    name: "dashboardTeams",
    initialState,
    reducers: {
        getDashboardTeams: (state) => {
            state.loading = true;
        },
        getDashboardTeamsSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTeamsData = action.payload;
            state.error = null;
        },
        getDashboardTeamsFail: (state, action) => {
            state.loading = false;
            state.dashboardTeamsData = null;
            state.error = action.payload;
        },
        getDashboardTeamsDetail: (state) => {
            state.loading = true;
        },
        getDashboardTeamsDetailSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTeamsDetailData = action.payload;
            state.error = null;
        },
        getDashboardTeamsDetailFail: (state, action) => {
            state.loading = false;
            state.dashboardTeamsDetailData = null;
            state.error = action.payload;
        },
        getDashboardTeamsWorking: (state) => {
            state.loading = true;
        },
        getDashboardTeamsWorkingSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTeamsWorkingData = action.payload;
            state.error = null;
        },
        getDashboardTeamsWorkingFail: (state, action) => {
            state.loading = false;
            state.dashboardTeamsWorkingData = null;
            state.error = action.payload;
        },
        downloadExcelTeam: (state, action) => {
            state.loading = true;
        },
        downloadExcelTeamSuccess: (state, action) => {
            state.loading = false;
            state.excelDataTeam = action.payload;
            state.errorExcelDataFactory = null;
        },
        downloadExcelTeamFail: (state, action) => {
            state.loading = false;
            state.excelDataTeam = null;
            state.errorExcelDataTeam = action.payload;
        },
        setExcelDataTeamNull: (state) => {
            state.excelDataTeam = null;
            state.errorExcelDataTeam = null;
        },
    },
});

export const {
    getDashboardTeams,
    getDashboardTeamsSuccess,
    getDashboardTeamsFail,
    getDashboardTeamsDetail,
    getDashboardTeamsDetailFail,
    getDashboardTeamsDetailSuccess,
    getDashboardTeamsWorking,
    getDashboardTeamsWorkingFail,
    getDashboardTeamsWorkingSuccess,
    downloadExcelTeam,
    downloadExcelTeamFail,
    downloadExcelTeamSuccess,
    setExcelDataTeamNull,
} = dashboardTeamsSlice.actions;

export default dashboardTeamsSlice.reducer;
