import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { getOrder } from '../../store/order/slice';
import { addQrNote, setDataNull,checkPassword } from '../../store/teams/slice';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import UploadFile from '../../components/UploadFile';
import { Toast } from 'primereact/toast';
import { QrReader } from 'react-qr-reader';
import '../../assets/scss/theme-base/ModalProjectDeliveryTeam.css';
import { AutoComplete } from 'primereact/autocomplete';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ModalProjectDeliveryNote = () => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clearFiles, setClearFiles] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [qrModalIsOpen, setQrModalIsOpen] = useState(false);
  const [isRearCamera, setIsRearCamera] = useState(true);
  const [isFactoryCodeSelected, setIsFactoryCodeSelected] = useState(false); // Thêm trạng thái này
  const query = useQuery();
  const name = query.get('name') || '';
  const initialFactoryCode = query.get('factoryCode') || '';
  const toast = useRef(null);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);
  const [password, setPassword] = useState('');
  const [filteredFactoryCodes, setFilteredFactoryCodes] = useState([]);


  const { addQrNoteData, errorQrNote, orderData,checkPasswordData, errorCheckPassword } = useSelector(
    (state) => ({
      addQrNoteData: state.Teams.addQrNoteData,
      errorQrNote: state.Teams.errorQrNote,
      orderData: state.Production.orderData,
      checkPasswordData: state.Teams.checkPasswordData,
      errorCheckPassword: state.Teams.errorCheckPassword
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isPasswordEntered) {
    dispatch(
      getOrder({
        page: 1,
        limit: orderData?.data?.products?.totalItems,
        current: 'plan',
      })
    );
    }
  }, [dispatch, isPasswordEntered, orderData?.data?.products?.totalItems]);

  const getFactoryCodeOptions = () => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return [];
    return orderData.data.products.items.map((item) => ({
      label: item.factoryCode,
      value: item.factoryCode,
    }));
  };

  const searchFactoryCode = (event) => {
    const allOptions = getFactoryCodeOptions();
    setFilteredFactoryCodes(
      allOptions.filter((option) =>
        option.label?.toLowerCase().includes(event.query.toLowerCase())
      )
    );
  };

  const getQuantityByFactoryCode = (factoryCode) => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return '';
      
    const item = orderData.data.products.items.find(
      (item) => item.factoryCode === factoryCode.label
    );
    return item ? item.quantity : '';
  };

  const getQuantityByFactoryCode2 = (factoryCode) => {
    if (
      !orderData ||
      !orderData.data ||
      !orderData.data.products ||
      !orderData.data.products.items
    )
      return '';
      
    const item = orderData.data.products.items.find(
      (item) => item.factoryCode === factoryCode
    );    
    return item ? item.quantity : '';
  };

  const validationSchema = Yup.object().shape({
    note: Yup.string().required('Vui lòng nhập ghi chú'),
  });

  

  const onUpload = () => {};

  const getFiles = (files) => {
    setSelectedFiles(files);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name,
      factoryCode: initialFactoryCode,
      quantity:  '',
      note: '',
    },
    onSubmit: async (values) => {
      const data = {
        teamName: values.name,
        note: values.note,
        factoryCode: values.factoryCode,
      };
      dispatch(addQrNote({ values: data, files: Array.from(selectedFiles) }));
    },
  });

  const handleFactoryCodeChange = (e) => {
    const selectedFactoryCode = e.value;
    formik.setFieldValue('factoryCode', selectedFactoryCode);
    const selectedQuantity = getQuantityByFactoryCode(selectedFactoryCode);
    formik.setFieldValue('quantity', selectedQuantity);
    setIsFactoryCodeSelected(true);
  };

  const openQrModal = () => setQrModalIsOpen(true);
  const closeQrModal = () => setQrModalIsOpen(false);
  const toggleCamera = () => setIsRearCamera((prev) => !prev);

  const handleScan = (data) => {
    if (data) {
      const url = new URL(data);
      const factoryCode = url.searchParams.get('factoryCode');
      if (factoryCode) {
        formik.setFieldValue('factoryCode', factoryCode);
        const selectedQuantity = getQuantityByFactoryCode2(factoryCode);
        formik.setFieldValue('quantity', selectedQuantity);
        setIsFactoryCodeSelected(true); // Cập nhật trạng thái khi quét Mã nhà máy
      }
      closeQrModal();
    }
  };

  const handleError = (err) => {
    console.error(err);
    toast.current?.show({
      severity: 'error',
      summary: 'Thất bại',
      detail: 'Lỗi khi quét mã QR',
      life: 3000,
    });
  };

  const handleStartSubmit = () => {
    formik.handleSubmit();
  };


  useEffect(() => {
    if (addQrNoteData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thêm thành công',
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  }, [dispatch, addQrNoteData]);

  useEffect(() => {
    if (errorQrNote) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorQrNote?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [dispatch, errorQrNote]);

  const handleClose = () => {
    setIsShow(false);
    navigate('/');
  };

  const handlePasswordSubmit = () => {
    const data = {
      teamName: formik.values.name, 
      password: password,
    };
  
    dispatch(checkPassword(data));
  };

  useEffect(() => {
    if (checkPasswordData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Nhập mật khẩu thành công',
        life: 3000,
      });
      setIsPasswordEntered(true);
      dispatch(setDataNull());
      
    }
  }, [dispatch, checkPasswordData]);

  useEffect(() => {
    if (errorCheckPassword) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorCheckPassword?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [dispatch, errorCheckPassword]);
  
  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header='Báo cáo chất lượng'
        visible={isShow}
        className='responsive-dialog'
        style={{ width: '50vw' }}
        onHide={handleClose}>
        <div className='bg-white p-3 border-round-md'>
            <div className='flex flex-column mb-4 gap-3'>
              <div className='flex flex-column w-12 mb-3'>
                <label className='mb-2'>Tên tổ đội</label>
                <InputText
                  id='name'
                  name='name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  style={{ height: '40px' }}
                  className={
                    formik.touched.name && formik.errors.name ? 'p-invalid' : ''
                  }
                  readOnly
                />
                {formik.touched.name && formik.errors.name ? (
                  <div style={{ color: 'red' }}>
                    {formik.errors.name.toString()}
                  </div>
                ) : null}
              </div>
              {!isPasswordEntered && (
              <>
                <div className='flex flex-column w-12 mb-3'>
                  <label className='mb-2'>Mật khẩu</label>
                  <InputText
                    id='password'
                    name='password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ height: '40px' }}
                    className={
                      formik.touched.password && formik.errors.password ? 'p-invalid' : ''
                    }
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors.password.toString()}
                    </div>
                  ) : null}
                </div>
                <div className='flex mb-3 justify-content-center'>
                  <Button
                    label='Xác nhận'
                    onClick={handlePasswordSubmit}
                  />
                </div>
              </>
            )}
            {isPasswordEntered && (
              <>
              <div className='flex flex-column w-12 mb-3'>
                <label className='mb-2'>Mã nhà máy</label>
                <div className='flex gap-1'>
                <AutoComplete
                      id="factoryCode"
                      name="factoryCode"
                      value={formik.values.factoryCode}
                      suggestions={filteredFactoryCodes}
                      completeMethod={searchFactoryCode}
                      field="label"
                      onChange={handleFactoryCodeChange}
                      onClick={handleDropdownOpen}
                      dropdown
                      style={{
                        height: "40px",
                        width: "93%",
                      }}
                      className={
                        formik.touched.factoryCode && formik.errors.factoryCode
                          ? "p-invalid"
                          : ""
                      }
                    />
                  <Button
                    icon='pi pi-qrcode'
                    className='p-button-outlined'
                    onClick={openQrModal}
                    style={{ width: '7%', minWidth: '40px' }}
                  />
                </div>

                {formik.touched.factoryCode && formik.errors.factoryCode ? (
                  <div style={{ color: 'red' }}>
                    {formik.errors.factoryCode.toString()}
                  </div>
                ) : null}
              </div>
              {isFactoryCodeSelected && (
                <>
                  <div className='flex flex-column w-12 mb-3'>
                    <label className='mb-2'>Số lượng đơn hàng</label>
                    <InputText
                      id='quantity'
                      name='quantity'
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      style={{ height: '40px' }}
                      className={
                        formik.touched.quantity && formik.errors.quantity
                          ? 'p-invalid'
                          : ''
                      }
                      readOnly
                    />
                    {formik.touched.quantity && formik.errors.quantity ? (
                      <div style={{ color: 'red' }}>
                        {formik.errors.quantity.toString()}
                      </div>
                    ) : null}
                  </div>
                  <div className='flex flex-column w-12 mb-3'>
                    <label className='mb-2'>Ghi chú</label>
                    <InputText
                      id='note'
                      name='note'
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      style={{ height: '40px' }}
                      className={
                        formik.touched.note && formik.errors.note
                          ? 'p-invalid'
                          : ''
                      }
                    />
                    {formik.touched.note && formik.errors.note ? (
                      <div style={{ color: 'red' }}>
                        {formik.errors.note.toString()}
                      </div>
                    ) : null}
                  </div>
                  <UploadFile
                    onUpload={onUpload}
                    getFiles={getFiles}
                    clearFiles={clearFiles}
                  />
                  <div className='flex mb-3 justify-content-center gap-5'>
                    <Button
                      label='Nhập'
                      onClick={handleStartSubmit}
                    />
                  </div>
                </>
              )}
              </>
            )}
            </div>
        </div>
      </Dialog>

      <Dialog
        header='Quét mã QR'
        visible={qrModalIsOpen}
        style={{ width: '80vw' }}
        onHide={closeQrModal}>
        <QrReader
          onResult={handleScan}
          onError={handleError}
          style={{ width: '100%' }}
          constraints={{
            facingMode: isRearCamera ? 'environment' : 'user',
          }}
        />
        <div className='flex justify-content-between'>
          <Button
            label='Chuyển camera'
            onClick={toggleCamera}
          />
          <Button
            label='Đóng'
            onClick={closeQrModal}
          />
        </div>
      </Dialog>
    </>
  );
};

export default ModalProjectDeliveryNote;
