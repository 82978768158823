import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  constructionData: [],
  constructionDataByID: null,
  error: null,
  loading: false,
  insertData: null,
  updateData: null,
  deleteData: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const constructionSlice = createSlice({
  name: 'construction',
  initialState,
  reducers: {
    getDataAll: (state) => {
      state.loading = true;
    },
    getDataAllSuccess: (state, action) => {
      state.loading = false;
      state.constructionData = action.payload;
      state.error = null;
    },
    getDataAllFail: (state, action) => {
      state.loading = false;
      state.constructionData = [];
      state.error = action.payload;
    },
    addConstruction: (state) => {
      state.loading = true;
    },
    addConstructionSuccess: (state, action) => {
      state.loading = false;
      state.insertData = action.payload;
      state.errorInsert = null;
    },
    addConstructionFail: (state, action) => {
      state.loading = false;
      state.errorInsert = action.payload;
      state.insertData = null;
    },
    updateConstruction: (state) => {
      state.loading = true;
      state.updateData = null;
      state.errorUpdate = null;
    },
    updateConstructionSuccess: (state, action) => {
      state.loading = false;
      state.updateData = action.payload;
      state.errorUpdate = null;
    },
    updateConstructionFail: (state, action) => {
      state.loading = false;
      state.errorUpdate = action.payload;
      state.updateData = null;
    },
    deleteConstruction: (state) => {
      state.loading = true;
    },
    deleteConstructionSuccess: (state, action) => {
      state.loading = false;
      state.deleteData = action.payload;
      state.errorDelete = null;
    },
    deleteConstructionFail: (state, action) => {
      state.loading = false;
      state.deleteData = null;
      state.errorDelete = action.payload;
    },
    setDataNull: (state) => {
      state.error = null;
      state.insertData = null;
      state.updateData = null;
      state.deleteData = null;
      state.errorInsert = null;
      state.errorUpdate = null;
      state.errorDelete = null;
    },
  },
});

export const {
  getDataAll,
  getDataAllSuccess,
  getDataAllFail,
  addConstruction,
  addConstructionSuccess,
  addConstructionFail,
  updateConstruction,
  updateConstructionSuccess,
  updateConstructionFail,
  deleteConstruction,
  deleteConstructionSuccess,
  deleteConstructionFail,
  setDataNull,
} = constructionSlice.actions;

export default constructionSlice.reducer;
