import { call, put, takeEvery } from "redux-saga/effects";
import { getDashboardPlanFail, getDashboardPlanSuccess } from "./slice";
import { getDashboardPlan } from "../../api/backend_helper";

function* getDashboardPlanSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            status,
        } = action.payload;
        const data = yield call(getDashboardPlan, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            status,
        });
        yield put(getDashboardPlanSuccess(data));
    } catch (error) {
        yield put(getDashboardPlanFail(error.message));
    }
}

function* DashboardPlan() {
    yield takeEvery("dashboardPlan/getDashboardPlan", getDashboardPlanSaga);
}

export default DashboardPlan;
