import React, { useEffect, useState, useContext, useCallback } from 'react';
import HeaderReport from '../../layout/HeaderReport/HeaderReport';
import HeaderReportTechnical from './HeaderReportTechnical';
import TableReportTechnical from './TableReportTechnical';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getDashboardTechnical,
  getDashboardTechnicalDetail,
} from '../../store/dashboardTechnical/slice';
import { exportTechnicalPDF } from './ExportTechnicalPDF';
import FormatDate from '../Report/FormatDate';
import { DataContext } from '../../contexts/data/DataProvider';

export default function ReportTechnical() {
  const dispatch = useDispatch();
  const [building, setBuilding] = useState(null);
  const [orderCode, setOrderCode] = useState('');
  const [category, setCategory] = useState('');
  const [factoryCode, setFactoryCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [nameBuilding, setNameBuilding] = useState('');
  const [handleChildClick, setHandleChildClick] = useState(() => () => {});
  const [pagination, setPagination] = useState({ first: 0, rows: 10 });
  const [tabMenuActive, setTabMenuActive] = useState(0);

  const handleTabMenuChange = (newTabMenuActive) => {
    setTabMenuActive(newTabMenuActive);
  };

  const handlePaginationChange = (first, rows) => {
    setPagination({ first, rows });
  };

  const childClickHandler = useCallback((handler) => {
    setHandleChildClick(() => handler);
  }, []);

  const handleClickInParent = () => {
    if (handleChildClick) {
      handleChildClick();
    }
  };

  const { buildingName } = useContext(DataContext);
  useEffect(() => {
    if (buildingName) setNameBuilding(buildingName);
  }, [buildingName]);

  const dashboardTechnicalData = useSelector(
    (state) => state.DashboardTechnical.dashboardTechnicalData,
    shallowEqual
  );

  const dashboardTechnicalDetailData = useSelector(
    (state) => state.DashboardTechnical.dashboardTechnicalDetailData,
    shallowEqual
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (startDate && endDate) {
        dispatch(
          getDashboardTechnical({
            timeStartReq: startDate,
            timeEndReq: endDate,
            building,
            orderCode,
            category,
            factoryCode,
          })
        );
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    endDate,
    startDate,
    building,
    orderCode,
    category,
    factoryCode,
  ]);

  const limit = dashboardTechnicalDetailData?.data?.products?.totalItems;

  useEffect(() => {
    let status = 'Tất cả';
    if (dashboardTechnicalDetailData) {
      status =
        dashboardTechnicalDetailData.data.listStatus[tabMenuActive]?.label;
    }

    const timeout = setTimeout(() => {
      if (startDate && endDate) {
        dispatch(
          getDashboardTechnicalDetail({
            page: (pagination.first + pagination.rows) / pagination.rows,
            limit: pagination.rows,
            status,
            timeStartReq: startDate,
            timeEndReq: endDate,
            building,
            orderCode,
            category,
            factoryCode,
          })
        );
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    building,
    orderCode,
    category,
    factoryCode,
    startDate,
    endDate,
    limit,
    pagination,
    tabMenuActive,
  ]);

  return (
    <div>
      <HeaderReport
        building={building}
        orderCode={orderCode}
        category={category}
        factoryCode={factoryCode}
        setBuilding={setBuilding}
        setOrderCode={setOrderCode}
        setCategory={setCategory}
        setFactoryCode={setFactoryCode}
        setStartDateOutput={setStartDate}
        setEndDateOutput={setEndDate}
        exportClick={(e) =>
          exportTechnicalPDF(
            'save',
            FormatDate(startDate),
            FormatDate(endDate),
            dashboardTechnicalData?.data,
            dashboardTechnicalDetailData?.data?.products?.items,
            nameBuilding
          )
        }
        printReport={(e) =>
          exportTechnicalPDF(
            'print',
            FormatDate(startDate),
            FormatDate(endDate),
            dashboardTechnicalData?.data,
            dashboardTechnicalDetailData?.data?.products?.items,
            nameBuilding
          )
        }
        exportExcel={handleClickInParent}
      />
      <HeaderReportTechnical dashboardTechnicalData={dashboardTechnicalData} />
      <TableReportTechnical
        dashboardTechnicalDetailData={dashboardTechnicalDetailData}
        onButtonClick={childClickHandler}
        onPaginationChange={handlePaginationChange}
        onTabMenuChange={handleTabMenuChange}
      />
    </div>
  );
}
