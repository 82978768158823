import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store/index";
import { DataProvider } from "./contexts/data/DataProvider";
import "./locales/setupLocales"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <DataProvider>
        <App />
    </DataProvider>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
