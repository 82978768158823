import { Tag } from "primereact/tag";

export const statusTemplate = (rowData) => {
    switch (rowData.status) {
        case "Hoàn thành đúng tiến độ":
            return (
                <Tag
                    value={rowData.status}
                    severity="success"
                    style={{
                        width: "100px",
                        height: "30px",
                        backgroundColor: "#D2ECC8",
                        color: "green",
                    }}
                ></Tag>
            );
        case "Đang sản xuất":
            return (
                <Tag
                    value={rowData.status}
                    severity="info"
                    style={{
                        width: "100px",
                        height: "30px",
                        backgroundColor: "#E0DFFF",
                        color: "blue",
                    }}
                ></Tag>
            );
        case "Trễ tiến độ":
            return (
                <Tag
                    value={rowData.status}
                    severity="danger"
                    style={{
                        width: "100px",
                        height: "25px",
                        backgroundColor: "#FFD9D9",
                        color: "red",
                    }}
                ></Tag>
            );
        case "Hoàn thành trễ tiến độ":
            return (
                <Tag
                    value={rowData.status}
                    severity="danger"
                    style={{
                        width: "100px",
                        height: "30px",
                        backgroundColor: "#F3DFCD",
                        color: "orange",
                    }}
                ></Tag>
            );
        case "Chưa có BOM":
            return (
                <Tag
                    value={rowData.status}
                    style={{
                        width: "100px",
                        height: "25px",
                        color: "black",
                        backgroundColor: "#EFEFEF",
                    }}
                ></Tag>
            );
        case "Chưa sản xuất":
            return (
                <Tag
                    value={rowData.status}
                    style={{
                        width: "100px",
                        height: "25px",
                        color: "black",
                        backgroundColor: "#EFEFEF",
                    }}
                ></Tag>
            );
        default:
            return rowData.status;
    }
};

export const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatDate2 = (dateString) => {
    if (!dateString) {
        return ""; // Hoặc giá trị mặc định khác mà bạn muốn trả về
    }

    const date = new Date(dateString);

    // Kiểm tra nếu 'date' là 'Invalid Date'
    if (isNaN(date.getTime())) {
        return ""; // Hoặc giá trị mặc định khác mà bạn muốn trả về
    }

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const QuantityTemplate = (rowData) => {
    const totalActualDelivery =
        rowData.totalActualDelivery !== null ? rowData.totalActualDelivery : 0;
    const quantity = rowData?.quantity || 0;
    const quantityConLai = quantity - totalActualDelivery;
    const progress =
        quantity !== 0 ? (totalActualDelivery / quantity) * 100 : 0;

    if (
        isNaN(totalActualDelivery) ||
        isNaN(quantityConLai) ||
        isNaN(progress)
    ) {
        return (
            <div className="progress-container">
                <div className="progress-bar" style={{ width: `0%` }}>
                    <span className="progress-text">N/A</span>
                </div>
                <span className="progress-remaining">N/A</span>
            </div>
        );
    }

    if (rowData.status === "Trễ tiến độ") {
        return (
            <div className="progress-container">
                <div
                    className="progress-bar overdue"
                    style={{ width: `${progress}%` }}
                >
                    <span className="progress-text1">
                        {totalActualDelivery}
                    </span>
                </div>
                <span className="progress-remaining1">{quantityConLai}</span>
            </div>
        );
    }

    if (rowData.status === "Đang sản xuất") {
        return (
            <div className="progress-container">
                <div
                    className="progress-bar overdue"
                    style={{ width: `${progress}%` }}
                >
                    <span className="progress-text2">
                        {totalActualDelivery}
                    </span>
                </div>
                <span className="progress-remaining">{quantityConLai}</span>
            </div>
        );
    }

    if (quantityConLai === 0) {
        return (
            <div className="progress-container">
                <div
                    className="progress-bar0"
                    style={{ width: `${progress}%` }}
                >
                    <span className="progress-text">{totalActualDelivery}</span>
                    <span className="progress-remaining0">
                        {quantityConLai}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="progress-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}>
                <span className="progress-text">{totalActualDelivery}</span>
            </div>
            <span className="progress-remaining">{quantityConLai}</span>
        </div>
    );
};

export const formatNumber = (number) => {
    if (number === null || number === undefined) {
        return "";
    }
    // Đảm bảo rằng number là một số trước khi định dạng
    const num = parseFloat(number);
    if (isNaN(num)) {
        return "";
    }
    // Chuyển đổi số thành chuỗi và định dạng với dấu phân cách hàng nghìn
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
