import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ColumnChart = ({ dashboardTeams }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (dashboardTeams && dashboardTeams.data) {
            setData(dashboardTeams?.data);
        }
    }, [dashboardTeams]);

    const options = {
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "20px",
            },
        },
        dataLabels: { enabled: false },
        xaxis: {
            categories: dashboardTeams?.data?.map((e) => e.name) || [],
        },
    };
    return (
        <div className="bg-white border-round-lg py-4 px-3">
            <p className="font-bold text-500">
                Sản lượng hoàn thành theo tổ đội
            </p>
            <ReactApexChart
                options={options}
                type="bar"
                series={[
                    {
                        name: "Sản lượng",
                        data: data.map((item) => item.totalQuantityMade),
                    },
                ]}
                height={"300px"}
            />
        </div>
    );
};

export default ColumnChart;
