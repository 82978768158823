import { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import '../../assets/scss/theme-base/QuantityTemplate.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import tabMenuTemplate from '../../assets/templete/tabMenuTemplete';
import ModalImport from './ModalImport';
import {
  statusTemplate,
  formatDate,
  formatNumber,
} from '../../components/utils';
import {
  getOrder,
  setDataNull,
  getBuilding,
  exportExcel2,
  setDataNull2,
  resetTechniqueExcel,
} from '../../store/order/slice';

import Intersection from '../Plan/intersection';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import QR from '../Plan/QR';
import LoadingSpinner from '../Report/LoadingSpinner';
import ModalExcel from './ModalExcel';
import { site } from '../../api/url_helper';
import { useLocation } from 'react-router-dom';
import { exportQuantityPDF } from './exportQuantityPDF';
import { Image } from 'primereact/image';
import { Menu } from 'primereact/menu';
import { FilterMatchMode } from 'primereact/api';

export default function Technique() {
  const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectFactoryCode, setSelectFactoryCode] = useState(null);
  const [category, setCategory] = useState(null);
  const [factoryCode, setFactoryCode] = useState(null);
  const [building, setBuilding] = useState(null);
  const [orderCode, setOrderCode] = useState(null);
  const [categoryValue, setCategoryValue] = useState('');
  const [factoryCodeValue, setFactoryCodeValue] = useState('');
  const [buildingValue, setBuildingValue] = useState();
  const [orderCodeValue, setOrderCodeValue] = useState('');
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [debounceTimeouts, setDebounceTimeouts] = useState({});
  const [qrDetail, setQrDetail] = useState(null);
  const [visible3, setVisible3] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dialogImportVisible, setDialogImportVisible] = useState(false);
  const [excelFileUrl, setExcelFileUrl] = useState('');
  const downloadLink = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [notMaterial, setNotMaterial] = useState(false);
  const [notMachining, setNotMachining] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const menuRight = useRef(null);
  const defaultImageUrl =
    'https://static.thenounproject.com/png/1269202-200.png';

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'Building.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryMaterial: { value: null, matchMode: FilterMatchMode.CONTAINS },
    materialName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productNote: { value: null, matchMode: FilterMatchMode.CONTAINS },
    totalActualDelivery: { value: null, matchMode: FilterMatchMode.CONTAINS },
    intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    bomProductionDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    startedDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    endedDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    machiningOrderingDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    materialOrderingDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    technicalNote: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const options = [
    { label: 'Chưa đặt vật tư', value: 'notMaterial' },
    { label: 'Chưa đặt hàng gia công', value: 'notMachining' },
  ];

  const location = useLocation();
  const state = location.state;

  const listPermissions =
    JSON.parse(localStorage.getItem('userPermissions')) || {};

  const {
    dataUpdate,
    orderData,
    dataBuilding,
    exportExcelData2,
    exportExcelError2,
    loadingExportExcel,
  } = useSelector(
    (state) => ({
      dataUpdate: state.Production.dataUpdate,
      error: state.Production.error,
      orderData: state.Production.orderData,
      dataBuilding: state.Production.dataBuilding,
      exportExcelData2: state.Production.techniqueExportExcel.data,
      exportExcelError2: state.Production.techniqueExportExcel.error,
      loadingExportExcel: state.Production.techniqueExportExcel.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let status = 'Tất cả';
    if (orderData) {
      status = orderData.data.listStatus[tabMenuActive]?.label;
    }
    dispatch(
      getOrder({
        page: (first + rows) / rows,
        limit: rows,
        status,
        category: category,
        factoryCode: factoryCode,
        building: building,
        orderCode: orderCode,
        notMachining: notMachining,
        notMaterial: notMaterial,
        current: 'technical',
      })
    );
  }, [
    dispatch,
    first,
    tabMenuActive,
    rows,
    category,
    factoryCode,
    building,
    orderCode,
    notMaterial,
    notMachining,
    visible,
  ]);

  useEffect(() => {
    if (dataUpdate) {
      dispatch(
        getOrder({
          page: (first + rows) / rows,
          limit: rows,
          current: 'technical',
        })
      );
      dispatch(setDataNull());
    }
  }, [dispatch, dataUpdate, first, rows]);

  useEffect(() => {
    const updateDataTable = () => {
      if (orderData) {
        setDataTable(orderData.data.products.items);
      }
    };
    updateDataTable();
  }, [orderData]);

  useEffect(() => {
    if (isDropdownOpen) {
      dispatch(
        getBuilding({
          page: 1,
          limit: 1000,
        })
      );
    }
  }, [isDropdownOpen, dispatch]);

  useEffect(() => {
    if (dataBuilding?.data?.items) {
      const buildings = dataBuilding.data.items.map((item) => ({
        label: item.name,
        value: item.buildingCode,
      }));
      setBuildingOptions(removeDuplicates(buildings, 'value'));
    }
  }, [dataBuilding]);

  useEffect(() => {
    if (state === 'Chưa có BOM') {
      setTabmenuActive(1);
    }
    if (state === 'notMaterial') {
      setSelectedOption('notMaterial');
      setNotMaterial(true);
    }
    if (state === 'notMachining') {
      setSelectedOption('notMachining');
      setNotMachining(true);
    }
  }, [state]);

  const handleTeamProgress = (rowData) => {
    setSelectFactoryCode(rowData.factoryCode);
    setDialogLocationVisible(true);
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleBuildingChange = (e) => {
    setBuilding(e.target.value);
    setBuildingValue(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryValue(e.target.value);
    debounce(() => setCategory(e.target.value), 'category');
  };

  const handleFactoryCodeChange = (e) => {
    setFactoryCodeValue(e.target.value);
    debounce(() => setFactoryCode(e.target.value), 'factoryCode');
  };

  const handleOrderCodeChange = (e) => {
    setOrderCodeValue(e.target.value);
    debounce(() => setOrderCode(e.target.value), 'orderCode');
  };

  const debounce = (callback, field) => {
    if (debounceTimeouts[field]) {
      clearTimeout(debounceTimeouts[field]);
    }

    const timeout = setTimeout(() => {
      callback();
      setDebounceTimeouts((prev) => ({ ...prev, [field]: null }));
    }, 400);

    setDebounceTimeouts((prev) => ({ ...prev, [field]: timeout }));
  };

  const removeDuplicates = (array, key) => {
    return array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
    );
  };

  const handleFilterChange = (e) => {
    const value = e.value;
    setSelectedOption(value);
    setNotMaterial(value === 'notMaterial');
    setNotMachining(value === 'notMachining');
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleDetailScanned = (detail) => {
    setQrDetail(detail);
    setIsModalVisible(true);
  };

  const quantityTemplate = (rowData) => {
    return formatNumber(rowData.quantity);
  };

  const logDataTable = () => {
    const ID = dataBuilding?.data?.items?.find(
      (item) => item.buildingCode === building
    )?.id;
    if (ID) {
      dispatch(
        exportExcel2({
          type: 'technical',
          building_id: ID,
        })
      );
    } else {
      dispatch(
        exportExcel2({
          type: 'technical',
        })
      );
    }
  };

  useEffect(() => {
    if (exportExcelData2) {
      const url = `${site}/${exportExcelData2.file}`;
      setExcelFileUrl(url);
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xuất excel thành công.',
        life: 3000,
      });
      dispatch(setDataNull2());
      dispatch(resetTechniqueExcel());

      // Tự động tải file
      downloadLink.current.href = url;
      // downloadLink.current.download = 'exported_data.xlsx';
      downloadLink.current.click();
    }
  }, [dispatch, exportExcelData2]);

  useEffect(() => {
    if (exportExcelError2) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: exportExcelError2.response.data.message,
        life: 3000,
      });
      dispatch(setDataNull2());
      dispatch(resetTechniqueExcel());
    }
  }, [dispatch, exportExcelError2]);

  const items = [];

  if (listPermissions['Kỹ thuật']?.includes('Cập nhật thông tin')) {
    items.push({
      label: 'Nhập từ excel',
      icon: 'pi pi-file-excel',
      command: () => setDialogImportVisible(true),
    });
  }

  items.push({
    label: 'Xuất ra excel',
    icon: 'pi pi-file-excel',
    command: logDataTable,
  });

  items.push({
    label: 'In báo cáo',
    icon: 'pi pi-print',
    command: () => exportQuantityPDF(dataTable),
  });

  const imageTemplate = (rowData) => {
    return (
      <Image
        src={rowData?.categoryImage || defaultImageUrl}
        alt='Category Image'
        imageStyle={{ borderRadius: '20%', width: '40px', height: '40px' }}
        preview
        onError={(e) => (e.target.src = defaultImageUrl)}
      />
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <a
        ref={downloadLink}
        style={{ display: 'none' }}
      />
      <div className='flex flex-column md:flex-row md:justify-content-end mb-3'>
        <Menu
          model={items}
          popup='true'
          ref={menuRight}
          id='popup_menu_right'
          popupAlignment='right'
        />
        <Button
          label={loadingExportExcel ? 'Đang xuất' : 'Thao tác'}
          icon={
            loadingExportExcel
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-sort-down-fill'
          }
          disabled={loadingExportExcel}
          iconPos='right'
          className='mr-2'
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls='popup_menu_right'
          aria-haspopup
        />
      </div>
      <div className='search-container pb-3'>
        <IconField className='search-item'>
          <Dropdown
            placeholder='Lọc công trình'
            options={buildingOptions}
            emptyFilterMessage='Không có dữ liệu'
            emptyMessage='Không có dữ liệu'
            onChange={handleBuildingChange}
            value={buildingValue}
            filter
            showClear
            className='w-full border-0'
            onClick={handleDropdownOpen}
          />
        </IconField>
        <IconField className='search-item'>
          <InputText
            value={orderCodeValue}
            placeholder='Mã đơn hàng'
            className='w-full border-0'
            onChange={handleOrderCodeChange}
          />
        </IconField>
        <IconField className='search-item'>
          <InputText
            value={categoryValue}
            onChange={handleCategoryChange}
            placeholder='Lọc hạng mục'
            className='w-full border-0'
          />
        </IconField>
        <IconField className='search-item'>
          <InputText
            value={factoryCodeValue}
            onChange={handleFactoryCodeChange}
            placeholder='Lọc mã nhà máy'
            className='w-full border-0'
          />
        </IconField>
        <IconField className='search-item'>
          <Dropdown
            placeholder='Lọc vật tư,đặt hàng gia công'
            emptyFilterMessage='Không có dữ liệu'
            emptyMessage='Không có dữ liệu'
            options={options}
            onChange={handleFilterChange}
            value={selectedOption}
            filter
            showClear
            className='w-full border-0'
          />
        </IconField>
      </div>

      <div className='bg-white border-round-md shadow-1 px-3'>
        {orderData && orderData.data ? (
          <>
            <div>
              <TabMenu
                model={orderData.data.listStatus.map((item, index) => ({
                  ...item,
                  template: () =>
                    tabMenuTemplate(
                      item,
                      index,
                      tabMenuActive,
                      setTabmenuActive
                    ),
                }))}
                activeIndex={tabMenuActive}
                onTabChange={(e) => setTabmenuActive(e.index)}
                pt={{ menuitem: 'p-0' }}
              />
            </div>
            <div>
              <DataTable
                value={dataTable}
                emptyMessage='Không có dữ liệu'
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                scrollHeight='60vh'
                scrollable
                size='small'>
                <Column
                  header='STT'
                  body={(rowData, options) => (
                    <div className='text-center'>
                      {first + options.rowIndex + 1}
                    </div>
                  )}
                  frozen
                  style={{ width: '3%', minWidth: '60px' }}
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Tên công trình'
                  field='Building.name'
                  filterPlaceholder='Tìm kiếm tên công trình'
                  style={{ width: '4%', minWidth: '200px' }}
                  filter
                  frozen></Column>
                <Column
                  header='Mã ĐH'
                  field='orderCode'
                  filterPlaceholder='Tìm kiếm mã đơn hàng'
                  style={{ width: '6%', minWidth: '100px' }}
                  filter
                  frozen></Column>
                <Column
                  header='Hạng mục'
                  field='categoryName'
                  filterPlaceholder='Tìm kiếm hạng mục'
                  style={{
                    width: '8%',
                    minWidth: '200px',
                    lineHeight: '1.5',
                  }}
                  frozen
                  filter></Column>
                <Column
                  header='Hình ảnh'
                  body={imageTemplate}
                  style={{ width: '5%', minWidth: '70px' }}
                  alignHeader={'center'}
                  frozen
                  align={'center'}></Column>

                <Column
                  header='SL'
                  field='quantity'
                  style={{ width: '3%', minWidth: '60px' }}
                  body={quantityTemplate}
                  alignHeader={'center'}
                  frozen
                  align={'center'}></Column>
                <Column
                  header='ĐVT'
                  field='unit'
                  filterPlaceholder='Tìm kiếm đơn vị tính'
                  style={{ width: '3%', minWidth: '60px' }}
                  filter
                  frozen></Column>
                <Column
                  header='Mã SP'
                  field='productCode'
                  filterPlaceholder='Tìm kiếm mã sản phẩm'
                  style={{ width: '5%', minWidth: '100px' }}
                  filter></Column>
                <Column
                  header='Mã nhà máy'
                  field='factoryCode'
                  style={{ width: '8%', minWidth: '200px' }}
                  filter
                  filterPlaceholder='Tìm kiếm mã nhà máy'></Column>
                <Column
                  header='Vật tư chính'
                  field='materialName'
                  filterPlaceholder='Tìm kiếm vật tư chính'
                  style={{ width: '8%', minWidth: '300px' }}
                  filter></Column>
                <Column
                  header='Hạng mục gia công'
                  field='categoryMaterial'
                  filterPlaceholder='Tìm kiếm hạng mục gia công'
                  style={{ width: '8%', minWidth: '165px' }}
                  filter></Column>

                <Column
                  header='Kế hoạch hoàn thành'
                  field='intendFinishDate'
                  body={(rowData) => formatDate(rowData.intendFinishDate)}
                  style={{ width: '8%', minWidth: '120px' }}
                  sortable
                  alignHeader={'center'}
                  align={'center'}></Column>

                <Column
                  header='Trạng thái'
                  field='status'
                  filterPlaceholder='Tìm kiếm trạng thái'
                  body={statusTemplate}
                  style={{ width: '12%', minWidth: '150px' }}
                  filter
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Ngày đặt vật tư'
                  style={{ width: '6%', minWidth: '135px' }}
                  sortable
                  field='materialOrderingDate'
                  body={(rowData) => formatDate(rowData.materialOrderingDate)}
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Ngày đặt hàng gia công'
                  style={{ width: '6%', minWidth: '195px' }}
                  sortable
                  field='machiningOrderingDate'
                  // body={(rowData) =>
                  //     formatDate(
                  //         rowData.machiningOrderingDate
                  //     )
                  // }
                  body={(rowData) =>
                    rowData.notMachining
                      ? 'Không cần hàng gia công'
                      : formatDate(rowData.machiningOrderingDate)
                  }
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='Ngày ra BOM'
                  style={{ width: '6%', minWidth: '120px' }}
                  field='bomProductionDate'
                  sortable
                  body={(rowData) => formatDate(rowData.bomProductionDate)}
                  align={'center'}
                  alignHeader={'center'}></Column>
                <Column
                  header='Ghi chú'
                  field='technicalNote'
                  style={{
                    minWidth: '150px',
                    height: '55px',
                  }}></Column>

                <Column
                  header='Định vị'
                  body={(rowData) => (
                    <div className='text-center'>
                      <i
                        className='pi pi-map-marker cursor-pointer'
                        onClick={() => handleTeamProgress(rowData)}></i>
                      <p>{rowData.teamWorking}</p>
                    </div>
                  )}
                  style={{
                    minWidth: '150px',
                  }}
                  alignHeader={'center'}
                  align={'center'}></Column>
                <Column
                  header='In QR'
                  body={(rowData) => (
                    <div className='text-center'>
                      <Button
                        onClick={() => {
                          setQrDetail(rowData);
                          setVisible3(true);
                        }}
                        icon='pi pi-qrcode'
                        rounded
                        text
                        severity='secondary'
                        aria-label='QR'
                        style={{ color: 'black' }}
                      />
                    </div>
                  )}
                  style={{ width: '8%', minWidth: '100px' }}
                  alignHeader={'center'}
                  align={'center'}></Column>
                {listPermissions['Kỹ thuật']?.includes(
                  'Cập nhật thông tin'
                ) && (
                  <Column
                    header='Nhập'
                    body={(rowData) => (
                      <div className='text-center'>
                        <Button
                          onClick={() => {
                            setVisible(true);
                            setSelectedRowData(rowData); // Set selected row data
                          }}
                          icon='pi pi-file-import'
                          rounded
                          text
                          severity='secondary'
                          aria-label='Bookmark'
                          style={{ color: 'black' }}
                        />
                      </div>
                    )}
                    style={{ width: '8%' }}
                    alignHeader={'center'}
                    align={'center'}></Column>
                )}
                <Column />
              </DataTable>
              <div className='card'>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={orderData?.data?.products?.totalItems}
                  rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
            <ModalImport
              isShow={visible}
              toast={toast}
              rowData={selectedRowData}
              hiden={() => setVisible(false)}
            />
            <ModalExcel
              onHide={() => setDialogImportVisible(false)}
              visible={dialogImportVisible}
              id={
                dataBuilding?.data.items.find(
                  (item) => item.buildingCode === building
                )?.id
              }
            />
            {dialogLocationVisible && (
              <Intersection
                visible={dialogLocationVisible}
                onHide={() => setDialogLocationVisible(false)}
                factoryCode={selectFactoryCode}
              />
            )}
            <QR
              isShow={visible3}
              hiden={() => setVisible3(false)}
              detail={qrDetail}
              onDetailScanned={handleDetailScanned}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
}
