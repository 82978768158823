import { Image } from 'primereact/image';
import logo from '../../assets/images/logo.png';

function Header() {
  return (
    <div className='bg-white flex justify-content-center p-2'>
      <div
        className='flex flex-row justify-content-center'
        style={{ width: '400px' }}>
        <div className='flex align-items-center justify-content-between ml-2 w-full'>
          <Image
            src={logo}
            alt='Image'
            height='50'
          />
          <span className='ml-3 font-bold text-primary'>NEM FURNITURE</span>
        </div>
      </div>
    </div>
  );
}

export default Header;
