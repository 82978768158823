import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { statusTemplate, QuantityTemplate } from "../../components/utils";
import FormatDate from "../Report/FormatDate";
import { Avatar } from "primereact/avatar";
import { site } from "../../api/url_helper";
import LoadingSpinner from "../Report/LoadingSpinner";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { imageTemplate } from "../Report/ProductInprogress";
import { FilterMatchMode } from "primereact/api";
import { teamImageTemplate } from "../Report/ProductInprogress";
import { Paginator } from "primereact/paginator";

const ProductInprogress = ({
    productInprogressFactory,
    onPaginationChange,
}) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };
    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
        if (onPaginationChange) {
            onPaginationChange(newFirst, newRows);
        }
    };
    return (
        <div className="p-3 bg-white border-round-lg h-full">
            <p
                style={{
                    color: "#5c5c5c",
                    fontWeight: "700",
                    marginBottom: "2rem",
                }}
            >
                Theo dõi sản xuất
            </p>
            {productInprogressFactory && productInprogressFactory.data ? (
                <div>
                    <DataTable
                        value={productInprogressFactory?.data?.items}
                        rowClassName="custom-row-class-factory-inprogress"
                        emptyMessage="Không có dữ liệu"
                        // paginator
                        // rows={10}
                        // rowsPerPageOptions={[10, 25, 50, 100, 500]}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        scrollable
                        scrollHeight="70vh"
                    >
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Hạng mục"
                            field="categoryName"
                            style={{
                                lineHeight: "1.5",
                                minWidth: "150px",
                            }}
                            filter
                        ></Column>
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ minWidth: "150px" }}
                            filter
                        />
                        <Column
                            header="Hình ảnh"
                            style={{ minWidth: "100px" }}
                            body={imageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ minWidth: "120px" }}
                            filter
                        ></Column>
                        <Column
                            header="ĐVT"
                            field="unit"
                            style={{ minWidth: "50px" }}
                            filter
                        ></Column>
                        <Column
                            header="SL"
                            field="quantity"
                            style={{ minWidth: "70px" }}
                        ></Column>
                        <Column
                            header="Kế hoạch hoàn thành"
                            field="intendFinishDate"
                            body={(rowData) =>
                                FormatDate(rowData.intendFinishDate)
                            }
                            style={{ minWidth: "120px" }}
                            sortable
                            align="center"
                            alignHeader="center"
                        ></Column>
                        <Column
                            header="Tiến độ giao hàng"
                            alignHeader={"center"}
                            align={"center"}
                            body={QuantityTemplate}
                            style={{ minWidth: "100px" }}
                        />
                        <Column
                            header="Định vị"
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                        icon="pi pi-map-marker"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="Bookmark"
                                        style={{ color: "black" }}
                                    />
                                    <p>{rowData.teamWorking}</p>
                                </div>
                            )}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="Trạng thái"
                            field="status"
                            body={statusTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "120px" }}
                            filter
                        ></Column>
                        <Column
                            header="Hình ảnh SX"
                            body={teamImageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="Ghi chú"
                            field="productNote"
                            style={{ minWidth: "200px" }}
                        ></Column>
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={
                            productInprogressFactory?.data?.totalItems
                        }
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    {dialogLocationVisible && (
                        <Intersection
                            visible={dialogLocationVisible}
                            onHide={() => setDialogLocationVisible(false)}
                            factoryCode={selectFactoryCode}
                        />
                    )}
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default ProductInprogress;
