import { combineReducers } from "redux";

import Auth from "./auth/slice";
import Production from "./order/slice";
import Construction from "./construction/slice";
import Dashboard from "./dashboard/slice";
import DashboardTeams from "./dashboardTeams/slice";
import DashboardPlan from "./dashboardPlan/slice";
import DashboardTechnical from "./dashboardTechnical/slice";
import DashboardQuantity from "./dashboardQuantity/slice";
import Teams from "./teams/slice";
import Permission from "./permission/slice";
import Product from "./product/slice";
import ReportQuality from "./quality/slice";

const rootReducer = combineReducers({
    Production,
    Construction,
    Auth,
    Dashboard,
    DashboardTeams,
    DashboardPlan,
    DashboardTechnical,
    DashboardQuantity,
    Teams,
    Permission,
    Product,
    ReportQuality,
});

export default rootReducer;
