import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { addTeams, setDataNull } from '../../store/teams/slice';
import '../../assets/scss/theme-base/Team.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';

const ModelAddTeam = ({ visible, onHide, toast }) => {
  const dispatch = useDispatch();

  const { errorInsert, insertData } = useSelector(
    (state) => ({
      errorInsert: state.Teams.errorInsert,
      insertData: state.Teams.insertData,
    }),
    shallowEqual
  );

  const validationSchema = Yup.object({
    teamCode: Yup.string().required('Giá trị này là bắt buộc'),
    name: Yup.string().required('Giá trị này là bắt buộc'),
    password: Yup.string()
      .required('Giá trị này là bắt buộc')
      .min(6, 'Mật khẩu phải có ít nhất 6 ký tự'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      teamCode: '',
      name: '',
      description: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(addTeams(values));
    },
  });

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorInsert?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (insertData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thêm tổ đội thành công',
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorInsert, insertData]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      className='responsive-dialog'
      header='Thêm tổ đội'
      modal
      style={{ width: '450px', minHeigh: '55%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            severity='secondary'
            className='p-button-text'
          />
          <Button
            type='submit'
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <form onSubmit={formik.handleSubmit}>
        <div className='bg-white p-3 border-round-md'>
          <div className='flex flex-column mb-4 gap-3'>
            <div className='flex flex-column w-12'>
              <label className='mb-2'>Tên tổ đội</label>
              <InputText
                id='name'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                invalid={
                  formik.touched.name && formik.errors.name ? true : false
                }
              />
              {formik.touched.name && formik.errors.name ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.name.toString()}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-12'>
              <label className='mb-2'>Mã tổ đội</label>
              <InputText
                id='teamCode'
                name='teamCode'
                value={formik.values.teamCode}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                invalid={
                  formik.touched.teamCode && formik.errors.teamCode
                    ? true
                    : false
                }
              />
              {formik.touched.teamCode && formik.errors.teamCode ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.teamCode.toString()}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-12 '>
              <label className='mb-2'>Mật khẩu</label>
              <Password
                value={formik.values.password}
                onChange={formik.handleChange}
                id='password'
                name='password'
                type='password'
                autoComplete='on'
                inputClassName='w-full'
                inputStyle={{ height: '40px' }}
                pt={{
                  iconField: {
                    root: { className: 'w-full' },
                  },
                }}
                toggleMask
                feedback={false}
                invalid={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.password.toString()}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-12 '>
              <label className='mb-2'>Ghi chú</label>
              <InputTextarea
                id='description'
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                autoResize
              />
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ModelAddTeam;
