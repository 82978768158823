import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { addUser, setDataInsertNull } from '../../store/auth/slice';
import { Password } from 'primereact/password';

const ModelAddUser = ({ visible, onHide, toast, permission }) => {
  const { userInsert, userInsertError } = useSelector(
    (state) => ({
      userInsert: state.Auth.userInsert,
      userInsertError: state.Auth.userInsertError,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    name: Yup.string().required('Tên người dùng không được để trống'),
    email: Yup.string()
      .required('Tên đăng nhập không được để trống')
      .min(6, 'Tên đăng nhập phải có ít nhất 6 ký tự'),
    password: Yup.string()
      .required('Mật khẩu không được để trống')
      .min(6, 'Mật khẩu phải có ít nhất 6 ký tự'),
    groupPermissionId: Yup.string().required('Vui lòng chọn quyền người dùng'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      groupPermissionId: '',
      note: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(addUser(values));
    },
  });

  useEffect(() => {
    if (userInsert) {
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thêm người dùng thành công',
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataInsertNull());
      onHide();
    }
    if (userInsertError) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: userInsertError.response.data.message,
        life: 3000,
      });
      dispatch(setDataInsertNull());
    }
  }, [userInsert, userInsertError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      header='Thêm người dùng'
      modal
      style={{ width: '450px', minHeight: '55%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            severity='secondary'
            className='p-button-text'
          />
          <Button
            type='submit'
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <form onSubmit={formik.handleSubmit}>
        <div className='bg-white border-round-md'>
          <div className='flex flex-column gap-3'>
            <div className='flex flex-column w-full '>
              <label className='mb-2'>Tên hiển thị</label>
              <InputText
                id='name'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                invalid={
                  formik.touched.name && formik.errors.name ? true : false
                }
              />
              {formik.touched.name && formik.errors.name ? (
                <div style={{ color: 'red', marginTop: '3px' }}>
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-full'>
              <label className='mb-2'>Tên đăng nhập</label>
              <InputText
                id='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                invalid={
                  formik.touched.email && formik.errors.email ? true : false
                }
              />
              {formik.touched.email && formik.errors.email ? (
                <div style={{ color: 'red', marginTop: '3px' }}>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-full'>
              <label className='mb-2'>Mật khẩu</label>
              <Password
                value={formik.values.password}
                onChange={formik.handleChange}
                id='password'
                name='password'
                type='password'
                autoComplete='on'
                inputClassName='w-full'
                inputStyle={{ height: '40px' }}
                pt={{
                  iconField: {
                    root: { className: 'w-full' },
                  },
                }}
                toggleMask
                feedback={false}
                invalid={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <p
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    fontSize: '0.9rem',
                  }}>
                  {String(formik.errors.password)}
                </p>
              ) : null}
            </div>
            <div className='flex flex-column w-full'>
              <label className='mb-2'>Chọn nhóm quyền</label>
              <Dropdown
                id='groupPermissionId'
                name='groupPermissionId'
                value={formik.values.groupPermissionId}
                options={permission}
                emptyMessage='Không có dữ liệu'
                placeholder='Chọn nhóm quyền'
                onChange={formik.handleChange}
                style={{ height: '40px' }}
                optionLabel='groupName'
                optionValue='id'
                className={`${
                  !formik.touched.groupPermissionId ||
                  !formik.errors.groupPermissionId
                    ? 'border-300'
                    : 'border-red-500'
                } flex align-items-center`}
                invalid={
                  formik.touched.groupPermissionId &&
                  formik.errors.groupPermissionId
                    ? true
                    : false
                }
              />
              {formik.touched.groupPermissionId &&
              formik.errors.groupPermissionId ? (
                <div style={{ color: 'red', marginTop: '3px' }}>
                  {formik.errors.groupPermissionId}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-full'>
              <label className='mb-2'>Ghi chú</label>
              <InputTextarea
                id='note'
                name='note'
                autoResize
                value={formik.values.note}
                onChange={formik.handleChange}
                style={{ height: '40px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ModelAddUser;
