import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getPlan, setDataNull2 } from "../../store/order/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {  formatNumber } from "../../components/utils";
import "../../assets/scss/theme-base/ModalAddPlan.css";

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function ModalAddPlan({ isShow, hiden, detail, productId,price  }) {
  const dispatch = useDispatch();
  const planData = useSelector(
    (state) => state.Production.planData,
    shallowEqual
  );

  useEffect(() => {
    if (isShow && productId) {
      dispatch(
        getPlan({ page: 1, limit: 100, productId, type: "production-plan" })
      );
    }
  }, [dispatch, isShow, productId]);

  const handleHide = () => {
    hiden();
    dispatch(setDataNull2());
  };

  const calculateValue = (rowData) => {
    return formatNumber(rowData.productionPlan * price); // Tính toán giá trị
  };

  return (
    <Dialog
      header="Sản lượng kế hoạch"
      visible={isShow}
      className="responsive-dialog"
      style={{ width: "30vw" }}
      onHide={handleHide}
    >
      <DataTable
      emptyMessage="Không có dữ liệu"
        value={planData?.data?.items || []}
        tableStyle={{ minWidth: "30vw" }}
      >
        <Column
          field="time"
          header="Ngày"
          style={{ height: "30px" }}
          body={(rowData) => formatDate(rowData.time)}
        ></Column>
        <Column
          field="productionPlan"
          header="SL kế hoạch"
          alignHeader={"center"}
          align={"center"}
        ></Column>
        <Column field="price" header="Giá trị" body={calculateValue}></Column>
      </DataTable>
    </Dialog>
  );
}

export default ModalAddPlan;
