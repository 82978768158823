import { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import '../Import/index.css';
import ModalProjectDeliveryNote2 from '../Teams/ModalProjectDeliveryNote2';
import { Button } from 'primereact/button';
import { site_frontend } from '../../api/url_helper';

Modal.setAppElement('#root');

const getOS = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.userAgentData?.platform || navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = 'Unknown';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};

const QualityReportPage = () => {
  const [isRearCamera, setIsRearCamera] = useState(true);
  const [os, setOS] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setOS(getOS());
  }, []);

  const toggleCamera = () => setIsRearCamera((prev) => !prev);

  // const handleScan = (data) => {
  //   console.log(data);
    
  //   if (data) {
  //     const url = new URL(data);
  //     const name = encodeURIComponent(url.searchParams.get('name'));
  //     const teamCode = encodeURIComponent(url.searchParams.get('teamCode'));

  //     navigate(`/ModalProjectDeliveryNote?name=${name}&teamCode=${teamCode}`);
  //   }
  // };

  const handleScan = (data) => {
    if (data) {
        
      try {
        const targetURL = `${site_frontend}/ModalProjectDeliveryNote?name=B%C3%A1o%20c%C3%A1o%20ch%E1%BA%A5t%20l%C6%B0%E1%BB%A3ng&teamCode=TDBCCL`;
        const url = new URL(data);
        
        if (url.href === targetURL) {
          // Nếu URL khớp, điều hướng đến trang mới
          navigate(`/ModalProjectDeliveryNote?name=${'Báo cáo chất lượng'}&teamCode=${'TDBCCL'}`);
        } else {
          // Báo lỗi nếu URL không khớp
          alert('URL không hợp lệ. Vui lòng quét QR báo cáo chất lượng.');
        }
      } catch (error) {
        // Báo lỗi nếu URL không hợp lệ hoặc không đúng định dạng
        alert('Quét không thành công, URL không hợp lệ.');
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    alert('Quét không thành công');
  };

  const handleHide = () => {
    // Xử lý khi modal bị đóng, ví dụ: điều hướng về một trang khác
    navigate('/report-construction');
  };

  return (
    <div
      className='card'
      style={{ minWidth: '400px' }}>
      {os === 'Mac OS' || os === 'Linux' || os === 'Windows'  ? (
        // Hiển thị trực tiếp ModalProjectDeliveryNote2
        <ModalProjectDeliveryNote2
          visible={true}
          onHide={handleHide}
        />
      ) : (
        // Hiển thị trực tiếp QR Reader nếu là iOS hoặc Android
        <div>
          <h2>Quét mã QR</h2>
          <QrReader
            onResult={handleScan}
            onError={handleError}
            // style={{ width: '100%', marginBottom: '10px' }}
            style={{
              width: '100%',
              height: '200px',
              marginBottom: '10px',
            }}
            constraints={{
              facingMode: isRearCamera ? 'environment' : 'user',
            }}
          />
          <div className='button-group flex justify-content-center'>
            <Button
              label='Chuyển camera'
              onClick={toggleCamera}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default QualityReportPage;
