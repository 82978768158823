import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ModelDelete from "../../components/ModalDelete";
import ModelAddConstruction from "./ModelAddConstruction";
import ModelEditConstruction from "./ModelEditConstruction";
import ActionMenu from "../../components/ActionMenu";
import {
    deleteConstruction,
    setDataNull,
} from "../../store/construction/slice";
import { getBuilding } from "../../store/order/slice";

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

function Construction() {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogEditVisible, setDialogEditVisible] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [rowData, setRowData] = useState();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [dataTable, setDataTable] = useState([]);
    const [valueSearch, setValueSearch] = useState("");
    const debouncedSearchTerm = useDebounce(valueSearch, 500);

    const { dataBuilding, deleteData, errorDelete, updateData, insertData } =
        useSelector(
            (state) => ({
                dataBuilding: state.Production.dataBuilding,
                deleteData: state.Construction.deleteData,
                errorDelete: state.Construction.errorDelete,
                updateData: state.Construction.updateData,
                insertData: state.Construction.insertData,
            }),
            shallowEqual
        );

    const listPermissions =
        JSON.parse(localStorage.getItem("userPermissions")) || {};

    useEffect(() => {
        dispatch(
            getBuilding({
                page: (first + rows) / rows,
                limit: rows,
                keyword: debouncedSearchTerm,
            })
        );
    }, [dispatch, first, rows, debouncedSearchTerm]);

    useEffect(() => {
        if (dataBuilding?.data?.items) {
            setDataTable(dataBuilding.data.items);
        }
    }, [dataBuilding]);

    useEffect(() => {
        if (deleteData || updateData || insertData) {
            dispatch(getBuilding({ page: (first + rows) / rows, limit: rows }));
            dispatch(setDataNull());
        }
    }, [deleteData, updateData, insertData, dispatch, first, rows]);

    const handleEdit = (rowData) => {
        setRowData(rowData);
        setDialogEditVisible(true);
    };

    const handleDelete = (rowData) => {
        setModalDelete(true);
        setRowData(rowData);
    };

    const acceptDelete = () => {
        if (rowData && rowData !== undefined) {
            const id = rowData.id;
            if (id !== undefined) {
                dispatch(deleteConstruction({ id, flag: true }));
            }
            setModalDelete(false);
        }
    };

    useEffect(() => {
        if (deleteData) {
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xóa người dùng thành công",
                life: 3000,
            });
            dispatch(setDataNull());
        }
    }, [dispatch, deleteData]);

    useEffect(() => {
        if (errorDelete) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorDelete.response.data.message,
                life: 3000,
            });
            dispatch(setDataNull());
        }
    }, [dispatch, errorDelete]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        dispatch(getBuilding({ page: event.page + 1, limit: event.rows }));
    };

    const actionBodyTemplate = (rowData) => {
        return listPermissions["Công trình"] &&
            listPermissions["Công trình"].includes("Sửa công trình") &&
            listPermissions["Công trình"].includes("Xóa công trình") ? (
            <ActionMenu
                onEdit={() => handleEdit(rowData)}
                onDelete={() => handleDelete(rowData)}
            />
        ) : (
            <></>
        );
    };

    const sttBodyTemplate = (rowData, { rowIndex }) => {
        return <span>{first + rowIndex + 1}</span>;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const templateTimeCreated = (rowData) => {
        return (
            <span>
                {rowData?.createdAt ? formatDate(rowData.createdAt) : ""}
            </span>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="flex flex-row justify-content-between mb-3">
                {listPermissions["Công trình"] &&
                    listPermissions["Công trình"].includes(
                        "Thêm công trình"
                    ) && (
                        <Button
                            label="Thêm mới"
                            size="small"
                            onClick={() => setDialogVisible(true)}
                        />
                    )}
                <IconField iconPosition="left" className="w-6">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText
                        placeholder="Tìm kiếm theo tên công trình"
                        className="w-full border-0"
                        value={valueSearch}
                        onChange={(e) => setValueSearch(e.target.value)}
                    />
                </IconField>
            </div>
            <div className="bg-white border-round-md shadow-1 p-3">
                <div>
                    <DataTable
                        value={dataTable}
                        rowGroupMode="subheader"
                        groupRowsBy="info.name_jp"
                        emptyMessage="Chưa có công trình"
                        scrollable
                        scrollHeight="600px"
                        size="small"
                    >
                        <Column
                            header="STT"
                            body={sttBodyTemplate}
                            style={{ width: "5%" }}
                            alignHeader={"center"}
                            align={"center"}
                        />
                        <Column
                            field="name"
                            header="Tên công trình"
                            style={{ minWidth: "150px" }}
                            // alignHeader={'center'}
                            // align={'center'}
                        />
                        <Column
                            field="buildingCode"
                            header="Mã công trình"
                            style={{ minWidth: "100px" }}
                            // alignHeader={'center'}
                            // align={'center'}
                        />
                        <Column
                            field="createdAt"
                            header="Ngày tạo"
                            style={{ minWidth: "120px" }}
                            body={templateTimeCreated}
                            alignHeader={"center"}
                            sortable
                            align={"center"}
                        />
                        <Column
                            field="description"
                            header="Ghi chú"
                            style={{ minWidth: "120px" }}
                        />
                        <Column
                            field="action"
                            body={actionBodyTemplate}
                            style={{ minWidth: "100px", height: "50px" }}
                        />
                    </DataTable>
                    <div className="card">
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={dataBuilding?.data?.totalItems || 0}
                            rowsPerPageOptions={[10, 20, 30]}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
            </div>
            <ModelAddConstruction
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
                toast={toast}
            />
            <ModelEditConstruction
                visible={dialogEditVisible}
                onHide={() => setDialogEditVisible(false)}
                toast={toast}
                rowData={rowData}
            />
            <ModelDelete
                visible={modalDelete}
                setVisible={setModalDelete}
                accept={acceptDelete}
                msg={
                    <div style={{ textAlign: "center" }}>
                        Bạn chắc chắn muốn xóa công trình này?
                        <br />
                        Các thông tin liên quan đến công trình sẽ bị xóa.
                    </div>
                }
            />
        </>
    );
}

export default Construction;
