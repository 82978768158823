import { call, put, takeEvery } from 'redux-saga/effects';
import { getConstruction, addNewConstruction, updateConstruction, deleteConstruction } from '../../api/backend_helper';
import {
  getDataAll,
  getDataAllFail,
  getDataAllSuccess,
  addConstruction,
  addConstructionFail,
  addConstructionSuccess,
  updateConstruction as updateConstructionAction,
  updateConstructionFail,
  updateConstructionSuccess,
  deleteConstructionFail,
  deleteConstructionSuccess,
} from './slice';

function* getConstructionData(action) {
  try {
    const query = action.payload;
    const data = yield call(getConstruction,query);
    yield put(getDataAllSuccess(data));
  } catch (error) {
    yield put(getDataAllFail(error.message));
  }
}

function* createConstructionSaga(action) {
  try {
    const data = yield call(addNewConstruction, action.payload);
    yield put(addConstructionSuccess(data));
  } catch (error) {
    yield put(addConstructionFail(error));
  }
}

function* modifyConstruction(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateConstruction, id, data);
    yield put(updateConstructionSuccess(response));
  } catch (error) {
    yield put(updateConstructionFail(error));
  }
}

function* removeConstruction({ payload }) {
  try {
    const response = yield call(deleteConstruction, payload.id, { flag: true });
    yield put(deleteConstructionSuccess(response));
  } catch (error) {
    yield put(deleteConstructionFail(error));
  }
}

function* ConstructionSaga() {
  yield takeEvery(getDataAll.type, getConstructionData);
  yield takeEvery(addConstruction.type, createConstructionSaga);
  yield takeEvery(updateConstructionAction.type, modifyConstruction);
  yield takeEvery('construction/deleteConstruction', removeConstruction);
}

export default ConstructionSaga;
