import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardTechnicalData: null,
    dashboardTechnicalDetailData: null,
    error: null,
    loading: false,
};

const dashboardTechnicalSlice = createSlice({
    name: "dashboardTechnical",
    initialState,
    reducers: {
        getDashboardTechnical: (state) => {
            state.loading = true;
        },
        getDashboardTechnicalSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalData = action.payload;
            state.error = null;
        },
        getDashboardTechnicalFail: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalData = null;
            state.error = action.payload;
        },
        getDashboardTechnicalDetail: (state) => {
            state.loading = true;
        },
        getDashboardTechnicalDetailSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalDetailData = action.payload;
            state.error = null;
        },
        getDashboardTechnicalDetailFail: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalDetailData = null;
            state.error = action.payload;
        },
    },
});

export const {
    getDashboardTechnical,
    getDashboardTechnicalSuccess,
    getDashboardTechnicalFail,
    getDashboardTechnicalDetail,
    getDashboardTechnicalDetailFail,
    getDashboardTechnicalDetailSuccess,
} = dashboardTechnicalSlice.actions;

export default dashboardTechnicalSlice.reducer;
