import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { addConstruction, setDataNull } from '../../store/construction/slice';
import '../../assets/scss/theme-base/ModelAddConstruction.css';
import { InputTextarea } from 'primereact/inputtextarea';

const ModelAddConstruction = ({ visible, onHide, toast }) => {
  const dispatch = useDispatch();

  const { errorInsert, insertData } = useSelector(
    (state) => ({
      errorInsert: state.Construction.errorInsert,
      insertData: state.Construction.insertData,
    }),
    shallowEqual
  );

  const constructionSchema = Yup.object().shape({
    buildingCode: Yup.string().required('This value is required'),
    name: Yup.string().required('This value is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      buildingCode: '',
      name: '',
      description: '',
    },
    validationSchema: constructionSchema,
    onSubmit: async (values) => {
      dispatch(addConstruction(values));
    },
  });

  useEffect(() => {
    if (insertData) {
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thêm thành công',
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      onHide();
    }
    if (errorInsert) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorInsert?.response?.data?.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [insertData, errorInsert]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      className='responsive-dialog'
      header='Thêm công trình'
      modal
      style={{ width: '450px', minHeight: '55%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            className='p-button-text'
          />
          <Button
            type='button' // Ensure type is specified to prevent form submission
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <div className='bg-white p-3 border-round-md'>
        <div className='flex flex-column mb-4 gap-3'>
          <div className='flex flex-column w-12'>
            <label className='mb-2'>Mã công trình</label>
            <InputText
              id='buildingCode'
              name='buildingCode'
              value={formik.values.buildingCode}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.buildingCode && formik.errors.buildingCode
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.buildingCode && formik.errors.buildingCode && (
              <div style={{ color: 'red' }}>{formik.errors.buildingCode}</div>
            )}
          </div>
          <div className='flex flex-column w-12 pt-4'>
            <label className='mb-2'>Tên công trình</label>
            <InputText
              id='name'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.name && formik.errors.name ? 'p-invalid' : ''
              }
            />
            {formik.touched.name && formik.errors.name && (
              <div style={{ color: 'red' }}>{formik.errors.name}</div>
            )}
          </div>
          <div className='flex flex-column w-12 py-4'>
            <label className='mb-2'>Ghi chú</label>
            <InputTextarea
              id='description'
              name='description'
              value={formik.values.description}
              onChange={formik.handleChange}
              autoResize
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModelAddConstruction;
