import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import './TimelineDemo.css'; // Đảm bảo rằng CSS của bạn được nhập đúng
import ModalDv from './ModalDv';
import { Button } from 'primereact/button';
import {
  getTeamProgress,
  resetTeamProgress,
  resetTeamChildren,
  getDViFull,
} from '../../store/order/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { formatDate2 } from '../../components/utils';
import { Image } from 'primereact/image';
import '../../assets/scss/theme-base/Intersection.css';
import { generatePdf } from './generatePdf';
import AddLocationPopup from './AddLocationPopup';
import { setDataNull2 } from '../../store/teams/slice';
import ModalAssignment from './ModalAssignment';
import ModalAssignment2 from './ModalAssignment2';

const Intersection = ({ visible, onHide, factoryCode,teamWorking,category }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [visibleModalDv, setVisibleModalDv] = useState(false);
  const [selectedEventName, setSelectedEventName] = useState('');
  const [visibleAddLocationPopup, setVisibleAddLocationPopup] = useState(false);
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const { dataTeamProgress, events, teamChildrenData ,dataDVi,addQrTeamData} = useSelector(
    (state) => ({
      dataTeamProgress: state.Production.dataTeamProgress,
      events: state.Production.dataTeamProgress?.data?.items,
      teamChildrenData: state.Production.teamChildrenData,
      dataDVi: state.Production.dataDVi,
      addQrTeamData: state.Teams.addQrTeamData,
    }),
    shallowEqual
  );

  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getTeamProgress({ id: factoryCode }));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, factoryCode]);

  useEffect(() => {
    if (addQrTeamData) {
      dispatch(getTeamProgress({ id: factoryCode }));
      dispatch(setDataNull2());
    }
  }, [dispatch, addQrTeamData]);

  const handlePrintPdf = async () => {
    if (factoryCode) {
      await dispatch(getDViFull({ id: factoryCode }));
    }
  };

  useEffect(() => {
    if (dataDVi && dataDVi.data) {
      generatePdf(dataDVi);
    }
  }, [dataDVi]);

  const customizedContent = (item) => {
    const handleDetailClick = (eventName) => {
      setSelectedEventName(eventName);
      setVisibleModalDv(true);
    };

    
    

    return (
      <Card title={item.name}>
        {item.Productions.map((production, index) => {
          const timeStart = production.timeStart;
          const timeEnd = production.timeEnd;

          return (
            <div key={index}>
              <p>
                {item === events[0]
                  ? `Thời gian bắt đầu: ${formatDate2(timeStart)}`
                  : `Thời gian: ${
                      timeEnd
                        ? `${formatDate2(timeStart)} - ${formatDate2(timeEnd)}`
                        : `${formatDate2(timeStart)} - đang làm`
                    }`}
              </p>
              <p className='pt-3'>
                Số lượng:{' '}
                {parseInt(item.totalEnd) !== 0
                  ? item.totalEnd
                  : item.totalStart}
              </p>
              {production.images && (
                <div className='image-container pt-3'>
                  {production.images.map((image, imgIndex) => (
                    <Image
                      key={imgIndex}
                      src={image}
                      alt={item.name}
                      preview
                    />
                  ))}
                </div>
              )}
              <p className='mt-2'>{production.note}</p>
            </div>
          );
        })}
        {item !== events[0] && (
          <Button
            label='Chi tiết'
            className='p-button-text'
            onClick={() => handleDetailClick(item.name)}></Button>
        )}
      </Card>
    );
  };

  const handleModalDvHide = () => {
    setVisibleModalDv(false);
    dispatch(resetTeamChildren());
  };

  // const handlePrintPdf = () => {
  //   generatePdf(events);
  // };

  const customizedMarker = (item) => {
    return (
      <span
        className='flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1'
        style={{ backgroundColor: 'green' }}>
        <i className={item.icon}></i>
      </span>
    );
  };

  const handleAddLocationClick = () => {
    setVisibleAddLocationPopup(true);
  };
  const reversedEvents = Array.isArray(events) ? [...events].reverse() : [];
    

  return (
    <>
    <Dialog
      visible={visible}
      onHide={() => {
        dispatch(resetTeamProgress());
        onHide();
      }}
      footer={
        <div className='flex justify-content-between'>
        <Button
          label='Thêm định vị'
          type="button"
          onClick={handleAddLocationClick}
        />
        <Button
              label='Phân công sản xuất'
              onClick={() => setShowAssignmentModal(true)}
            />
        <Button
          label='In PDF'
          icon='pi pi-print'
          severity='danger'
          size='small'
          outlined
          onClick={handlePrintPdf}
        />
        
        
        </div>
      }
      className='responsive-dialog'
      header='Định vị'
      modal
      style={{ width: '48%', height: '90%' }}>
      <>
        <div className='card'>
          <Timeline
            value={reversedEvents}
            align='alternate'
            className='customized-timeline'
            marker={customizedMarker}
            content={customizedContent}
          />
        </div>
      </>
      {visibleModalDv && (
        <ModalDv
          visible={visibleModalDv}
          onHide={handleModalDvHide}
          factoryCode={factoryCode}
          name={selectedEventName}
        />
      )}
    </Dialog>
    {visibleAddLocationPopup && (
    <AddLocationPopup
    factoryCode={factoryCode}
    visible={visibleAddLocationPopup}
    onHide={() => setVisibleAddLocationPopup(false)}
  />
  )}
  <ModalAssignment2
        isShow={showAssignmentModal}
        onHide={() => setShowAssignmentModal(false)}
        factoryCode={factoryCode}
      />
</>
  );
};

export default Intersection;
