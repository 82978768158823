import { useRef, useState, useContext, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Menu } from 'primereact/menu';
import { Image } from 'primereact/image';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import { getByUserId, logout } from '../../store/auth/slice.js';
import logo from '../../assets/images/logo.png';
import { DataContext } from '../../contexts/data/DataProvider';

function Header() {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const accountMenu = useRef(null);

  const { titlePage } = useContext(DataContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = localStorage.getItem('userId');

  const { dataByUserId } = useSelector(
    (state) => ({
      dataByUserId: state.Auth.dataByUserId,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch(getByUserId({ userId: id }));
    }
  }, [id, dispatch]);

  const profileMenuItems = [
    {
      template: () => <></>,
    },
    {
      label: 'Tài khoản',
      items: [
        // {
        //     label: "Mật khẩu",
        //     icon: "pi pi-info-circle",
        //     command: async () => {
        //         navigate("/account");
        //     },
        // },
        {
          label: 'Đăng xuất',
          icon: 'pi pi-sign-out',
          command: async () => {
            dispatch(logout());
            navigate('/');
          },
        },
      ],
    },
  ];

  return (
    <>
      <div className='flex align-items-center justify-content-between bg-white h-4rem px-3 lg:px-4'>
        <div className='flex flex-row align-items-center'>
          <div onClick={() => setMobileMenuVisible(true)}>
            <i
              className='pi pi-bars mx-2 cursor-pointer xl:hidden'
              style={{ fontSize: '1.5rem' }}></i>
          </div>
          <h3>{titlePage}</h3>
        </div>
        <div className='flex flex-row align-items-center'>
          <div
            className='flex align-items-center cursor-pointer p-3 gap-2 border-round text-700'
            onClick={(event) => accountMenu.current.toggle(event)}>
            <Menu
              model={profileMenuItems}
              popup
              ref={accountMenu}
              className='w-auto'
              pt={{ menuitem: 'mx-2' }}
            />
            <div className='flex flex-column align-items-end gap-1'>
              <span className='font-bold text-primary'>
                {dataByUserId?.data?.items[0]?.name}
              </span>
              <span className='text-sm font-bold'>
                {dataByUserId?.data?.items[0]?.email}
              </span>
            </div>
            <div className='text-primary'>
              <i className='pi pi-sort-down-fill'></i>
            </div>
          </div>
        </div>
      </div>

      {/* SidebarMenu Mobile */}
      <div className='card flex justify-content-center overflow-visible'>
        <Sidebar
          header={
            <div className='ml-2 flex justify-content-between align-items-center'>
              <Image
                src={logo}
                alt='Image'
                width='60'
              />
              <span className='ml-3 font-bold'>NEM FURNITURE</span>
            </div>
          }
          visible={mobileMenuVisible}
          onHide={() => setMobileMenuVisible(false)}>
          <SidebarMenu
            mobile={mobileMenuVisible}
            setMobileMenuVisible={setMobileMenuVisible}
          />
        </Sidebar>
      </div>
    </>
  );
}
export default Header;
