import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LoadingSpinner from "../Report/LoadingSpinner";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { FilterMatchMode } from "primereact/api";
import { Paginator } from "primereact/paginator";
import { imageTemplate } from "../Report/ProductInprogress";

const Complete = ({ productIncompleteFactory, onPaginationChange }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };
    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
        if (onPaginationChange) {
            onPaginationChange(newFirst, newRows);
        }
    };

    return (
        <div className="p-3 bg-white border-round-lg h-full">
            <p
                style={{
                    color: "#5c5c5c",
                    fontWeight: "700",
                    marginBottom: "2rem",
                }}
            >
                Hoàn thành
            </p>
            {productIncompleteFactory && productIncompleteFactory.data ? (
                <div>
                    <DataTable
                        value={productIncompleteFactory?.data?.items}
                        rowClassName="custom-row-class-factory"
                        emptyMessage="Không có dữ liệu"
                        // paginator
                        // rows={10}
                        // rowsPerPageOptions={[10, 25, 50, 100, 500]}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        scrollable
                        scrollHeight="70vh"
                    >
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Hạng mục"
                            field="categoryName"
                            style={{ lineHeight: "1.5", minWidth: "150px" }}
                            filter
                        />
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ minWidth: "150px" }}
                            filter
                        />
                        <Column
                            header="Hình ảnh"
                            style={{ minWidth: "100px" }}
                            body={imageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ minWidth: "100px" }}
                            filter
                        />
                        <Column
                            header="Số lượng"
                            field="quantity"
                            style={{ minWidth: "50px" }}
                        />
                        <Column
                            header="ĐVT"
                            field="unit"
                            style={{ minWidth: "50px" }}
                            filter
                        />
                        <Column
                            header="Định vị"
                            alignHeader={"center"}
                            align={"center"}
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                        icon="pi pi-map-marker"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="Bookmark"
                                        style={{ color: "black" }}
                                    />
                                    <p>{rowData.teamWorking}</p>
                                </div>
                            )}
                            style={{ minWidth: "100px" }}
                        />
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={
                            productIncompleteFactory?.data?.totalItems
                        }
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    <Intersection
                        visible={dialogLocationVisible}
                        onHide={() => setDialogLocationVisible(false)}
                    />
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default Complete;
