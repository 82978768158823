import { call, put, takeEvery } from "redux-saga/effects";
import {
    getDashboardTeamsSuccess,
    getDashboardTeamsFail,
    getDashboardTeamsDetailFail,
    getDashboardTeamsDetailSuccess,
    getDashboardTeamsWorkingFail,
    getDashboardTeamsWorkingSuccess,
    downloadExcelTeamFail,
    downloadExcelTeamSuccess,
} from "./slice";
import {
    getDashboardTeams,
    getDashboardTeamsDetail,
    getDashboardTeamsWorking,
    exportExcelTeam,
} from "../../api/backend_helper";

function* getDashboardTeamsSaga(action) {
    try {
        const {
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
        } = action.payload;
        const data = yield call(getDashboardTeams, {
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
        });
        yield put(getDashboardTeamsSuccess(data));
    } catch (error) {
        yield put(getDashboardTeamsFail(error.message));
    }
}

function* getDashboardTeamsDetailSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            team,
            status,
        } = action.payload;
        const data = yield call(getDashboardTeamsDetail, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            team,
            status,
        });
        yield put(getDashboardTeamsDetailSuccess(data));
    } catch (error) {
        yield put(getDashboardTeamsDetailFail(error.message));
    }
}

function* getDashboardTeamsWorkingSaga(action) {
    const {
        page,
        limit,
        timeStartReq,
        timeEndReq,
        building,
        orderCode,
        category,
        factoryCode,
    } = action.payload;
    const data = yield call(getDashboardTeamsWorking, {
        page,
        limit,
        timeStartReq,
        timeEndReq,
        building,
        orderCode,
        category,
        factoryCode,
    });
    yield put(getDashboardTeamsWorkingSuccess(data));
    try {
    } catch (error) {
        yield put(getDashboardTeamsWorkingFail(error.message));
    }
}

function* downloadExcelTeamSaga(action) {
    try {
        const data = yield call(exportExcelTeam, action.payload);
        yield put(downloadExcelTeamSuccess(data));
    } catch (error) {
        yield put(downloadExcelTeamFail(error));
    }
}

function* DashboardTeams() {
    yield takeEvery("dashboardTeams/getDashboardTeams", getDashboardTeamsSaga);
    yield takeEvery(
        "dashboardTeams/getDashboardTeamsDetail",
        getDashboardTeamsDetailSaga
    );
    yield takeEvery(
        "dashboardTeams/getDashboardTeamsWorking",
        getDashboardTeamsWorkingSaga
    );
    yield takeEvery("dashboardTeams/downloadExcelTeam", downloadExcelTeamSaga);
}

export default DashboardTeams;
