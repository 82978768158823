import React from 'react';
import { Badge } from 'primereact/badge';

const tabMenuTemplate = (item, itemIndex, tabMenuActive, setTabmenuActive) => (
  <div
    className='p-menuitem-link flex align-items-center gap-2 cursor-pointer '
    onClick={() => setTabmenuActive(itemIndex)}
    style={{
      width: window.innerWidth <= 768 ? '8rem' : 'auto',
      cursor: 'pointer',
    }}>
    <span
      className={`white-space-nowrap overflow-hidden ${
        tabMenuActive === itemIndex ? 'font-bold text-primary' : ''
      }`}>
      {item.label}
    </span>
    <Badge
      value={item.count}
      style={{
        backgroundColor: tabMenuActive === itemIndex ? '#4880FF' : '#999999',
      }}></Badge>
  </div>
);

export default tabMenuTemplate;
