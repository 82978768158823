import { call, put, takeEvery } from "redux-saga/effects";

import { getListStatusSuccess, getListStatusFail } from "./slice";
import { getListStatus } from "../../api/backend_helper";

function* getListStatusSaga() {
    try {
        const data = yield call(getListStatus);
        yield put(getListStatusSuccess(data));
    } catch (error) {
        yield put(getListStatusFail(error.messagge));
    }
}

function* Product() {
    yield takeEvery("product/getListStatus", getListStatusSaga);
}

export default Product;
