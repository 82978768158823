import React, { useMemo, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { QRCode } from 'react-qrcode-logo';
import '../../assets/scss/theme-base/Qr.css';
import { site_frontend } from '../../api/url_helper';
import SHA256 from 'crypto-js/sha256'; // Import thư viện crypto-js
import encHex from 'crypto-js/enc-hex'; // Import encoder cho output hex

function QR({ isShow, hiden, detail }) {
  const dialogRef = useRef(null);

  const generateQRCodeValue = useMemo(() => {
    if (detail) {
      const { factoryCode, categoryName, quantity } = detail;
      // Mã hóa categoryName sử dụng SHA-256
      const hashedCategoryName = SHA256(categoryName).toString(encHex);
      return `${site_frontend}/locate1?factoryCode=${encodeURIComponent(
        factoryCode
      )}`;
    }
    return `${site_frontend}/locate1`;
  }, [detail]);

  const downloadQRCode = () => {
    const canvas = document.getElementById('qr-code-canvas');
    const url = canvas.toDataURL('image/png');
    const a = document.createElement('a');
    a.href = url;
    a.download = 'qrcode.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const printfQRCode = () => {
    const printStyle = document.createElement('style');
    printStyle.innerHTML = `
      @media print {
        #qr-code-canvas {
          width: 100% !important;
          height: auto !important;
          max-width: 1000px !important;
          max-height: 1000px !important;
          margin-bottom: 200px !important;
          margin-top: 200px !important;
        }
        .p-dialog {
          width: 100vw !important;
          height: 100vh !important;
          max-width: none !important;
          max-height: none !important;
        }
        .hide-on-print {
          display: none;
        }
        .p-dialog-header {
          text-align: center;
        }
        .p-dialog-header .p-dialog-header-icon {
          display: none !important;
        }
        body {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .printfs {
          margin-top: 20px !important;
          font-size: 80px !important;
        }
          .headers{
          font-size: 80px !important;
          margin-bottom: 20px !important;
          }
      }
    `;
    document.head.appendChild(printStyle);

    const printContainer = document.createElement('div');
    printContainer.id = 'print-container';
    const qrCanvas = document.getElementById('qr-code-canvas').cloneNode(true);
    printContainer.appendChild(qrCanvas);
    document.body.appendChild(printContainer);

    window.print();

    document.body.removeChild(printContainer);
    document.head.removeChild(printStyle);
  };

  return (
    <Dialog
      visible={isShow}
      style={{ width: '20vw', minWidth: '300px', minHeight: '300px' }}
      className='responsive-dialog'
      onHide={hiden}
      ref={dialogRef}>
      <div className='headers' style={{ textAlign: 'center', fontWeight: 'bold', lineHeight: '1.5' }}>
        NEM FURNITURE <br /> CÔNG TY CỔ PHẦN NỘI THẤT NEM
      </div>
      <div
        className='flex justify-content-center align-items-center flex-column gap-3'
        style={{ padding: '20px' }}>
        <QRCode
          id='qr-code-canvas'
          value={generateQRCodeValue}
          size={200}
          logoWidth={50}
          logoHeight={50}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}>
          <span
            className='printfs '
            style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
            Mã CT : {detail?.Building?.buildingCode || 'N/A'}
          </span>
          <span className='printfs'>Mã ĐH : {detail?.orderCode || 'N/A'}</span>
          <span className='printfs'>
            Hạng mục : {detail?.categoryName || 'N/A'}
          </span>
        </div>
      </div>
      <div className='text-center mt-4'>
        <button
          className='p-button p-button-outlined hide-on-print mr-3'
          onClick={downloadQRCode}>
          Tải mã
        </button>
        <button
          className='p-button p-button-outlined hide-on-print'
          onClick={printfQRCode}>
          In mã
        </button>
      </div>
    </Dialog>
  );
}

export default QR;
