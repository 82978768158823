import FormatDate from "../Report/FormatDate";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "html2canvas";
import logo from "../../assets/images/logo.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getBase64Image = (img) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(img);
    });
};
const fetchImageAsBase64 = async (imagePath) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const base64Image = await getBase64Image(blob);
    return base64Image;
};

export const exportTeamPDF = async (
    action,
    startDate,
    endDate,
    productInprogress,
    dataDetail,
    buildingName
) => {
    const imagePath = logo;

    const logoBase64 = await fetchImageAsBase64(imagePath);
    const columnChartElement = document.getElementById("column-chart");
    const captureElementAsImage = async (element) => {
        if (!element) return null;
        const canvas = await html2canvas(element);
        return canvas.toDataURL("image/png");
    };

    const [columnChartImgData] = await Promise.all([
        captureElementAsImage(columnChartElement),
    ]);
    const docDefinition = {
        pageSize: { width: 1400, height: 900 },
        pageMargins: [40, 60, 40, 60],
        content: [
            {
                image: logoBase64,
                width: 40,
                absolutePosition: { x: 40, y: 50 },
            },
            { text: buildingName, absolutePosition: { x: 100, y: 60 } },
            {
                text: "Báo cáo tổ đội",
                style: "header",
                fontSize: 28,
                margin: [0, 0, 0, 20],
            },
            {
                text: `Từ ngày: ${startDate} ~ Đến ngày: ${endDate}`,
                alignment: "center",
                fontSize: 14,
                margin: [0, 0, 0, 0],
            },
            {
                image: columnChartImgData,
                margin: [0, 0, 0, 20],
                alignment: "center",
            },
            {
                text: "Đang thực hiện",
                style: "subheader",
                margin: [0, 0, 0, 5],
                bold: true,
                fontSize: 14,
                alignment: "center",
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["auto", 300, 300, 100],
                    body: [
                        [
                            {
                                text: "STT",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Tổ đội",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Mã nhà máy",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Định vị",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                        ],
                        ...productInprogress?.map((product, index) => [
                            { text: index + 1, alignment: "center" },
                            {
                                text: product.name,
                            },
                            { text: product.factoryCode },
                            { text: product.teamWorking },
                        ]),
                    ],
                },

                margin: [300, 0, 0, 5],
            },
            {
                text: "Chi tiết tổ đội sản xuất",
                style: "subheader",
                pageBreak: "before",
                margin: [0, 0, 0, 5],
                bold: true,
                fontSize: 14,
                alignment: "center",
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["auto", 150, 200, 100, 100, 100, 50, 50, 200, 100],
                    body: [
                        [
                            {
                                text: "STT",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Công trình",

                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Hạng mục",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Mã nhà máy",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Từ ngày",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Đến ngày",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "SL",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "ĐVT",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Trạng thái",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Định vị",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                        ],
                        ...dataDetail?.map((product, index) => [
                            { text: index + 1, alignment: "center" },
                            {
                                text: product.buildingName,
                            },
                            { text: product.categoryName },
                            { text: product.factoryCode },
                            {
                                text: FormatDate(product.timeStart),
                            },
                            {
                                text: FormatDate(product.timeEnd),
                            },
                            { text: product.quantityMade },
                            { text: product.unit },
                            { text: product.status },
                            { text: product.teamWorking },
                        ]),
                    ],
                },
                margin: [100, 0, 0, 5],
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
            },
        },
        defaultStyle: {
            font: "Roboto",
        },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (action === "print") {
        pdfDocGenerator.getBlob((blob) => {
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, "_blank");
            if (newWindow) {
                newWindow.onload = () => {
                    newWindow.print();
                };
            }
        });
    } else if (action === "save") {
        pdfDocGenerator.download("quanity.pdf");
    }
};
