import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  getPermission,
  setPermissionNull,
  updateGroupPermission,
} from '../../store/permission/slice';
import { InputTextarea } from 'primereact/inputtextarea';

const ModelEditPermission = ({ visible, onHide, toast, rowData }) => {
  const dispatch = useDispatch();
  const { groupPermissionUpdate, groupPermissionUpdateError } = useSelector(
    (state) => ({
      groupPermissionUpdate: state.Permission.groupPermissionUpdate,
      groupPermissionError: state.Permission.groupPermissionError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: rowData?.groupName || '',
      description: rowData?.description || '',
    },
    validationSchema: Yup.object().shape({
      groupName: Yup.string().required('Tên nhóm quyền không được để trống'),
    }),
    onSubmit: (value) => {
      dispatch(updateGroupPermission({ id: rowData.id, data: value }));
    },
  });

  useEffect(() => {
    if (groupPermissionUpdate) {
      toast.current?.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Cập nhật nhóm quyền thành công',
        life: 3000,
      });
      onHide();
      formik.resetForm();
      dispatch(setPermissionNull());
      dispatch(getPermission({ page: 1, limit: 20 }));
    }
  }, [groupPermissionUpdate]);

  useEffect(() => {
    if (groupPermissionUpdateError) {
      toast.current?.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: groupPermissionUpdateError.response.data.message,
        life: 3000,
      });
      dispatch(setPermissionNull());
    }
  }, [groupPermissionUpdateError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      header='Chỉnh sửa nhóm quyền'
      modal
      style={{ width: '400px', height: '55%' }}
      footer={
        <div>
          <Button
            label='Hủy'
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            severity='secondary'
            className='p-button-text'
          />
          <Button
            type='submit'
            label='Lưu'
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <div className='bg-white  border-round-md'>
        <div className='flex flex-column gap-3'>
          <div className='flex flex-column w-12'>
            <label className='mb-2'>Tên nhóm quyền</label>
            <InputText
              id='groupName'
              name='groupName'
              value={formik.values.groupName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.groupName && formik.errors.groupName
                  ? true
                  : false
              }
            />
            {formik.touched.groupName && formik.errors.groupName ? (
              <div style={{ color: 'red' }}>
                {formik.errors.groupName.toString()}
              </div>
            ) : null}
          </div>
          <div className='flex flex-column w-12 py-2'>
            <label className='mb-2'>Ghi chú</label>
            <InputTextarea
              id='description'
              name='description'
              autoResize
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModelEditPermission;
