import React, { useContext, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Image } from 'primereact/image';
import logo from '../../assets/images/logo.png';
import { DataContext } from '../../contexts/data/DataProvider';

const SidebarMenu = ({ mobile, setMobileMenuVisible }) => {
  const location = useLocation();
  const listPermissions =
    JSON.parse(localStorage.getItem('userPermissions')) || {};
  const { setTitlePage } = useContext(DataContext);

  const BaoCao =
    listPermissions['Công trình'] ||
    listPermissions['Kỹ thuật'] ||
    listPermissions['Kế hoạch'] ||
    listPermissions['Sản lượng'] ||
    listPermissions['Công trình'] ||
    listPermissions['Nhà máy'];

  const ThaoTac =
    listPermissions['Kế hoạch'] ||
    listPermissions['Kỹ thuật'] ||
    listPermissions['Sản xuất'] ||
    listPermissions['Sản lượng'];

  const DanhMuc = listPermissions['Công trình'] || listPermissions['Tổ đội'];

  const NhapXuat =
    listPermissions['Sản xuất']?.includes('Nhập sản xuất') ||
    listPermissions['Kế hoạch']?.includes('Giao công trình');

  const KeHoach = [
    'Thêm thông tin',
    'Sửa thông tin',
    'Xóa thông tin',
    'Xem thông tin',
  ];

  const CongTrinh = [
    'Thêm công trình',
    'Sửa công trình',
    'Xóa công trình',
    'Xem thông tin',
  ];

  const NguoiDung = [
    'Thêm người dùng',
    'Sửa người dùng',
    'Xóa người dùng',
    'Xem thông tin',
  ];

  const SanXuat = [
    'Xem kế hoạch',
    'Xem thông tin',
    'Sửa kế hoạch',
    'Xóa kế hoạch',
    'Thêm kế hoạch',
  ];

  const ToDoi = ['Xem thông tin', 'Thêm tổ đội', 'Sửa tổ đội', 'Xóa tổ đội'];

  const KyThuat = ['Cập nhật thông tin', 'Xem thông tin'];

  // function to check if user has permission to access the operation
  const checkPermission = (permission, operation) =>
    operation.some((value) => listPermissions[permission]?.includes(value));

  // check if nothing in menu selected
  useEffect(() => {
    const currentItem = findCurrentItem(location.pathname);
    if (currentItem) {
      setTitlePage(currentItem.label);
    } else {
      setTitlePage('Mật khẩu');
    }
  }, [location.pathname]);

  const findCurrentItem = (pathname) => {
    for (const category of items) {
      for (const item of category.items || []) {
        if (item.url === pathname) {
          return item;
        }
      }
    }
    return null;
  };

  const itemRenderer = (item) => {
    const isSelected = location.pathname === item.url;

    return (
      <Link
        to={item.url}
        className={`flex align-items-center hidden p-menuitem-content p-menuitem-link border-round-md xl:ml-3 xl:mr-3 p-3 ${
          isSelected ? 'text-white-alpha-90 bg-primary' : ''
        }`}
        onClick={mobile ? () => setMobileMenuVisible(false) : () => {}}>
        <span className={item.icon} />
        <span className='mx-3 white-space-nowrap'>{item.label}</span>
      </Link>
    );
  };

  const items = useMemo(() => {
    const generateItems = () => {
      const baseItems = [
        BaoCao && {
          label: 'Báo cáo',
          items: [
            listPermissions['Nhà máy']?.includes('Xem báo cáo') && {
              label: 'Báo cáo nhà máy',
              icon: 'pi pi-chart-bar',
              url: '/report-factory',

              template: itemRenderer,
            },
            listPermissions['Kỹ thuật']?.includes('Xem báo cáo') && {
              label: 'Báo cáo kỹ thuật',
              icon: 'pi pi-wrench',
              url: '/report-technical',
              template: itemRenderer,
            },
            listPermissions['Kế hoạch']?.includes('Xem báo cáo') && {
              label: 'Báo cáo kế hoạch',
              icon: 'pi pi-calendar',
              url: '/report-plan',
              template: itemRenderer,
            },
            listPermissions['Sản lượng']?.includes('Xem báo cáo') && {
              label: 'Báo cáo sản lượng',
              icon: 'pi pi-dollar',
              url: '/report-quantity',
              template: itemRenderer,
            },
            listPermissions['Tổ đội']?.includes('Xem báo cáo') && {
              label: 'Báo cáo tổ đội',
              icon: 'pi pi-users',
              url: '/report-team',
              template: itemRenderer,
            },
            listPermissions['Công trình']?.includes('Xem báo cáo') && {
              label: 'Báo cáo công trình',
              icon: 'pi pi-building',
              url: '/report-construction',
              template: itemRenderer,
            },
            NhapXuat && {
              label: 'Báo cáo chất lượng',
              icon: 'pi pi-arrow-right',
              url: '/QualityReportPage',
              template: itemRenderer,
            },
            
          ].filter(Boolean),
        },
        ThaoTac && {
          label: 'Thao tác',
          items: [
            checkPermission('Kế hoạch', KeHoach) && {
              label: 'Kế hoạch',
              icon: 'pi pi-calendar',
              url: '/plan',
              template: itemRenderer,
            },
            checkPermission('Kỹ thuật', KyThuat) && {
              label: 'Kỹ thuật',
              icon: 'pi pi-wrench',
              url: '/technique',
              template: itemRenderer,
            },
            checkPermission('Sản xuất', SanXuat) && {
              label: 'Sản xuất',
              icon: 'pi pi-list-check',
              url: '/production',
              template: itemRenderer,
            },
            checkPermission('Sản lượng', KyThuat) && {
              label: 'Sản lượng',
              icon: 'pi pi-dollar',
              url: '/quantity',
              template: itemRenderer,
            },
            NhapXuat && {
              label: 'Nhập xuất',
              icon: 'pi pi-arrow-right',
              url: '/import',
              template: itemRenderer,
            },
            NhapXuat && {
              label: 'Giao công trình',
              icon: 'pi pi-arrow-right',
              url: '/importconstruction',
              template: itemRenderer,
            },
          ].filter(Boolean),
        },
        DanhMuc && {
          label: 'Danh mục',
          items: [
            checkPermission('Công trình', CongTrinh) && {
              label: 'Danh sách công trình',
              icon: 'pi pi-building',
              url: '/construction',
              template: itemRenderer,
            },
            checkPermission('Tổ đội', ToDoi) && {
              label: 'Danh sách tổ đội',
              icon: 'pi pi-users',
              url: '/teams',
              template: itemRenderer,
            },
          ].filter(Boolean),
        },
        listPermissions['Người dùng'] && {
          label: 'Người dùng',
          items: [
            checkPermission('Người dùng', NguoiDung) && {
              label: 'Danh sách người dùng',
              icon: 'pi pi-user',
              url: '/users',
              template: itemRenderer,
            },
            listPermissions['Người dùng'].includes('Phân quyền') && {
              label: 'Phân quyền',
              icon: 'pi pi-check-square',
              url: '/permission',
              template: itemRenderer,
            },
          ].filter(Boolean),
        },
      ].filter(Boolean);

      return baseItems.filter(
        (section) => section.items && section.items.length > 0
      );
    };

    return generateItems();
  }, [checkPermission, listPermissions]);

  return (
    <div className='flex flex-column h-screen border-gray-200 xl:pb-5'>
      <div className='hidden xl:block text-center p-3'>
        <Image
          src={logo}
          alt='Logo'
          width='80'
        />
      </div>
      <Menu
        model={[{ template: () => <></> }, ...items]}
        className='w-full xl:w-18rem border-noround xl:overflow-auto border-none'
      />
    </div>
  );
};

export default SidebarMenu;
