import { useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { login } from '../../store/auth/slice';

export default function Login() {
  const toast = useRef(null);
  const dispatch = useDispatch();

  const { dataLogin, errorLogin } = useSelector(
    (state) => ({
      dataLogin: state.Auth.dataLogin,
      errorLogin: state.Auth.errorLogin,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Tên đăng nhập không được để trống'),
      password: Yup.string().required('Mật khẩu không được để trống'),
    }),
    onSubmit: async (values) => {
      dispatch(login(values));
    },
  });

  useEffect(() => {
    if (errorLogin) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: `Đăng nhập thất bại. ${errorLogin.message}`,
        life: 3000,
        position: 'top-left',
      });
    }
  }, [errorLogin]);

  const KeHoach = [
    'Thêm thông tin',
    'Sửa thông tin',
    'Xóa thông tin',
    'Xem thông tin',
  ];

  const CongTrinh = [
    'Thêm công trình',
    'Sửa công trình',
    'Xóa công trình',
    'Xem thông tin',
  ];

  const NguoiDung = [
    'Thêm người dùng',
    'Sửa người dùng',
    'Xóa người dùng',
    'Xem thông tin',
  ];

  const SanXuat = [
    'Xem kế hoạch',
    'Xem thông tin',
    'Sửa kế hoạch',
    'Xóa kế hoạch',
    'Thêm kế hoạch',
  ];

  const ToDoi = ['Xem thông tin', 'Thêm tổ đội', 'Sửa tổ đội', 'Xóa tổ đội'];

  const KyThuat = ['Cập nhật thông tin', 'Xem thông tin'];

  // function to check if user has permission to access the operation
  const checkPermission = (permission, operation) => {
    if (dataLogin.listPermissions[permission]) {
      operation.some((value) =>
        dataLogin.listPermissions[permission].includes(value)
      );
    }
  };

  useEffect(() => {
    if (dataLogin) {
      if (dataLogin?.listPermissions['Nhà máy']?.includes('Xem báo cáo')) {
        window.location.href = '/report-factory';
        return;
      } else if (
        dataLogin?.listPermissions['Kỹ thuật']?.includes('Xem báo cáo')
      ) {
        window.location.href = '/report-technical';
        return;
      } else if (
        dataLogin?.listPermissions['Kế hoạch']?.includes('Xem báo cáo')
      ) {
        window.location.href = '/report-plan';
        return;
      } else if (
        dataLogin?.listPermissions['Sản lượng']?.includes('Xem báo cáo')
      ) {
        window.location.href = '/report-quantity';
        return;
      } else if (
        dataLogin?.listPermissions['Tổ đội']?.includes('Xem báo cáo')
      ) {
        window.location.href = '/report-team';
        return;
      } else if (
        dataLogin?.listPermissions['Công trình']?.includes('Xem báo cáo')
      ) {
        window.location.href = '/report-construction';
        return;
      } else if (checkPermission('Kế hoạch', KeHoach)) {
        window.location.href = '/plan';
        return;
      } else if (checkPermission('Kỹ thuật', KyThuat)) {
        window.location.href = '/technique';
        return;
      } else if (checkPermission('Sản xuất', SanXuat)) {
        window.location.href = '/production';
        return;
      } else if (checkPermission('Sản lượng', KyThuat)) {
        window.location.href = '/quantity';
        return;
      } else if (
        dataLogin.listPermissions['Sản xuất']?.includes('Nhập sản xuất') ||
        dataLogin.listPermissions['Kế hoạch']?.includes('Giao công trình')
      ) {
        window.location.href = '/import';
        return;
      } else if (checkPermission('Công trình', CongTrinh)) {
        window.location.href = '/construction';
        return;
      } else if (checkPermission('Tổ đội', ToDoi)) {
        window.location.href = '/teams';
        return;
      } else if (checkPermission('Người dùng', NguoiDung)) {
        window.location.href = '/users';
        return;
      } else if (
        dataLogin.listPermissions['Kế hoạch']?.includes('Phân quyền')
      ) {
        window.location.href = '/permission';
        return;
      } else window.location.href = '/account';
    }
  }, [dataLogin]);

  return (
    <div
      className='card p-5'
      style={{ minWidth: '450px', height: '65vh' }}>
      <form>
        <Toast ref={toast} />
        <div className=' card bg-white p-3 border-round-xl'>
          <div className='text-center p-5 '>
            <h4 className='text-3xl'>Đăng nhập</h4>
          </div>
          <div className='flex flex-column mb-4 '>
            <label
              htmlFor='email'
              className='mb-2'>
              Tên đăng nhập
            </label>
            <InputText
              value={formik.values.email}
              onChange={formik.handleChange}
              id='email'
              name='email'
              autoComplete='on'
              type='text'
              placeholder='Tên đăng nhập'
              className='w-full p-3'
              invalid={
                formik.touched.email && formik.errors.email ? true : false
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <p style={{ color: 'red', marginTop: '5px', fontSize: '0.9rem' }}>
                {String(formik.errors.email)}
              </p>
            ) : null}
          </div>
          <div className='flex flex-column mb-2'>
            <label className='mb-2'>Mật khẩu</label>
            <Password
              value={formik.values.password}
              onChange={formik.handleChange}
              id='password'
              name='password'
              type='password'
              placeholder='Mật khẩu'
              autoComplete='on'
              inputClassName='w-full p-3'
              pt={{ iconField: { root: { className: 'w-full' } } }}
              toggleMask
              feedback={false}
              invalid={
                formik.touched.password && formik.errors.password ? true : false
              }
            />
          </div>
          <div className='flex align-items-center justify-content-between mb-5 gap-5 relative'>
            {formik.touched.password && formik.errors.password ? (
              <p style={{ color: 'red', fontSize: '0.9rem' }}>
                {String(formik.errors.password)}
              </p>
            ) : null}
          </div>
          <Button
            type='submit'
            label='Đăng nhập'
            className='w-full my-5'
            onClick={formik.handleSubmit}
          />
        </div>
      </form>
    </div>
  );
}
