import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { QrReader } from 'react-qr-reader';
import Modal from 'react-modal';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import '../Import/index.css';
import ModalProjectDeliveryTeam2 from '../Teams/ModalProjectDeliveryTeam2';
import ModalProjectDelivery2 from '../Plan/ModalProjectDelivery2';
import Locate1 from '../Plan/Locate1';

Modal.setAppElement('#root');

const getOS = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.userAgentData?.platform || navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = "Unknown";

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }
  return os;
};

const Import = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isRearCamera, setIsRearCamera] = useState(true);
  const [destination, setDestination] = useState('');
  const [modalQrIsOpen, setModalQrIsOpen] = useState(false);
  const [modalQrIsOpen2, setModalQrIsOpen2] = useState(false);
  const [modalQrIsOpen3, setModalQrIsOpen3] = useState(false);
  const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
  const [os, setOS] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setOS(getOS());
  }, []);

  useEffect(() => {
    if (os === 'Windows' || os === 'Mac OS' || os === 'Linux') {
      setDialogIsOpen(true); // Mở dialog phù hợp cho hệ điều hành Windows, Mac OS, Linux
    } else {
      setDialogIsOpen(true); // Mở dialog phù hợp cho hệ điều hành khác
    }
  }, [os]);

  const openModal = (target) => {
    setDestination(target);
    setModalIsOpen(true);
  };

  const handleOpenModal = () => {
    setModalQrIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalQrIsOpen(false);
  };

  const handleOpenModal2 = () => {
    setModalQrIsOpen2(true);
  };

  const handleCloseModal2 = () => {
    setModalQrIsOpen2(false);
  };

  const handleCloseModal3 = () => {
    setModalQrIsOpen3(false);
  };

  const closeModal = () => setModalIsOpen(false);

  const closeDialog = () => {setDialogIsOpen(false)};

  const toggleCamera = () => setIsRearCamera((prev) => !prev);

  const handleScan = (data) => {
    if (data) {
      const url = new URL(data);
      const name = encodeURIComponent(url.searchParams.get('name'));
      const factoryCode = encodeURIComponent(url.searchParams.get('factoryCode'));
      const quantity = encodeURIComponent(url.searchParams.get('quantity'));
      const teamCode = encodeURIComponent(url.searchParams.get('teamCode'));
      const categoryName = encodeURIComponent(url.searchParams.get('categoryName'));

      if (destination === 'ModalProjectDeliveryTeam') {
        navigate(`/ModalProjectDeliveryTeam?name=${name}&teamCode=${teamCode}`);
      } else if (destination === 'ModalProjectDelivery') {
        navigate(`/ModalProjectDelivery?factoryCode=${factoryCode}&categoryName=${categoryName}&quantity=${quantity}`);
      }else if (destination === 'Locate1') {
        navigate(`/Locate1?factoryCode=${factoryCode}`);
      }
      closeModal();
    }
  };

  const handleError = (err) => {
    console.error(err);
    alert('Quét không thành công');
  };

  const closeDialogAndNavigate = () => {
    closeDialog();
    navigate('/plan');
  };

  return (
    <div className='card'>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='QR Code Scanner'
        className='Modal'
        overlayClassName='Overlay'>
        <h2>Quét mã QR</h2>
        <QrReader
          onResult={handleScan}
          onError={handleError}
          style={{ width: '100%', marginBottom: '10px' }}
          constraints={{
            facingMode: isRearCamera ? 'environment' : 'user',
          }}
        />
        <div className='button-group'>
          <Button
            label='Chuyển camera'
            onClick={toggleCamera}
          />
          <Button
            label='Đóng'
            onClick={closeModal}
          />
        </div>
      </Modal>

      {/* Dialog hiển thị dựa trên hệ điều hành */}
      {os==='Windows' || os === 'Mac OS' || os === 'Linux' ? (
        <Dialog
          header="Chọn chức năng"
          visible={dialogIsOpen}
          onHide={closeDialogAndNavigate}
          style={{ width: '400px' }}>
          <div className='flex flex-column justify-content-center align-items-center gap-3'>
            <Button
              style={{ width: '150px', marginBottom: '10px' }}
              label='Nhập sản xuất'
              onClick={handleOpenModal}
            />
            {/* <Button
              label='Giao công trình'
              onClick={handleOpenModal2}
              style={{ width: '150px' }}
            /> */}
          </div>
        </Dialog>
      ) : (
        <Dialog
          header="Chọn chức năng"
          visible={dialogIsOpen}
          onHide={closeDialogAndNavigate}
          style={{ width: '400px' }}>
          <div className='flex flex-column justify-content-center align-items-center gap-3'>
            <Button
              style={{ width: '150px', marginBottom: '10px' }}
              label='Nhập sản xuất'
              onClick={() => {
                closeDialog();
                openModal('ModalProjectDeliveryTeam');
              }}
            />
            {/* <Button
              label='Giao công trình'
              onClick={() => {
                closeDialog();
                openModal('ModalProjectDelivery');
              }}
              style={{ width: '150px', marginBottom: '10px' }}
            /> */}
            
            <Button
              label='Quét định vị'
              onClick={() => {
                closeDialog();
                openModal('Locate1');
              }}
              style={{ width: '150px' }}
            />
          </div>
        </Dialog>
      )}
      <ModalProjectDeliveryTeam2 visible={modalQrIsOpen} onHide={handleCloseModal}/>
      <ModalProjectDelivery2 visible={modalQrIsOpen2} onHide={handleCloseModal2}/>
      {dialogLocationVisible && (
        <Locate1 visible={modalQrIsOpen3} onHide={handleCloseModal3}/>
      )}
    </div>
  );
};

export default Import;
